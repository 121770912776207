import { PRODUCT_PHOTO_SIZE_FULL, mdi, PRODUCT_PHOTO_SIZE_MOBILE } from 'app/config/constants';
import { asPrice } from 'app/util/format-utils';
import Qty from 'app/shared/qty/Qty';
import React, { useEffect, useState } from 'react';
import { IProduct } from 'app/shared/model/product.model';
import { IRecipe } from 'app/shared/model/recipe.model';
import { len } from 'app/util/structure-utils';
import ActionButton from 'app/shared/action-buttons/ActionButton';
import ModalDialog from 'app/shared/layout/dialog/ModalDialog';
import { Color } from 'app/shared/model/enumerations/color.model';
import ProductNameCell from 'app/entities/event/product-table/ProductNameCell';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getCustomNames, saveCustomNames } from 'app/entities/custom-product-names/custom-product-names.reducer';
import { useWindowWidth } from 'app/shared/hooks/useWindowWidth';
import { screenWidth } from 'app/shared/model/enumerations/screen-modes';

interface IColumn {
  render: (product: IProduct, index: number, name?: string, isMobile?: boolean) => JSX.Element;
}

interface ProductTableProps {
  title: string;
  products: IProduct[];
  startFromIndex?: number;
  recipe: IRecipe;
  readOnly?: boolean;
  editableNames?: boolean;
  stepQtyChange?: boolean;
  onRecipeChange?: (recipe: any) => void;
  columns?: IColumn[];
}

const ProductTable = ({
  title,
  products,
  startFromIndex,
  recipe,
  readOnly = false,
  editableNames = false,
  stepQtyChange = false,
  onRecipeChange,
  columns = null,
}: ProductTableProps) => {
  const [productIndexToDelete, setProductIndexToDelete] = useState<number>(null);
  const customNames = useAppSelector(state => state.productCustomNames.entity);
  const dispatch = useAppDispatch();
  const width = useWindowWidth();

  useEffect(() => {
    if (len(customNames) === 0) {
      dispatch(getCustomNames());
    }
  }, []);

  const renameProductHandler = (code: string, name: string) => {
    let newCustomNames = {
      ...customNames,
      mapping: {
        ...customNames.mapping,
      },
    };

    if (!name) {
      delete newCustomNames.mapping[code.toLowerCase().trim()];
    } else {
      newCustomNames.mapping[code] = name;
    }

    dispatch(saveCustomNames(newCustomNames));
  };

  const changeQuantityHandler = (productIndex: number, qty: number) => {
    const products = [...recipe.products];
    products[productIndex] = { ...products[productIndex], quantity: Number(qty) };
    const newRecipe = { ...recipe, products };
    onRecipeChange(newRecipe);
  };

  const deleteHandler = () => {
    const products = recipe.products.filter((_: any, i: number) => i !== productIndexToDelete);
    const newRecipe = { ...recipe, products: products };
    onRecipeChange(newRecipe);
    resetModal();
  };

  const resetModal = () => {
    setProductIndexToDelete(null);
  };

  const renderSubTitle = (title: string) => <div className="form-section-subtitle">{title}</div>;

  const getProductName = (product: IProduct): string =>
    customNames.mapping[product.productId.trim().toLowerCase()] || product.productDescription;

  const renderDefaultRow = (product: IProduct, i: number) => {
    const productIndex = recipe.products.indexOf(product);
    const productName = getProductName(product);
    const isMobile = width <= screenWidth.MAX_NARROW;
    const imageSize = isMobile ? PRODUCT_PHOTO_SIZE_MOBILE : PRODUCT_PHOTO_SIZE_FULL;

    return (
      <tr key={productIndex}>
        {startFromIndex && <td className="product-index">{i}.</td>}
        <td className="table-image">
          <img src={product.imageName} alt={product.productId} width={imageSize} height={imageSize} />
        </td>
        <ProductNameCell
          product={product}
          name={productName}
          editable={editableNames}
          onRename={name => renameProductHandler(product.productId, name)}
        />
        <td className="price">{asPrice(product.price1)}</td>

        {!readOnly && (
          <td className="qty">
            <Qty
              min={1}
              value={Math.round(product.quantity)}
              step={stepQtyChange ? product.minQuantity : 1}
              width={isMobile ? '75px' : '100px'}
              onChange={qty => changeQuantityHandler(productIndex, qty)}
            />
          </td>
        )}

        {!readOnly && (
          <td className="remove">
            <ActionButton icon={mdi.delete} name="Delete" clickHandler={() => setProductIndexToDelete(productIndex)} />
          </td>
        )}
      </tr>
    );
  };

  const renderCustomRow = (product: IProduct, i: number) => {
    const productName = getProductName(product);
    const isMobile = width <= screenWidth.MAX_NARROW;

    return <tr key={product.productId}>{columns.map(column => column.render(product, i, productName, isMobile))}</tr>;
  };

  const renderContent = () => (
    <div>
      <div className="category-title">{renderSubTitle(title)}</div>

      {products.length > 0 && (
        <table>
          <tbody>
            {products.map((product: IProduct, i: number) =>
              columns && len(columns) > 0 ? renderCustomRow(product, startFromIndex + i) : renderDefaultRow(product, startFromIndex + i),
            )}
          </tbody>
        </table>
      )}
    </div>
  );

  const renderConfirmationDialog = () => {
    const product = recipe.products[productIndexToDelete];

    return (
      productIndexToDelete !== null && (
        <ModalDialog
          isOpen={true}
          modalText={`Are you sure you want to delete '${product.productDescription.toLowerCase().trim()}' from the recipe '${recipe.name}'?`}
          toggleHandler={resetModal}
          buttons={[
            {
              label: 'Delete',
              color: Color.DANGER,
              clickHandler: deleteHandler,
            },
            {
              label: 'Cancel',
              color: Color.SECONDARY,
              outline: true,
              clickHandler: resetModal,
            },
          ]}
        />
      )
    );
  };

  return (
    <>
      {renderContent()}
      {renderConfirmationDialog()}
    </>
  );
};

export default ProductTable;
