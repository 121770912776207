import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { login, oneTimeLinkLogin } from 'app/shared/reducers/authentication';
import LoginPage from './login-page';
import { ROOT_PATH } from 'app/paths';

const useQuery = () => new URLSearchParams(useLocation().search);

export const Login = ({ oneTimeLink = false }) => {
  const query = useQuery();
  const token = query.get('token');
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isLoading = useAppSelector(state => state.authentication.loading);
  const loginError = useAppSelector(state => state.authentication.loginError);
  const navigate = useNavigate();
  const pageLocation = useLocation();

  useEffect(() => {
    if (oneTimeLink) {
      oneTimeLinkAuth();
    }
  }, []);

  useEffect(() => {
    if (oneTimeLink && !isLoading) {
      navigate(ROOT_PATH);
    }
  }, [isLoading]);

  const oneTimeLinkAuth = () => {
    dispatch(oneTimeLinkLogin(token));
  };

  const handleLogin = (username, password, rememberMe = false) => {
    dispatch(login(username, password, rememberMe));
  };

  const { from } = pageLocation.state || { from: { pathname: ROOT_PATH, search: pageLocation.search } };

  return isAuthenticated ? <Navigate to={from} replace /> : <LoginPage handleLogin={handleLogin} loginError={loginError} />;
};

export default Login;
