import React, { useEffect, useRef, useState } from 'react';
import './address-autocomplete-styles.scss';
import { Input } from 'reactstrap';
import SuggestionsPopup from './SuggestionPopup';
import { len } from 'app/util/structure-utils';
import { getStateByCode } from 'app/util/format-utils';

const SUBMIT_DELAY_IN_MS: number = 50;
const AUTOCOMPLETE_MIN_LENGTH: number = 3;

interface IAddressAutocompleteProps {
  id: string;
  value?: string;
  invalid?: boolean;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  onAutocomplete?: (value: any) => void;
  currentCity?: string;
}

export interface IPrediction {
  displayText: string;
  placeId: string;
}

export interface IPlace {
  city: string;
  route: string;
  state: string;
  zipCode?: string;
}

const AddressAutocomplete = ({ id, value = '', invalid, onChange, onBlur, onAutocomplete }: IAddressAutocompleteProps) => {
  const [inputValue, setInputValue] = useState(value);
  const [placePredictions, setPlacePredictions] = useState<IPrediction[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isUserInputRef = useRef(false);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value || '');
      isUserInputRef.current = false;
    }
  }, [value]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (len(inputValue) >= AUTOCOMPLETE_MIN_LENGTH) {
      if (isUserInputRef.current) {
        timerRef.current = setTimeout(() => {
          performPlacePrediction();
        }, SUBMIT_DELAY_IN_MS);
      }
    } else {
      setPlacePredictions([]);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [inputValue]);

  const performPlacePrediction = () => {
    if (window.google && window.google.maps) {
      const autocompleteService = new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: inputValue,
          componentRestrictions: { country: ['us'] },
          types: ['address'],
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
            const places: IPrediction[] = predictions.map(p => {
              const displayText = p.description;
              const placeId = p.place_id;

              return { displayText, placeId };
            });
            setPlacePredictions(places);
          } else {
            setPlacePredictions([]);
          }
        },
      );
    }
  };

  const fetchPlaceDetails = (placeId: string): Promise<IPlace> => {
    return new Promise((resolve, reject) => {
      const map = new window.google.maps.Map(document.createElement('div'));
      const service = new window.google.maps.places.PlacesService(map);

      service.getDetails(
        {
          placeId: placeId,
          fields: ['address_components', 'geometry'],
        },
        (placeDetails, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const addressComponents = placeDetails.address_components;
            const place: IPlace = {
              city: '',
              route: '',
              state: '',
              zipCode: '',
            };

            addressComponents.forEach(component => {
              if (component.types.includes('locality')) {
                place.city = component.long_name;
              } else if (component.types.includes('route')) {
                place.route = component.long_name;
              } else if (component.types.includes('administrative_area_level_1')) {
                place.state = component.short_name;
              } else if (component.types.includes('postal_code')) {
                place.zipCode = component.long_name;
              }
            });

            resolve(place);
          } else {
            reject('Failed to fetch place details');
          }
        },
      );
    });
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    isUserInputRef.current = true;
    setInputValue(newValue);

    if (onChange) {
      onChange(compileChange(e));
    }
  };

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 250);

    if (onBlur) {
      onBlur(compileChange(e));
    }
  };

  const focusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    setShowSuggestions(true);
  };

  const compileChange = (e: any) => {
    const change = e;
    change.target.id = id;

    return change;
  };

  const suggestionSelectHandler = async (prediction: IPrediction) => {
    try {
      const placeDetails = await fetchPlaceDetails(prediction.placeId);
      const state = getStateByCode(placeDetails.state);
      const route = prediction.displayText.split(',')[0];
      const placeWithFullState = { ...placeDetails, state, route };
      onAutocomplete(placeWithFullState);
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
    setShowSuggestions(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Input
        id={id}
        type="text"
        value={inputValue}
        onChange={changeHandler}
        onBlur={blurHandler}
        onFocus={focusHandler}
        invalid={invalid}
      />
      {showSuggestions && len(placePredictions) > 0 && (
        <SuggestionsPopup placePredictions={placePredictions} highlight={inputValue} onSelect={suggestionSelectHandler} />
      )}
    </div>
  );
};

export default AddressAutocomplete;
