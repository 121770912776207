import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import ContactDetail from './contact-detail';
import Contact from 'app/entities/contact/contact';
import ContactUpdate from 'app/entities/contact/contact-update';
import { EDIT_PATH, NEW_PATH } from 'app/paths';

const ContactRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Contact />} />
    <Route path={NEW_PATH} element={<ContactUpdate />} />
    <Route path=":id">
      <Route index element={<ContactDetail />} />
      <Route path={EDIT_PATH} element={<ContactUpdate />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ContactRoutes;
