import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table, Input } from 'reactstrap';
import { IProduct } from 'app/shared/model/product.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, searchEntities } from 'app/entities/product/product.reducer';
import { eventBus } from 'app/shared/EventBus';
import { MODAL, SHOW_PRODUCT_SELECTOR_MODAL } from 'app/shared/EventBusAction';
import { asPrice } from 'app/util/format-utils';
import { PRODUCT_PHOTO_SIZE_FULL, PRODUCT_PHOTO_SIZE_MOBILE } from 'app/config/constants';
import SearchInput from 'app/shared/search/SearchInput';
import ProductFilter, { FilterKeys } from './product-filter/ProductFilter';
import NoItems from 'app/shared/alert/NoItems';
import Loading from 'app/shared/alert/loading';
import { len } from 'app/util/structure-utils';
import { getCustomNames } from 'app/entities/custom-product-names/custom-product-names.reducer';
import { useWindowWidth } from 'app/shared/hooks/useWindowWidth';
import { screenWidth } from 'app/shared/model/enumerations/screen-modes';

const SEARCH_MIN_LENGTH = 3;
let globalChanges = { add: [] as IProduct[], remove: [] as IProduct[] };

const ProductSelectorModal = ({ initialSelectedProducts, onApply, onCancel }) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>(initialSelectedProducts || []);
  const [currentChanges, setCurrentChanges] = useState<{ add: IProduct[]; remove: IProduct[] }>({ add: [], remove: [] });
  const [searchText, setSearchText] = useState('');
  const [productFilter, setProductFilter] = useState<FilterKeys | null>(null);
  const dispatch = useAppDispatch();
  const products = useAppSelector(state => state.product.entities);
  const isLoading = useAppSelector(state => state.product.loading);
  const customNames = useAppSelector(state => state.productCustomNames.entity);
  const width = useWindowWidth();

  useEffect(() => {
    const bus = eventBus.subscribe(MODAL, (name: string) => {
      if (name === SHOW_PRODUCT_SELECTOR_MODAL) {
        toggleModal();
      }
    });

    if (len(customNames) === 0) {
      dispatch(getCustomNames());
    }

    return () => bus.unsubscribe();
  }, []);

  useEffect(() => {
    setSelectedProducts(initialSelectedProducts);
    setCurrentChanges(globalChanges);
  }, [initialSelectedProducts]);

  useEffect(() => {
    setCurrentChanges(globalChanges);
  }, [products]);

  useEffect(() => {
    submitSearch();
  }, [searchText, productFilter]);

  const toggleModal = () => {
    setOpen(prev => !prev);
  };

  const fetchAllProducts = () => {
    dispatch(getEntities({}));
  };

  const toggleProductSelection = (product: IProduct) => {
    const isNowSelected = selectedProducts.some(p => p.productId === product.productId);
    let updatedSelectedProducts: IProduct[] = [];
    let newChanges = { ...globalChanges };

    if (isNowSelected) {
      updatedSelectedProducts = selectedProducts.filter(p => p.productId !== product.productId);

      if (initialSelectedProducts.some(p => p.productId === product.productId)) {
        newChanges.remove.push(product);
      } else {
        newChanges.add = newChanges.add.filter(p => p.productId !== product.productId);
      }
    } else {
      updatedSelectedProducts = [...selectedProducts, product];

      if (initialSelectedProducts.some(p => p.productId === product.productId)) {
        newChanges.remove = newChanges.remove.filter(p => p.productId !== product.productId);
      } else {
        newChanges.add.push({ ...product, quantity: 1 });
      }
    }

    setSelectedProducts(updatedSelectedProducts);
    globalChanges = newChanges;
    setCurrentChanges(newChanges);
  };

  const applyClickHandler = () => {
    onApply(globalChanges);
    closeModal();
  };

  const closeModal = () => {
    setSelectedProducts(initialSelectedProducts);
    setProductFilter(null);
    fetchAllProducts();
    globalChanges = { add: [], remove: [] };
    setCurrentChanges(globalChanges);
    onCancel();
    setOpen(false);
  };

  const submitSearch = () => {
    if (!searchText && !productFilter) {
      fetchAllProducts();
    } else {
      const searchPart = searchText.length >= SEARCH_MIN_LENGTH ? `?names=${searchText}` : '';
      const filterPart = !productFilter ? '' : searchPart ? `&type=${productFilter}` : `?type=${productFilter}`;
      const params = searchPart + filterPart;

      if (params) dispatch(searchEntities(params));
    }
  };

  const productFilterChangeHandler = (value: FilterKeys) => {
    setProductFilter(value);
  };

  const searchInputChangeHandler = (value: string) => {
    setSearchText(value);
  };

  const getProductName = (product: IProduct): string =>
    customNames.mapping[product.productId.trim().toLowerCase()] || product.productDescription;

  const renderProducts = () => {
    const imageSize = width > screenWidth.MAX_NARROW ? PRODUCT_PHOTO_SIZE_FULL : PRODUCT_PHOTO_SIZE_MOBILE;

    return (
      <div className="products-table">
        {isLoading ? (
          <Loading />
        ) : products.length === 0 ? (
          <NoItems text="No Products Found" />
        ) : (
          <Table>
            <tbody>
              {products.map((product: IProduct, i: number) => {
                const checked = selectedProducts.some(p => p.productId === product.productId);

                return (
                  <tr
                    key={i}
                    className={`selectable ${checked && 'checked'}`}
                    onClick={e => {
                      const target = e.target as HTMLElement;

                      if (target.tagName !== 'INPUT') {
                        toggleProductSelection(product);
                      }
                    }}
                  >
                    <td className="checkbox">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        checked={checked}
                        onChange={() => toggleProductSelection(product)}
                      />
                    </td>

                    <td className="table-image">
                      <img src={product.imageName} alt={product.productId} width={imageSize} height={imageSize} />
                    </td>

                    <td className="product-name">
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          overflow: 'hidden',
                        }}
                      >
                        <div className="product-description" style={{ paddingRight: '5px', display: 'inline' }}>
                          {product.productDescription}
                        </div>

                        {product.productDescription !== getProductName(product) && (
                          <div className="primary" style={{ background: 'none', opacity: 0.75 }}>
                            ({getProductName(product)})
                          </div>
                        )}
                      </div>

                      <div className="product-id">{product.productId}</div>
                    </td>

                    <td className="um">
                      <div className="um-container">
                        <div>{product.um}</div>
                        {product.minQuantity && product.minQuantity > 1 && (
                          <div className="min-qty">
                            {product.um.toUpperCase() === 'BU' ? `${product.minQuantity} /bunch` : `min ${product.minQuantity}`}
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="price">
                      <div className="price-container">{asPrice(product.price1)}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    );
  };

  return (
    <Modal className="form-modal" isOpen={isOpen} toggle={closeModal}>
      <div className="event-form">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="form-section-title">Select Product(s)</div>
          <ProductFilter value={productFilter} onChangeFilter={productFilterChangeHandler} />
        </div>

        <SearchInput onSearch={searchInputChangeHandler} placeholder="e.g., rose" />

        {renderProducts()}

        <Row className="action-footer">
          <Col className="text-end">
            <Button color="secondary" onClick={closeModal}>
              Cancel
            </Button>

            <Button
              color="primary"
              style={{ marginLeft: '20px' }}
              onClick={applyClickHandler}
              disabled={(currentChanges.add.length === 0 && currentChanges.remove.length === 0) || products.length === 0}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ProductSelectorModal;
