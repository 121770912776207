import React, { useRef } from 'react';
import templates from 'app/entities/settings/personalization';
import { Button, Input } from 'reactstrap';

const PersonalizedEditor = ({ setting, title = '', onChange }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const changeHandler = e => {
    const value = e.target.value;
    onChange(value);
  };

  const putPersonification = (value: string) => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const newText = textarea.value.substring(0, startPos) + value + textarea.value.substring(endPos);
      onChange(newText);
      setTimeout(() => {
        textarea.focus();
        textarea.setSelectionRange(startPos + value.length, startPos + value.length);
      }, 0);
    }
  };

  const renderPersonificationOptions = () => (
    <div className="personalization">
      {title}

      <div className="templates-container">
        {templates.map(option => (
          <Button className="template" color="primary" onClick={() => putPersonification(option.value)}>
            {option.name}
          </Button>
        ))}
      </div>
    </div>
  );

  return (
    <>
      {renderPersonificationOptions()}

      <Input type="textarea" innerRef={textareaRef} value={setting} onChange={changeHandler} />
    </>
  );
};

export default PersonalizedEditor;
