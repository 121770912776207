import { IProduct } from 'app/shared/model/product.model';

export interface IRecipe {
  id?: number;
  name?: string | null;
  note?: string | null;
  quantity?: number;
  products?: IProduct[];
}

export const defaultValue: Readonly<IRecipe> = {};
