import './footer.scss';
import React from 'react';
import { Col, Row } from 'reactstrap';
import Icon from '@mdi/react';
import { PRIVACY_PATH, TERMS_PATH } from 'app/paths';
import { Link } from 'react-router-dom';
import { mdi } from 'app/config/constants';

interface ILink {
  text?: string;
  icon?: string;
  url: string;
  internal?: boolean;
}

interface ILinkBlock {
  title?: string;
  icon?: string;
  links: ILink[];
}

const linkBlocks: ILinkBlock[] = [
  {
    title: '',
    links: [
      {
        text: 'Privacy Policy',
        url: PRIVACY_PATH,
        icon: mdi.shield,
        internal: true,
      },
    ],
  },
  {
    title: '',
    links: [
      {
        text: 'Terms of Service',
        url: TERMS_PATH,
        icon: mdi.bulletList,
        internal: true,
      },
    ],
  },
];

const renderIcon = (icon: string, isTitle?: boolean) => <Icon path={icon} size={isTitle ? 0.75 : 0.5} style={{ marginRight: 5 }} />;

const renderLink = (link: ILink, i: number) => {
  const linkContent = (
    <>
      {link.icon && renderIcon(link.icon)}
      {link.text}
    </>
  );

  return (
    <div key={i}>
      {link.internal ? (
        <Link to={link.url} className="link">
          {linkContent}
        </Link>
      ) : (
        <a className="link" href={link.url}>
          {linkContent}
        </a>
      )}
    </div>
  );
};

const renderBlock = (block: ILinkBlock, i: number) => (
  <Col key={i} className="block">
    <div className="block-title">
      {block.icon && renderIcon(block.icon, true)}
      {block.title}
    </div>

    {block.links.map(renderLink)}
  </Col>
);

const Footer = () => (
  <div className="footer-container">
    <Row className="link-block-container">{linkBlocks.map(renderBlock)}</Row>
    <div className="copyright">© 2024 FloriPlanner. All Rights Reserved.</div>
    <img className="logo" src="content/images/logo.svg" alt="FloriPlanner Logo" />
  </div>
);

export default Footer;
