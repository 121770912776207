import React, { useState } from 'react';
import Subscription from 'app/modules/startup/subscription/Subscription';
import DisplayName from 'app/modules/startup/display-name/DisplayName';
import Agreement from 'app/modules/startup/agreement/Agreement';

const Startup = ({ showStartup, onDone }) => {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(prev => prev + 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Subscription onDone={handleNext} />;
      case 2:
        return <DisplayName onDone={handleNext} />;
      case 3:
        return <Agreement onDone={onDone} />;
      default:
        return null;
    }
  };

  return showStartup && renderStep();
};

export default Startup;
