class EventBus {
  events = null;

  constructor() {
    this.events = {};
  }

  subscribe(eventName: string, callback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);

    return {
      unsubscribe: () => {
        this.unsubscribe(eventName, callback);
      },
    };
  }

  unsubscribe(eventName, callback) {
    if (this.events[eventName]) {
      this.events[eventName] = this.events[eventName].filter(cb => cb !== callback);
      // Очищаем массив, если он пустой
      if (this.events[eventName].length === 0) {
        delete this.events[eventName];
      }
    }
  }

  publish(eventName: string, data: any) {
    if (this.events[eventName]) {
      this.events[eventName].forEach(callback => callback(data));
    }
  }
}

export const eventBus = new EventBus();
