/**
 * Regular expression pattern for validating phone numbers.
 *
 * @type {RegExp}
 */
export const PHONE_REGEX: RegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

/**
 * Regular expression pattern to validate email addresses.
 *
 * @type {RegExp}
 */
export const EMAIL_REGEX: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * Regular expression for filtering file names.
 * Matches the last segment of a file path, excluding any query parameters or fragments.
 *
 * @type {RegExp}
 */
export const FILENAME_FILTER_REGEX: RegExp = /.*\/([^/?]+)/;
