import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Table } from 'reactstrap';
import './subscription-info-styles.scss';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiClockTimeFourOutline, mdiCloseCircleOutline } from '@mdi/js';
import { SUBSCRIPTION_CONFIG_URL, SUBSCRIPTIONS_URL } from 'app/config/url';
import { convertDateToUSAFormat } from 'app/shared/util/date-utils';
import { ChargePeriodDays } from 'app/shared/model/enumerations/charge-period.model';
import { asPrice } from 'app/util/format-utils';
import Loading from 'app/shared/alert/loading';

const status = {
  TRIAL: (
    <div className="subscription-status">
      <Icon path={mdiClockTimeFourOutline} size={1} className="trial" />
      Trial
    </div>
  ),
  ACTIVE: (
    <div className="subscription-status">
      <Icon path={mdiCheckCircle} size={1} className="active" />
      Active
    </div>
  ),
  CANCELED: (
    <div className="subscription-status">
      <Icon path={mdiCloseCircleOutline} size={1} className="canceled" />
      Canceled
    </div>
  ),
};

const isTrialEnded = (record: any, chargePeriod: number): boolean => {
  const trialStartDate = new Date(record.timestamp * 1000);
  const trialEndDate = new Date(trialStartDate);
  trialEndDate.setDate(trialStartDate.getDate() + chargePeriod);
  const currentDate = new Date();

  return trialEndDate.getTime() <= currentDate.getTime();
};

const getNextChargeDate = (record: any, chargePeriod: number): Date => {
  const startDate = new Date(record.timestamp * 1000);
  const currentDate = new Date();
  const chargeDate = new Date(startDate);

  do {
    chargeDate.setDate(startDate.getDate() + chargePeriod);
  } while (chargeDate.getTime() <= currentDate.getTime());

  return chargeDate;
};

const getDaysToNextCharge = (record: any, chargePeriod: number): number => {
  const endDate = getNextChargeDate(record, chargePeriod);
  const currentDate = new Date();
  const diff = endDate.getTime() - currentDate.getTime();

  return Math.ceil(diff / (1000 * 60 * 60 * 24));
};

const renderStatus = (record: any, chargePeriod: number) => {
  let result: any;

  if (record.isCanceledEarly) {
    result = status.CANCELED;
  } else if (isTrialEnded(record, chargePeriod)) {
    result = status.ACTIVE;
  } else {
    result = status.TRIAL;
  }

  return result;
};

interface IInfoRow {
  key: string;
  value: string;
}

const SubscriptionInfo = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [config, setConfig] = useState(null);
  const [infoRows, setInfoRows] = useState<IInfoRow[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const period = config && ChargePeriodDays[config.chargePeriod];
    const days = data && period && getDaysToNextCharge(data, period);
    const plan = (config && config.name) || '';
    const startDate = (data && convertDateToUSAFormat(data.timestamp * 1000)) || '';
    const status = (data && period && renderStatus(data, period)) || null;
    const chargeDate =
      (data && period && `${convertDateToUSAFormat(getNextChargeDate(data, period))} (in ${days} ${days > 1 ? 'days' : 'day'})`) || '';
    const amount = (config && asPrice(config.amount)) || '';

    const rows: IInfoRow[] = [
      {
        key: 'Subscription Plan',
        value: plan,
      },
      {
        key: 'Start Date',
        value: startDate,
      },
      {
        key: 'Status',
        value: status,
      },
      {
        key: 'Next Billing Date',
        value: chargeDate,
      },
      {
        key: 'Charge Amount',
        value: amount,
      },
    ];

    setInfoRows(rows);
  }, [data, config]);

  const fetchData = () => {
    setLoading(true);

    Promise.all([axios.get(SUBSCRIPTIONS_URL), axios.get(`${SUBSCRIPTION_CONFIG_URL}/current`)])
      .then(results => {
        setData(results[0].data);
        setConfig(results[1].data);
      })
      .catch(error => {
        console.error('Failed to fulfill the request', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="stretch col-md-8">
      <h4>Subscription Details</h4>
      <Row className="justify-content-center">
        {loading ? (
          <Loading />
        ) : (
          <div className="subscription-info-container">
            <Table>
              <tbody>
                {infoRows.map((row, index) => (
                  <tr key={index}>
                    <td className="field-key">
                      <div>{row.key}</div>
                    </td>
                    <td className="field-value">{row.value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Row>
    </div>
  );
};

export default SubscriptionInfo;
