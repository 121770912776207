import { Department } from 'app/shared/model/enumerations/department.model';

export interface IProduct {
  quantity1?: number;
  quantity2?: number;
  quantity3?: number;
  quantity4?: number;
  quantity5?: number;
  unitPrice?: number;
  price1?: number;
  price2?: number;
  price3?: number;
  price4?: number;
  price5?: number;
  deliveryMethod?: string;
  shipToName?: string;
  shipDate?: Date;
  quantity?: number;
  pack?: number;
  um?: string;
  minQuantity?: number;
  productId?: string;
  shipToCode?: number;
  inventory?: number;
  productDescription?: string;
  department?: Department;
  imageName?: string;
  isFarm?: boolean;
  isPreBooked?: boolean;
  isOneClickBuy?: boolean;
}

export const defaultValue: Readonly<IProduct> = {};
