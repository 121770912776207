import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/alert/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import {
  CONTACTS_PATH,
  DELETE_PATH,
  EDIT_PATH,
  EVENTS_PATH,
  GALLERY_PATH,
  GUEST_PROPOSAL_VIEW_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  NEW_PATH,
  ONE_TIME_LINK_AUTH_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
  SUBSCRIPTIONS_MANAGEMENT_PATH,
  PRIVACY_PATH,
  TERMS_PATH,
  SUBSCRIPTION_PATH,
} from 'app/paths';
import { useAppSelector } from 'app/config/store';
import ContactRoutes from 'app/entities/contact';
import EventRoutes from 'app/entities/event';
import Layout from 'app/shared/layout/Layout';
import UserManagement from 'app/modules/administration/user-management';
import Health from 'app/modules/administration/health/health';
import Metrics from 'app/modules/administration/metrics/metrics';
import Configuration from 'app/modules/administration/configuration/configuration';
import Logs from 'app/modules/administration/logs/logs';
import Docs from 'app/modules/administration/docs/docs';
import AccountSettings from 'app/modules/account/settings/settings';
import Password from 'app/modules/account/password/password';
import { ProposalView } from 'app/entities/proposal/ProposalView';
import UserManagementUpdate from 'app/modules/administration/user-management/user-management-update';
import UserManagementDetail from 'app/modules/administration/user-management/user-management-detail';
import UserManagementDeleteDialog from 'app/modules/administration/user-management/user-management-delete-dialog';
import Gallery from 'app/entities/gallery';
import Settings from './entities/settings';
import Terms from 'app/pages/footer-pages/terms';
import Privacy from 'app/pages/footer-pages/privacy';
import { SubscriptionsPage } from 'app/admin/SubscriptionsPage';
import SubscriptionInfo from './pages/subscription-info';

const loading = <div>loading ...</div>;

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

export const NOT_FOUND = <Route path="*" element={<PageNotFound />} />;

const AppRoutes = () => {
  const [homeRedirectPath, setHomeRedirectPath] = useState('');
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const account = useAppSelector(state => state.authentication.account);

  useEffect(() => {
    let redirect: string;

    if (!isAuthenticated) {
      redirect = LOGIN_PATH;
    } else {
      redirect = EVENTS_PATH;
    }

    setHomeRedirectPath(redirect);
  }, [isAuthenticated, account, loading]);

  const ACCOUNT_ROUTES = (
    <Route
      path="account"
      element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}>
          <Layout />
        </PrivateRoute>
      }
    >
      <Route index element={<Navigate to="register" replace />} />
      <Route path="register" element={<Register />} />
      <Route path="activate" element={<Activate />} />

      <Route path="reset">
        <Route index element={<Navigate to="request" replace />} />
        <Route path="request" element={<PasswordResetInit />} />
        <Route path="finish" element={<PasswordResetFinish />} />
        {NOT_FOUND}
      </Route>

      <Route path="/" element={<Outlet />}>
        <Route path="settings" element={<AccountSettings />} />
        <Route path="password" element={<Password />} />
        {NOT_FOUND}
      </Route>

      {NOT_FOUND}
    </Route>
  );

  const ADMIN_ROUTES = (
    <>
      <Route
        path="admin"
        element={
          <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index element={<Navigate to="health" replace />} />

        <Route path="user-management">
          <Route index element={<UserManagement />} />
          <Route path={NEW_PATH} element={<UserManagementUpdate />} />

          <Route path=":login">
            <Route index element={<UserManagementDetail />} />
            <Route path={EDIT_PATH} element={<UserManagementUpdate />} />
            <Route path={DELETE_PATH} element={<UserManagementDeleteDialog />} />
            {NOT_FOUND}
          </Route>

          {NOT_FOUND}
        </Route>

        <Route path="health" element={<Health />} />
        <Route path="metrics" element={<Metrics />} />
        <Route path="configuration" element={<Configuration />} />
        <Route path="logs" element={<Logs />} />
        <Route path="docs" element={<Docs />} />
        {NOT_FOUND}
      </Route>
    </>
  );

  const GUEST_PROPOSAL_ROUTE = <Route path={`${GUEST_PROPOSAL_VIEW_PATH}/:id`} element={<ProposalView guest={!isAuthenticated} />} />;
  const ONE_TIME_LINK_AUTH_ROUTE = <Route path={ONE_TIME_LINK_AUTH_PATH} element={<Login oneTimeLink />} />;

  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route path={ROOT_PATH} element={<Navigate replace to={homeRedirectPath} />} />
        <Route path={LOGIN_PATH} element={<Login />} />
        <Route path={LOGOUT_PATH} element={<Logout />} />

        {GUEST_PROPOSAL_ROUTE}
        {ONE_TIME_LINK_AUTH_ROUTE}

        <Route
          path={ROOT_PATH}
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route path={`${SUBSCRIPTIONS_MANAGEMENT_PATH}`} element={<SubscriptionsPage />} />
        </Route>

        <Route
          path={ROOT_PATH}
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route path={`${CONTACTS_PATH}/*`} element={<ContactRoutes />} />
          <Route path={`${EVENTS_PATH}/*`} element={<EventRoutes />} />
          <Route path={`${GALLERY_PATH}/*`} element={<Gallery />} />
          <Route path={`${SETTINGS_PATH}/*`} element={<Settings />} />
          <Route path={`${TERMS_PATH}/*`} element={<Terms />} />
          <Route path={`${SUBSCRIPTION_PATH}/*`} element={<SubscriptionInfo />} />
          <Route path={`${PRIVACY_PATH}/*`} element={<Privacy />} />
          {NOT_FOUND}
        </Route>

        {NOT_FOUND}
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
