import { IProduct } from 'app/shared/model/product.model';
import { IRecipe } from 'app/shared/model/recipe.model';
import { roundPrice } from 'app/util/format-utils';
import { Department } from 'app/shared/model/enumerations/department.model';

/**
 * Calculates the length of an input which can be a string, an array, or an object.
 * If the input is a string, it returns the length of the string.
 * If the input is an array, it returns the number of elements in the array.
 * If the input is an object, it returns the number of keys in the object.
 * Throws an error if the input is neither an array nor a non-null object.
 *
 * @param input - The input to be evaluated, which can be a string, any array or an object.
 * @returns The number of chars, elements, or keys in the input.
 * @throws Will throw an error if the input is not a string, an array, or a non-null object.
 */
export const len = (input: any[] | object | string): number => {
  if (typeof input === 'string') return input.length;

  if (Array.isArray(input)) return input.length;

  if (typeof input === 'object' && input !== null) return Object.keys(input).length;

  throw new Error('Input must be an array or an object');
};

/**
 * Merges an array of recipes and combines products with the same productId,
 * summing their quantities.
 *
 * @param {IRecipe[]} recipes - The array of recipes to merge. Defaults to an empty array.
 * @param ignoreRecipeQty - used in case products contain quantities multiplied by recipe quantities.
 * @return {IProduct[]} - The merged products.
 */
export const mergeRecipes = (recipes: IRecipe[] = [], ignoreRecipeQty: boolean = false): IProduct[] => {
  const mergedProducts: IProduct[] = [];
  recipes.forEach(recipe => {
    const products = recipe.products || [];
    products.forEach(product => {
      const addedProduct = mergedProducts.filter(p => p.productId === product.productId)[0];
      const qty = ignoreRecipeQty ? product.quantity : product.quantity * recipe.quantity;

      if (!addedProduct) {
        mergedProducts.push({ ...product, quantity: qty });
      } else {
        addedProduct.quantity += qty;
      }
    });
  });

  return mergedProducts;
};

/**
 * Creates a map of product IDs to quantities from an array of products.
 *
 * @param {Array<IProduct>} products - The array of products.
 * @returns {Object} - The map of product IDs to quantities.
 */
export const getProductIdQty = (products: IProduct[]): object =>
  products.reduce((acc, product) => ({ ...acc, [product.productId]: product.quantity }), {});

/**
 * Determines the quantity of products that fit a minimum quantity requirement.
 *
 * @param {IProduct} product - The product object.
 * @returns {number} - The quantity of products that fit.
 */
export const fitQty = (product: IProduct): number =>
  product.quantity % product.minQuantity === 0 ? product.quantity : Math.ceil(product.quantity / product.minQuantity) * product.minQuantity;

/**
 * Represents the calculated total amounts of a transaction.
 */
export interface ITotals {
  priceAmount: number;
  markupAmount: number;
  taxAmount: number;
  laborFeeAmount: number;
  setupFeeAmount: number;
  deliveryFeeAmount: number;
  totalAmount: number;
}

/**
 * Calculates the totals for products
 *
 * @param {IProduct[]} products - The list of products.
 * @param {number} taxRate - The tax rate as a decimal number (e.g. 0.1 for 10%).
 * @param {number} flowersMarkup - The markup rate for flower products as a decimal number (e.g. 0.2 for 20%).
 * @param {number} suppliesMarkup - The markup rate for supplies products as a decimal number (e.g. 0.15 for 15%).
 * @param {number} plantsMarkup - The markup rate for plant products as a decimal number (e.g. 0.25 for 25%).
 * @param {number} laborFee - Labor fee in $.
 * @param {number} setupFee - Setup fee in $.
 * @param {number} deliveryFee - Delivery fee in $.
 * @returns {ITotals} - Object containing the calculated totals
 */
export const calculateTotals = (
  products: IProduct[],
  taxRate: number,
  flowersMarkup: number = 0,
  suppliesMarkup: number = 0,
  plantsMarkup: number = 0,
  laborFee: number = 0,
  setupFee: number = 0,
  deliveryFee: number = 0,
): ITotals => {
  const priceAmount = roundPrice(products.reduce((sum: number, p: IProduct) => sum + p.price1 * p.quantity, 0));
  const markupAmount = calculateProfit(products, flowersMarkup, suppliesMarkup, plantsMarkup);
  const taxAmount = roundPrice((priceAmount + markupAmount + Number(laborFee) + Number(setupFee) + Number(deliveryFee)) * taxRate);
  const laborFeeAmount = roundPrice(laborFee);
  const setupFeeAmount = roundPrice(setupFee);
  const deliveryFeeAmount = roundPrice(deliveryFee);
  const totalAmount = roundPrice(priceAmount + markupAmount + taxAmount + laborFeeAmount + setupFeeAmount + deliveryFeeAmount);

  return { priceAmount, markupAmount, taxAmount, laborFeeAmount, setupFeeAmount, deliveryFeeAmount, totalAmount };
};

/**
 * Calculate the total profit based on the given products and markups.
 *
 * @param {IProduct[]} products - The array of products.
 * @param {number} flowersMarkup - The markup rate for flower products as a decimal number (e.g. 0.2 for 20%).
 * @param {number} suppliesMarkup - The markup rate for supplies products as a decimal number (e.g. 0.15 for 15%).
 * @param {number} plantsMarkup - The markup rate for plant products as a decimal number (e.g. 0.25 for 25%).
 * @returns {number} - The calculated profit.
 */
const calculateProfit = (products: IProduct[], flowersMarkup: number, suppliesMarkup: number, plantsMarkup: number): number =>
  roundPrice(
    products.reduce((sum: number, p: IProduct) => {
      const markup =
        p.department === Department.FLOWERS
          ? flowersMarkup
          : p.department === Department.SUPPLIES
            ? suppliesMarkup
            : p.department === Department.PLANTS
              ? plantsMarkup
              : 0;

      return sum + roundPrice((p.price1 * markup) / 100) * p.quantity;
    }, 0),
  );

/**
 * An object containing the calculated total amounts for a recipe.
 */
export interface IRecipeTotals {
  cost: number;
  profit: number;
  price: number;
}

/**
 * Calculates the total cost, profit, and price of a recipe.
 *
 * @param {IRecipe} recipe - The recipe object.
 * @param {number} flowersMarkup - The markup rate for flower products as a decimal number (e.g. 0.2 for 20%).
 * @param {number} suppliesMarkup - The markup rate for supplies products as a decimal number (e.g. 0.15 for 15%).
 * @param {number} plantsMarkup - The markup rate for plant products as a decimal number (e.g. 0.25 for 25%).
 * @returns {Object} - An object containing the cost, profit, and price of the recipe.
 */
export const calculateRecipeTotals = (
  recipe: IRecipe,
  flowersMarkup: number,
  suppliesMarkup: number,
  plantsMarkup: number,
): IRecipeTotals => {
  const products =
    (recipe.products && recipe.products.map((product: IProduct) => ({ ...product, quantity: product.quantity * recipe.quantity }))) || [];
  const cost = roundPrice(products.reduce((sum: number, p: IProduct) => sum + p.price1 * p.quantity, 0));
  const profit = calculateProfit(products, flowersMarkup, suppliesMarkup, plantsMarkup);
  const price = roundPrice(cost + profit);

  return { cost, profit, price };
};

/**
 * Represents a category. A category has a title and an array of products.
 */
interface ICategory {
  title: string;
  products: IProduct[];
}

/**
 * Segregates the products in a recipe into different categories based on their department.
 *
 * @param {IRecipe} recipe - The recipe object containing the products.
 * @returns {ICategory[]} - An array of category objects with the segregated products.
 */
export const segregateProductCategories = (recipe: IRecipe): ICategory[] => {
  const recipeProducts = recipe.products || [];
  return [
    { title: 'Flowers', products: recipeProducts.filter(product => product.department === Department.FLOWERS) },
    { title: 'Supplies', products: recipeProducts.filter(product => product.department === Department.SUPPLIES) },
    { title: 'Plants', products: recipeProducts.filter(product => product.department === Department.PLANTS) },
  ];
};

/**
 * Calculates the total count of products in the given categories.
 *
 * @param {ICategory[]} categories - An array of category objects.
 * @returns {number} - The count of products in the categories.
 */
export const countCategoryProducts = (categories: ICategory[]): number =>
  Object.values(categories).reduce((sum, category) => sum + len(category.products), 0);

/**
 * Retrieves the list of departments which all products from the given recipes belong to.
 *
 * @param {IRecipe[]} recipes - The recipes from which to retrieve the current product departments.
 * @returns {Department[]} - An array of departments products within the recipes belong to.
 */
export const getCurrentProductDepartments = (recipes: IRecipe[]): Department[] => {
  const mergedProducts = mergeRecipes(recipes);

  return Array.from(new Set(mergedProducts.map(p => p.department)));
};

/**
 * Retrieves the muted markup validators based on the given recipes. </br>
 * If there is not any flower within the given recipes, then the <b>flowersMarkup</b> can be muted.
 *
 * @param {IRecipe[]} recipes - An array of recipe objects.
 * @returns {string[]} - An array of muted markup validator names.
 */
export const getMutedMarkupValidators = (recipes: IRecipe[]): string[] => {
  const mutedValidators: string[] = [];
  const departments = getCurrentProductDepartments(recipes);

  if (!departments.includes(Department.FLOWERS)) mutedValidators.push('flowersMarkup');
  if (!departments.includes(Department.SUPPLIES)) mutedValidators.push('suppliesMarkup');
  if (!departments.includes(Department.PLANTS)) mutedValidators.push('plantsMarkup');

  return mutedValidators;
};
