export const ROOT_PATH: string = '/';

/**
 * Root paths that follow hostname
 */
export const LOGIN_PATH: string = 'sign-in';
export const LOGOUT_PATH: string = 'sign-out';
export const EVENTS_PATH: string = '/event';
export const CONTACTS_PATH: string = '/contact';
export const ADMIN_PATH: string = 'admin';
export const RECIPE_ITEMS_PATH: string = 'recipe-item';
export const PRODUCTS_PATH: string = 'product';
export const RECIPES_PATH: string = 'recipe';
export const EVENT_PAYMENTS_PATH: string = 'event-payment';
export const SUBSCRIPTIONS_MANAGEMENT_PATH: string = '/manage-subscriptions';
export const SUBSCRIPTION_PATH: string = '/subscription';
export const PROPOSALS_PATH: string = '/proposal';
export const FLORISTS_PATH: string = 'florist';
export const GALLERY_PATH: string = '/gallery';
export const SETTINGS_PATH: string = '/settings';
export const TERMS_PATH: string = '/terms';
export const PRIVACY_PATH: string = '/privacy';

/**
 * Sub-paths that allow to control entities and follow their ids after /
 */
export const NEW_PATH: string = 'new';
export const EDIT_PATH: string = 'edit';
export const DELETE_PATH: string = 'delete';
export const VIEW_PATH: string = 'view';

/**
 * Paths and sub-path that is used to specify auth-free routes
 */
export const GUEST_PATH: string = 'guest';
export const GUEST_PROPOSAL_VIEW_PATH: string = `${PROPOSALS_PATH}/${VIEW_PATH}/${GUEST_PATH}`;
export const ONE_TIME_LINK_AUTH_PATH: string = 'otl';

/**
 * Settings sub-paths
 */
export const GENERAL_SETTINGS_PATH: string = 'general';
export const COVER_LETTER_SETTINGS_PATH: string = 'cover-letter';
export const AGREEMENT_SETTINGS_PATH: string = 'agreement';

/**
 * Other paths
 */
export const NEW_EVENT_PATH: string = `${EVENTS_PATH}/${NEW_PATH}`;
