import GeneralTab from 'app/entities/settings/tabs/GeneralTab';
import CoverLetterTab from 'app/entities/settings/tabs/CoverLetterTab';
import AgreementTab from 'app/entities/settings/tabs/AgreementTab';
import { AGREEMENT_SETTINGS_PATH, COVER_LETTER_SETTINGS_PATH, GENERAL_SETTINGS_PATH } from 'app/paths';

export interface ISettingsTabProps {
  settingEntries?: any;
  onChangeSetting: (setting: any) => void;
}

/**
 * Attention, please
 * <blockquote style="border-left: 3px solid #C28C2B; padding-left: 10px;">Make sure this tab exists in the <b>settingsTabs</b></blockquote>
 */
export const DEFAULT_TAB: string = GENERAL_SETTINGS_PATH;

/**
 * Object representing the settings tabs.<br>
 * This object contains keys as URL paths and values of the following structure:<br>
 * <code>
 *   {
 *     name: string,
 *     tabComponent: JSX Component
 *   }
 * </code>
 */
export const settingsTabs = {
  [GENERAL_SETTINGS_PATH]: {
    name: 'General Settings',
    tabComponent: GeneralTab,
  },
  [COVER_LETTER_SETTINGS_PATH]: {
    name: 'Cover Letter',
    tabComponent: CoverLetterTab,
  },
  [AGREEMENT_SETTINGS_PATH]: {
    name: 'Terms and Conditions',
    tabComponent: AgreementTab,
  },
};
