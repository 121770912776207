import React, { useEffect, useState } from 'react';
import './signature-styles.scss';
import SignaturePadModal from 'app/shared/signature/SignaturePadModal';

interface SignatureProps {
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  resizeToWidth?: number;
  resizeToHeight?: number;
  color?: string;
  onSubmit?: (signature: any) => void;
}

export interface ISignature {
  signature: string;
  signerName: string;
}

const Signature = ({ className, style, title, resizeToWidth, resizeToHeight, color, onSubmit }: SignatureProps) => {
  const [showPad, setShowPad] = useState(false);
  const [signatureData, setSignatureData] = useState<ISignature>(null);

  const resize = async (sourceData: string): Promise<string> => {
    if (!resizeToWidth && !resizeToHeight) return sourceData;

    if ((resizeToWidth && !resizeToHeight) || (!resizeToWidth && resizeToHeight)) {
      throw new Error("Provide both width and height to resize signature or don't use any.");
    }

    const img = new Image();

    const loadImg = new Promise<void>((resolve, reject) => {
      img.onload = () => resolve();
      img.onerror = reject;
      img.src = sourceData;
    });

    await loadImg;
    const elem = document.createElement('canvas');
    elem.width = resizeToWidth;
    elem.height = resizeToHeight;
    const ctx = elem.getContext('2d');
    if (!ctx) throw new Error('Failed to get canvas context');
    ctx.drawImage(img, 0, 0, resizeToWidth, resizeToHeight);
    return elem.toDataURL();
  };

  const closePadHandler = () => {
    setShowPad(false);
  };

  const signatureClickHandler = () => {
    setShowPad(true);
  };

  const applyHandler = async (data: ISignature) => {
    const resizedSignature = await resize(data.signature);
    const updatedData = { ...data, signature: resizedSignature };
    setSignatureData(updatedData);
    onSubmit(updatedData);
  };

  const renderTitle = () => <div className="signature-title">{title}</div>;

  return (
    <div className={className} style={style} onClick={signatureClickHandler}>
      <div className="signature-canvas">
        {renderTitle()}
        {signatureData ? (
          <img src={signatureData.signature} alt="Your Signature" />
        ) : (
          <div className="signature-placeholder">Click to Sign</div>
        )}
      </div>

      <SignaturePadModal isOpen={showPad} onApply={applyHandler} onClose={closePadHandler} color={color} />
    </div>
  );
};

export default Signature;
