import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Event from './event';
import { EventDetail } from './event-detail';
import EventForm from 'app/entities/event/event-form';
import { EDIT_PATH, NEW_PATH } from 'app/paths';

const EventRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Event />} />
    <Route path={NEW_PATH} element={<EventForm />} />
    <Route path={`:proposalNumber/${EDIT_PATH}`} element={<EventForm />} />
    <Route path=":proposalNumber/*" element={<EventDetail />} />
  </ErrorBoundaryRoutes>
);

export default EventRoutes;
