import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Gallery from 'app/entities/gallery/gallery';

const ContactRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Gallery />} />
  </ErrorBoundaryRoutes>
);

export default ContactRoutes;
