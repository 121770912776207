import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { GALLERY_URL, PICTURE_URL } from 'app/config/url';

export interface IPicture {
  width: number;
  height: number;
  src: string;
}

const initialState: EntityState<IPicture> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  updateSuccess: false,
};

const apiUrl = GALLERY_URL;

const mapPicturesToObjects = (pictures: string[]) => {
  const random = (from: number, to: number) => Math.floor(Math.random() * (to - from + 1)) + from;

  const getSize = () => {
    const width = 100;
    const height = random(width - 40, width - 15);

    return { width, height };
  };

  return pictures.map((picture: string) => ({ src: `https://floriplanner.com/${PICTURE_URL(picture)}`, ...getSize() }));
};

// Actions

export const getPictures = createAsyncThunk('gallery/fetch_picture_list', async () => {
  return axios.get<string[]>(apiUrl);
});

export const uploadPicture = createAsyncThunk(
  'gallery/upload_picture',
  async (file: File, thunkAPI) => {
    const formData = new FormData();
    formData.append('file', file);
    const result = await axios.post<string>(apiUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    thunkAPI.dispatch(getPictures());
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deletePicture = createAsyncThunk(
  'gallery/delete_picture',
  async (filePath: string, thunkAPI) => {
    const result = await axios.delete<string>(apiUrl, { data: filePath });
    thunkAPI.dispatch(getPictures());
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const GallerySlice = createEntitySlice({
  name: 'gallery',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getPictures.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = mapPicturesToObjects(action.payload.data);
      })
      .addCase(deletePicture.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isFulfilled(uploadPicture), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getPictures), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(uploadPicture, deletePicture), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = GallerySlice.actions;

// Reducer
export default GallerySlice.reducer;
