import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import NavMenu from 'app/shared/layout/menus/nav-menu/NavMenu';
import { getMenuItems } from 'app/shared/layout/menus/nav-menu/nav-menu-items';
import { AUTHORITIES } from 'app/config/constants';
import { useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import '../layout/Layout.scss';
import axios from 'axios';
import { SUBSCRIPTIONS_URL } from 'app/config/url';
import RestrictionPopup from 'app/modules/startup/subscription/RestrictionPopup';
import Startup from 'app/modules/startup/Startup';
import { useWindowWidth } from 'app/shared/hooks/useWindowWidth';
import { screenWidth } from 'app/shared/model/enumerations/screen-modes';

const Layout = ({ children = [] }) => {
  const [userRole, setUserRole] = useState(null);
  const [showStartup, setShowStartup] = useState(false);
  const [restrictAccess, setRestrictedAccess] = useState(false);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const width = useWindowWidth();

  useEffect(() => {
    axios.get(SUBSCRIPTIONS_URL).then(res => {
      if (!res.data) {
        setShowStartup(true);
      } else {
        if (res.data.isCanceledEarly) {
          setRestrictedAccess(true);
        }
      }
    });
  }, []);

  useEffect(() => {
    let role: string;

    if (isAdmin) {
      role = AUTHORITIES.ADMIN;
    } else if (isAuthenticated) {
      role = AUTHORITIES.USER;
    } else {
      role = AUTHORITIES.GUEST;
    }

    setUserRole(role);
  }, [isAuthenticated, isAdmin]);

  return (
    <div className="layout">
      <Row className="justify-content-center">
        <Col>
          <Startup showStartup={showStartup} onDone={() => setShowStartup(false)} />
          <RestrictionPopup isVisible={restrictAccess} />
        </Col>
      </Row>

      {width >= screenWidth.MIN_WIDE && <NavMenu items={getMenuItems(userRole)} />}

      <Card className="stretch">
        <Outlet />
      </Card>
    </div>
  );
};

export default Layout;
