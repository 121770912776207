/**
 * A FloriPlanner user model.
 * <br>
 * Make sure it covers a model the back-end side sends in responses.
 */
export interface IUser {
  id?: any;
  login?: string;
  firstName?: string;
  accountNumber?: any;
  sequence?: any;
  phoneNumber?: any;
  mobileNumber?: any;
  role?: any;
  lastName?: string;
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: any[];
  createdBy?: string;
  createdDate?: Date | null;
  lastModifiedBy?: string;
  lastModifiedDate?: Date | null;
  password?: string;
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  accountNumber: '',
  sequence: '',
  phoneNumber: '',
  mobileNumber: '',
  role: '',
  firstName: '',
  lastName: '',
  email: '',
  activated: true,
  langKey: '',
  authorities: [],
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  password: '',
};
