import { MAX_FILE_SIZE_KB, MAX_PICTURE_WIDTH } from 'app/config/constants';

/**
 * Converts a file to JPEG format if possible.
 *
 * @param {Blob} file - The file to convert.
 * @returns {Promise<Blob>} - A promise that resolves with the converted file as a Blob.
 * @throws {Error} - If the conversion to JPEG fails.
 */
export const convertToJpeg = (file: Blob): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = e => {
      if (typeof e.target.result === 'string') {
        img.src = e.target.result;
      }
    };

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0, img.width, img.height);

      canvas.toBlob(blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Conversion to JPEG failed'));
        }
      }, 'image/jpeg');
    };

    reader.readAsDataURL(file);
  });
};

/**
 * Compresses an image file.
 *
 * @param {Blob} file - The image file to compress.
 * @returns {Promise<Blob>} - A Promise that resolves to the compressed image Blob.
 * @throws {Error} - If the compression fails.
 */
export const compressImage = (file: Blob): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    let quality = 0.9;
    const MIN_QUALITY = 0.1;
    const img = new Image();
    const reader = new FileReader();

    reader.onload = e => {
      if (typeof e.target.result === 'string') {
        img.src = e.target.result;
      }
    };

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const compress = () => {
        canvas.toBlob(
          blob => {
            if (blob) {
              if (blob.size <= MAX_FILE_SIZE_KB * 1000 || quality <= MIN_QUALITY) {
                resolve(blob);
              } else {
                quality -= 0.1;
                compress();
              }
            } else {
              reject(new Error('Compression failed'));
            }
          },
          'image/jpeg',
          quality,
        );
      };

      compress();
    };

    reader.readAsDataURL(file);
  });
};

/**
 * Resizes an image file.
 *
 * @param {Blob} file - The input image file.
 * @returns {Promise<Blob>} - The resized image file as a Blob object.
 * @throws {Error} - If the resizing fails.
 */
export const resizeImage = (file: Blob): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = e => {
      if (typeof e.target.result === 'string') {
        img.src = e.target.result;
      }
    };

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const aspectRatio = img.width / img.height;
      let newWidth = img.width;
      let newHeight = img.height;

      if (img.width > MAX_PICTURE_WIDTH) {
        newWidth = MAX_PICTURE_WIDTH;
        newHeight = MAX_PICTURE_WIDTH / aspectRatio;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Resize failed'));
        }
      }, file.type);
    };

    reader.readAsDataURL(file);
  });
};
