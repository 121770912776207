import florist from 'app/entities/florist/florist.reducer';
import contact from 'app/entities/contact/contact.reducer';
import event from 'app/entities/event/event.reducer';
import recipeItem from 'app/entities/recipe-item/recipe-item.reducer';
import product from 'app/entities/product/product.reducer';
import recipe from 'app/entities/recipe/recipe.reducer';
import eventPayment from 'app/entities/event-payment/event-payment.reducer';
import proposal from 'app/entities/proposal/proposal.reducer';
import gallery from 'app/entities/gallery/gallery.reducer';
import tax from 'app/entities/tax/tax.reducer';
import shipdays from 'app/entities/shipdays/shipdays.reducer';
import settings from 'app/entities/settings/settings.reducer';
import productCustomNames from 'app/entities/custom-product-names/custom-product-names.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  florist,
  contact,
  gallery,
  event,
  recipeItem,
  product,
  recipe,
  eventPayment,
  proposal,
  tax,
  shipdays,
  settings,
  productCustomNames,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
