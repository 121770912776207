import React from 'react';
import './loading-styles.scss';

const Loading = ({ text = 'Loading...', center = false, padding = false }) => (
  <div className={`loading-container ${center ? 'center' : ''} ${padding ? 'padding' : ''}`}>
    <div className="loading">
      <div className="spinner" />
      <div>{text}</div>
    </div>
  </div>
);

export default Loading;
