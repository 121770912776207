import { Button } from 'reactstrap';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavMenu.scss';

export default ({ name, icon, path }) => {
  const [isActive, setActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setActive(location.pathname.includes(path));
  }, [path, location]);

  return (
    <div>
      <Button tag={Link} to={path} className={`nav-component-row ${isActive ? 'active' : ''}`}>
        <div className={`nav-component ${isActive ? 'active' : ''}`}>
          {icon && <Icon path={icon} size={0.75} style={{ marginRight: 5 }} />}
          {name}
        </div>
      </Button>
    </div>
  );
};
