import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IProduct, defaultValue } from 'app/shared/model/product.model';
import { SEARCH_URL } from 'app/config/url';

const initialState: EntityState<IProduct> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = SEARCH_URL;

// Actions

export const searchEntities = createAsyncThunk('product/fetch_entity_list', async (params: string) => {
  const requestUrl = `${apiUrl}${params}`;
  const response = await axios.get<IProduct[]>(requestUrl);
  return response.data;
});

export const getEntities = createAsyncThunk('product/fetch_entity_list', async (params: IQueryParams = {}) => {
  const { names, sort } = params;
  const nameParam = names ? `names=${names}&` : '';
  const sortParam = sort ? `sort=${sort}&` : '';
  const requestUrl = `${apiUrl}?${nameParam}${sortParam}cacheBuster=${new Date().getTime()}`;
  const response = await axios.get<IProduct[]>(requestUrl);
  return response.data;
});

export const getEntity = createAsyncThunk(
  'product/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    const response = await axios.get<IProduct>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'product/create_entity',
  async (entity: IProduct, thunkAPI) => {
    const result = await axios.post<IProduct>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result.data;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'product/update_entity',
  async (entity: IProduct, thunkAPI) => {
    // Disabled as we must not update product entities
    // const result = await axios.put<IProduct>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    // return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'product/partial_update_entity',
  async (entity: IProduct, thunkAPI) => {
    // Disabled as we must not update product entities
    // const result = await axios.patch<IProduct>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    // return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'product/delete_entity',
  async (id: string | number, thunkAPI) => {
    // Disabled as we must not delete product entities
    // const requestUrl = `${apiUrl}/${id}`;
    // const result = await axios.delete<IProduct>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    // return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const ProductSlice = createEntitySlice({
  name: 'product',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload; // Доступ к данным непосредственно
      })
      .addCase(deleteEntity.fulfilled, state => {
        // state.updating = false;
        // state.updateSuccess = true;
        // state.entity = {};
      })
      .addCase(searchEntities.fulfilled, (state, action) => {
        state.entities = action.payload; // Доступ к данным непосредственно
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const data = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        // state.updating = false;
        // state.loading = false;
        // state.updateSuccess = true;
        // state.entity = action.payload;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        // state.errorMessage = null;
        // state.updateSuccess = false;
        // state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        // state.errorMessage = null;
        // state.updateSuccess = false;
        // state.updating = true;
      });
  },
});

export const { reset } = ProductSlice.actions;

// Reducer
export default ProductSlice.reducer;
