import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React from 'react';
import { Color } from 'app/shared/model/enumerations/color.model';

export interface IModalDialogButtonProps {
  label: string;
  color: Color;
  outline?: boolean;
  clickHandler: () => void;
}

interface IModalDialogProps {
  isOpen: boolean;
  modalTitle?: string;
  modalText: any;
  buttons: IModalDialogButtonProps[];
  toggleHandler?: () => void;
}

const ModalDialog = ({ isOpen, modalTitle = '', modalText, buttons, toggleHandler }: IModalDialogProps) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleHandler}>
      {!!modalTitle && <ModalHeader>Your Contract</ModalHeader>}

      <ModalBody>{modalText}</ModalBody>

      <ModalFooter>
        {buttons.map((button, i) => (
          <Button color={button.color} outline={button.outline} onClick={button.clickHandler}>
            {button.label}
          </Button>
        ))}
      </ModalFooter>
    </Modal>
  );
};

export default ModalDialog;
