import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { GUEST_PROPOSAL_VIEW_PATH } from 'app/paths';
import PageNotFound from 'app/shared/alert/page-not-found';
import { Button } from 'reactstrap';
import './proposal-styles.scss';
import { API, PROPOSAL_URL } from 'app/config/url';
import Icon from '@mdi/react';
import { mdiBookCheckOutline } from '@mdi/js';
import Signature, { ISignature } from 'app/shared/signature/Signature';
import { createRoot } from 'react-dom/client';
import Loading from 'app/shared/alert/loading';

export const PROPOSAL_MOBILE_MAX_WIDTH: number = 750;

export const ProposalView = ({ guest = false }) => {
  const { id } = useParams();
  const [proposalContent, setProposalContent] = useState(null);
  const [agreement, setAgreement] = useState<string>(null);
  const [isAgreementExpanded, setAgreementExpanded] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const [isApproved, setApproved] = useState(false);
  const [signatureData, setSignatureData] = useState<ISignature>(null);
  const [isSigned, setSigned] = useState(false);
  const [isBusy, setBusy] = useState(true);
  const [proposalHeight, setProposalHeight] = useState(0);
  const [isMobile, setMobile] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (id !== undefined) {
      fetchDocument();
    }
  }, [id]);

  useEffect(() => {
    if (containerRef.current) {
      const container = document.createElement('div');
      container.innerHTML = proposalContent;
      const agreement = container.querySelector('#agreement');

      if (agreement) {
        setAgreement(agreement.outerHTML);
        agreement.remove();
        setProposalContent(container.innerHTML);
      }
    }
  }, [proposalContent]);

  const updateContainerHeight = () => {
    const isMobileView = window.innerWidth <= PROPOSAL_MOBILE_MAX_WIDTH;
    setMobile(isMobileView);

    if (containerRef.current) {
      const offerDetailsContainer = containerRef.current.querySelector('#offer-details-container');
      const invoiceContainer = containerRef.current.querySelector('.invoice-container');
      const offerDetailsHeight = offerDetailsContainer ? offerDetailsContainer.scrollHeight : 0;
      const invoiceHeight = invoiceContainer ? invoiceContainer.scrollHeight : 0;
      let height = offerDetailsHeight + invoiceHeight;

      if (isMobileView) height += 40;

      setProposalHeight(height);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      updateContainerHeight();
    };

    window.addEventListener('resize', handleResize);

    setTimeout(() => {
      updateContainerHeight();
    }, 25);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [proposalContent]);

  useEffect(() => {
    if (!isSigned && containerRef.current) {
      const signatureElement = containerRef.current.querySelector('.signature');

      if (signatureElement && signatureElement.textContent.trim() === 'signature anchor') {
        const parent = signatureElement.parentElement;
        const signatureContainer = document.createElement('div');
        signatureContainer.className = 'signature-container-wrapper';
        parent.replaceChild(signatureContainer, signatureElement);
        const signaturePlaceholder = agreement
          ? 'By signing above, I agree to the terms and conditions outlined in this proposal.'
          : 'By signing below, I confirm that the order is correct and complete.';

        const root = createRoot(signatureContainer);
        root.render(
          <Signature
            className={`signature-container ${signatureData ? 'filled' : ''}`}
            title={signaturePlaceholder}
            color={'#3b59cf'}
            onSubmit={submitSignatureHandler}
          />,
        );
      }
    }
  }, [proposalContent, guest, signatureData, isSigned, agreement]);

  const fetchDocument = () => {
    const apiHost = `${API}`;
    const url = `${apiHost}${GUEST_PROPOSAL_VIEW_PATH}/${id}`;
    axios
      .get(url)
      .then(res => {
        const content = res.data;
        setProposalContent(content);
        setSigned(!content.includes('signature anchor'));
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          setNotFound(true);
        }
      })
      .finally(() => setBusy(false));
  };

  const approveProposal = () => {
    setBusy(true);
    const url = `${PROPOSAL_URL}/approve`;
    const formData = new FormData();
    formData.append('publicId', id);
    formData.append('signature', signatureData.signature);
    formData.append('signerName', signatureData.signerName);
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(r => {
        if (r.status >= 200 && r.status < 300) setApproved(true);
      })
      .catch(err => console.error(err))
      .finally(() => setBusy(false));
  };

  const submitSignatureHandler = (data: ISignature) => {
    setSignatureData(data);
  };

  const renderAgreement = () =>
    agreement && (
      <div className={`agreement ${isAgreementExpanded ? 'expanded' : ''}`}>
        <div
          className="agreement-spoiler-header"
          onClick={() => {
            setAgreementExpanded(prev => !prev);
          }}
        >
          {isAgreementExpanded ? 'Hide' : 'Terms and Conditions'}
        </div>

        {isAgreementExpanded && <div dangerouslySetInnerHTML={{ __html: agreement }} />}
      </div>
    );

  const renderProposal = () => (
    <div className={`guest-proposal-container ${guest ? 'guest' : ''}`} ref={containerRef}>
      <div dangerouslySetInnerHTML={{ __html: proposalContent }} style={{ display: isMobile ? 'flex' : 'unset', height: proposalHeight }} />

      {renderAgreement()}

      {!isSigned && (
        <Button onClick={approveProposal} color="primary" disabled={!signatureData || isBusy} style={{ marginTop: '20px' }}>
          {isBusy ? 'Submitting...' : 'Submit'}
        </Button>
      )}
    </div>
  );

  const renderConfirmation = () => (
    <div className="proposal-approval">
      <Icon path={mdiBookCheckOutline} size={6} className="approval-icon" />
      <p>Thank you for choosing our services.</p>
      <p>We will be in touch soon with next steps.</p>
    </div>
  );

  return isNotFound ? (
    <PageNotFound />
  ) : isApproved ? (
    renderConfirmation()
  ) : proposalContent ? (
    renderProposal()
  ) : (
    isBusy && <Loading center />
  );
};
