import React from 'react';
import { Label } from 'reactstrap';
import './switch-styles.scss';
import { Color } from 'app/shared/model/enumerations/color.model';

interface ISwitchProps {
  leftLabel?: any;
  rightLabel?: any;
  isAtRight: boolean;
  leftColor?: 'none' | Color;
  rightColor?: 'none' | Color;
  disabled?: boolean;
  onToggle: () => void;
}

const ToggleSwitch = ({ leftLabel, rightLabel, isAtRight, leftColor, rightColor, disabled, onToggle }: ISwitchProps) => {
  const toggleHandler = () => {
    if (!disabled) onToggle();
  };

  const getSliderClassName = () => {
    const colorClass = isAtRight ? rightColor : leftColor;
    return `slider ${colorClass}-switch ${disabled ? 'disabled' : ''}`;
  };

  const getLabelClassName = (isRightLabel: boolean) => {
    if (disabled) return '';
    if (isRightLabel) {
      return isAtRight && rightColor ? `${rightColor}-label` : '';
    } else {
      return !isAtRight && leftColor ? `${leftColor}-label` : '';
    }
  };

  return (
    <div className={`switch-container ${disabled ? 'disabled' : ''}`} onClick={toggleHandler}>
      {leftLabel && <span className={`label ${getLabelClassName(false)}`}>{leftLabel}</span>}

      <Label className="switch" style={{ margin: !!rightLabel ? '0 10px 0 0' : 0 }}>
        <input
          type="checkbox"
          className="switch-input"
          disabled={disabled}
          checked={isAtRight}
          onChange={e => {
            toggleHandler();
            e.stopPropagation();
          }}
        />
        <span className={getSliderClassName()} />
      </Label>

      {rightLabel && <span className={`label ${getLabelClassName(true)}`}>{rightLabel}</span>}
    </div>
  );
};

export default ToggleSwitch;
