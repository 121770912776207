import {
  mdiBookCheckOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiDelete,
  mdiEraser,
  mdiFileEditOutline,
  mdiPencil,
  mdiProgressClock,
  mdiThumbDownOutline,
  mdiThumbUpOutline,
  mdiHistory,
  mdiPlus,
  mdiRefresh,
  mdiShieldHalfFull,
  mdiFormatListBulleted,
  mdiListStatus,
  mdiImage,
  mdiCog,
  mdiBookOpenPageVariant,
  mdiCardAccountPhone,
  mdiChartBox,
  mdiCalendar,
  mdiCancel,
  mdiBookArrowRightOutline,
  mdiChevronDoubleUp,
  mdiChevronDoubleDown,
  mdiEyeOff,
  mdiMessageText,
  mdiPhone,
  mdiRestore,
  mdiClockTimeFourOutline,
  mdiCheckCircle,
  mdiCloseCircleOutline,
  mdiAccountGroup,
  mdiTrendingUp,
  mdiTrendingDown,
  mdiLogout,
  mdiCreditCardOutline,
  mdiMenu,
} from '@mdi/js';

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  GUEST: 'ROLE_GUEST',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT: string = 'M/D/YY';
export const APP_DATE_TIME_FORMAT: string = 'M/D/YY ';
export const APP_TIMESTAMP_FORMAT: string = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT: string = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT: string = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT: string = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT: string = '0,0.[00]';

export const USA_DATE_FORMAT: string = 'M/D/YY';
export const USA_DATE_TIME_FORMAT: string = 'M/D/YY H:MM';

export const DAYS_TO_EARLIEST_EVENT: number = 21;
export const EXPIRES_LATEST_DAYS_BEFORE_EVENT: number = 14;

export const SUBSCRIPTION_CHARGE_PERIOD: number = 30;

/**
 * A collection of Material Design Icons for consistent use.
 */
export const mdi = {
  delete: mdiDelete,
  edit: mdiPencil,
  expand: mdiChevronDown,
  collapse: mdiChevronUp,
  expandAll: mdiChevronDoubleDown,
  collapseAll: mdiChevronDoubleUp,
  ordered: mdiBookCheckOutline,
  approved: mdiThumbUpOutline,
  pending: mdiProgressClock,
  draft: mdiFileEditOutline,
  close: mdiClose,
  clear: mdiClose,
  declined: mdiThumbDownOutline,
  erase: mdiEraser,
  plus: mdiPlus,
  refresh: mdiRefresh,
  history: mdiHistory,
  shield: mdiShieldHalfFull,
  bulletList: mdiFormatListBulleted,
  statusList: mdiListStatus,
  image: mdiImage,
  settings: mdiCog,
  catalog: mdiBookOpenPageVariant,
  contactCard: mdiCardAccountPhone,
  chart: mdiChartBox,
  calendar: mdiCalendar,
  cancel: mdiCancel,
  convertToOrder: mdiBookArrowRightOutline,
  hide: mdiEyeOff,
  message: mdiMessageText,
  phone: mdiPhone,
  restore: mdiRestore,
  trial: mdiClockTimeFourOutline,
  active: mdiCheckCircle,
  canceled: mdiCloseCircleOutline,
  accounts: mdiAccountGroup,
  growth: mdiTrendingUp,
  fall: mdiTrendingDown,
  logout: mdiLogout,
  card: mdiCreditCardOutline,
  menu: mdiMenu,
};

/**
 * An array containing the names of all 50 states in the United States.
 *
 * @type {string[]}
 */
export const states: string[] = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

/**
 * An object that represents the state codes of the United States.
 *
 * @type {Object.<string, string>}
 */
export const stateCodes: { [key: string]: string } = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

/**
 * The constant variable representing the size of an image thumbnail.
 *
 * @type {number}
 * @default 50
 */
export const PRODUCT_PHOTO_SIZE_FULL: number = 50;
export const PRODUCT_PHOTO_SIZE_MOBILE: number = 35;

/**
 * Gallery constants
 */
export const MAX_PICTURE_WIDTH: number = 1024;
export const MAX_FILE_SIZE_KB: number = 500;

export const DEFAULT_COVER_LETTER: string =
  'Hi [first name],\n' +
  '\n' +
  'Imagine a world where flowers speak a language of love, elegance, and joy. We believe that every floral arrangement is a masterpiece, a testament to the beauty and artistry of nature.\n' +
  '\n' +
  "We're thrilled to present our floral proposal for your upcoming [event] at [event time], [event date]. Inside, you'll find a curated selection of designs that capture the essence of your special occasion. From the romantic allure of a cascading bridal bouquet to the vibrant energy of a reception centerpiece, each arrangement is crafted with meticulous attention to detail.\n" +
  '\n' +
  "We're more than just a florist; we're your partner in creating unforgettable memories. Let's work together to transform your vision into a floral reality.";
