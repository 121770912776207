import './header.scss';
import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavbarToggler, Collapse } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { Brand } from './header-components';
import './header.scss';
import AccountMenu from 'app/shared/layout/menus/account';
import { useWindowWidth } from 'app/shared/hooks/useWindowWidth';
import { screenWidth } from 'app/shared/model/enumerations/screen-modes';
import { useDrawer } from 'app/shared/layout/menus/drawer/Drawer';
import ActionButton from 'app/shared/action-buttons/ActionButton';
import { mdi } from 'app/config/constants';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  onLoaded: () => void;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const width = useWindowWidth();
  const { openDrawer } = useDrawer(); // Используем хук здесь

  useEffect(() => {
    props.onLoaded();
  }, []);

  const renderDevRibbon = () =>
    !props.isInProduction && (
      <div className="ribbon dev">
        <a href="">Development</a>
      </div>
    );

  const toggleMenu = () => setMenuOpen(!menuOpen);

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div id="app-header">
      {/*{renderDevRibbon()}*/}
      <LoadingBar className="loading-bar" />
      <Navbar
        data-cy="navbar"
        dark
        expand="md"
        // uncomment the next line to make the header fixed (see the app.scss)
        // fixed="top"
        className="header"
      >
        {width >= screenWidth.MIN_WIDE && <NavbarToggler aria-label="Menu" onClick={toggleMenu} />}

        <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%' }}>
          {width < screenWidth.MIN_WIDE && (
            <div style={{ flexGrow: 1 }}>
              <ActionButton icon={mdi.menu} name="Menu" clickHandler={openDrawer} />
            </div>
          )}

          <Brand />

          {width < screenWidth.MIN_WIDE && <div style={{ flexGrow: 1 }} />}
        </div>

        {width >= screenWidth.MIN_WIDE && (
          <Collapse isOpen={menuOpen} navbar>
            <Nav id="header-tabs" className="ms-auto" navbar>
              {/*<Home />*/}
              {/*{props.isAuthenticated && <EntitiesMenu />}*/}
              {/*{props.isAuthenticated && props.isAdmin && (*/}
              {/*  <AdminMenu showOpenAPI={props.isOpenAPIEnabled} showDatabase={!props.isInProduction} />*/}
              {/*)}*/}
              <AccountMenu isAuthenticated={props.isAuthenticated} />
            </Nav>
          </Collapse>
        )}
      </Navbar>
    </div>
  );
};

export default Header;
