import React, { useState } from 'react';
import { SubscriptionConfigManagement } from 'app/admin/SubscriptionConfigManagement';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { SubscriptionManagement } from 'app/admin/SubscriptionManagement';
import classnames from 'classnames';
import AdminDashboard from 'app/shared/dashboard/AdminDashboard';

const tabs = {
  subscribers: 'Subscribers',
  plans: 'Plans',
};

export const SubscriptionsPage = () => {
  const [activeTab, setActiveTab] = useState(tabs.subscribers);

  return (
    <div className="flori-form">
      <h4>Subscriptions Management</h4>

      <AdminDashboard />

      <Nav tabs>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === tabs.subscribers })} onClick={() => setActiveTab(tabs.subscribers)}>
            {tabs.subscribers}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink className={classnames({ active: activeTab === tabs.plans })} onClick={() => setActiveTab(tabs.plans)}>
            {tabs.plans}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} style={{ paddingTop: '10px' }}>
        <TabPane tabId={tabs.subscribers}>
          <SubscriptionManagement />
        </TabPane>

        <TabPane tabId={tabs.plans}>
          <SubscriptionConfigManagement />
        </TabPane>
      </TabContent>
    </div>
  );
};
