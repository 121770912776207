import React, { useEffect, useRef, useState } from 'react';
import { IProduct } from 'app/shared/model/product.model';
import { Input } from 'reactstrap';
import ActionButton from 'app/shared/action-buttons/ActionButton';
import { mdi } from 'app/config/constants';

interface ProductNameCellProps {
  product: IProduct;
  name?: string;
  editable?: boolean;
  onRename?: (name: string) => void;
}

const ProductNameCell = ({ product, name = '', editable, onRename }: ProductNameCellProps) => {
  const [isEditMode, setEditMode] = useState(false);
  const [productName, setProductName] = useState<string>('');
  const [productNameBackup, setProductNameBackup] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setProductName(name.trim());
    setProductNameBackup(name.trim());
  }, [name]);

  useEffect(() => {
    if (isEditMode) {
      inputRef.current?.focus();
    }
  }, [isEditMode]);

  const cancelRename = () => {
    setEditMode(false);
    setProductName(productNameBackup);
  };

  const applyName = () => {
    onRename(productName);
    setEditMode(false);
  };

  const renameHandler = () => {
    setProductNameBackup(productName);
    setEditMode(true);
  };

  const restoreHandler = () => {
    onRename('');
  };

  const renderRenameButton = () => <ActionButton icon={mdi.edit} name="Rename" clickHandler={renameHandler} />;

  const renderRestoreButton = () => <ActionButton icon={mdi.restore} name="Restore Default Name" clickHandler={restoreHandler} />;

  return (
    <td className="product-name">
      <div
        style={editable ? { display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' } : {}}
        onClick={editable ? renameHandler : null}
      >
        {isEditMode ? (
          <Input
            innerRef={inputRef}
            value={productName}
            style={{ padding: '2px 5px' }}
            maxLength={100}
            onChange={e => setProductName(e.target.value)}
            onBlur={applyName}
            onKeyDown={event => {
              if (event.key === 'Escape') {
                cancelRename();
              } else if (event.key === 'Enter') {
                applyName();
              }
            }}
          />
        ) : (
          <div className="product-description">{productName}</div>
        )}

        {editable && !isEditMode && (
          <div style={{ margin: '-4px 0 0 10px', display: 'flex' }}>
            <div style={{ paddingRight: '5px' }}>{renderRenameButton()}</div>
            {name.trim() !== product.productDescription.trim() && renderRestoreButton()}
          </div>
        )}
      </div>

      <div className="product-id">{product.productId}</div>
    </td>
  );
};

export default ProductNameCell;
