import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Settings from 'app/entities/settings/settings';

const SettingsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route path="*" element={<Settings />} />
  </ErrorBoundaryRoutes>
);

export default SettingsRoutes;
