import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { SUBSCRIPTION_CONFIG_URL } from 'app/config/url';
import { SubscriptionConfig } from 'app/shared/model/subscription-config.model';
import { Table, Button, Input } from 'reactstrap';
import { ChargePeriod } from 'app/shared/model/enumerations/charge-period.model';
import './SubscriptionPage.css';

export const SubscriptionConfigManagement = () => {
  const [config, setConfig] = useState<SubscriptionConfig | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [tempConfig, setTempConfig] = useState<SubscriptionConfig | null>(null);

  useEffect(() => {
    axios
      .get(`${SUBSCRIPTION_CONFIG_URL}/current`)
      .then(res => {
        setConfig(res.data);
        setTempConfig(res.data);
      })
      .catch(error => {
        console.error('Failed to fetch configuration:', error);
      });
  }, []);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    if (!tempConfig) return;

    axios
      .post(`${SUBSCRIPTION_CONFIG_URL}/update`, tempConfig)
      .then(response => {
        setConfig(response.data);
        setEditMode(false);
        console.log('Configuration updated successfully.');
      })
      .catch(error => {
        console.error('Failed to update configuration:', error);
      });
  };

  const handleChange = (key: keyof SubscriptionConfig, value: any) => {
    if (tempConfig) {
      setTempConfig({ ...tempConfig, [key]: value });
    }
  };

  return (
    <Table className="table-row">
      <thead>
        <tr>
          <td>Plan</td>
          <td>Period</td>
          <td>Bill</td>
          <td>Amount</td>
          <td>Actions</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          {editMode ? (
            <>
              <td>
                <Input type="text" value={tempConfig?.name} onChange={e => handleChange('name', e.target.value)} />
              </td>
              <td>
                {editMode ? (
                  <Input
                    type="select"
                    value={tempConfig?.chargePeriod}
                    onChange={e => handleChange('chargePeriod', e.target.value)}
                    style={{ display: 'block', width: '100%' }}
                  >
                    {Object.values(ChargePeriod).map(period => (
                      <option key={period} value={period}>
                        {period}
                      </option>
                    ))}
                  </Input>
                ) : (
                  config?.chargePeriod
                )}
              </td>
              <td>
                <Input type="text" value={tempConfig?.productName} onChange={e => handleChange('productName', e.target.value)} />
              </td>
              <td>
                <Input type="number" value={tempConfig?.amount} onChange={e => handleChange('amount', e.target.value)} />
              </td>
            </>
          ) : (
            <>
              <td>{config?.name}</td>
              <td>{config?.chargePeriod}</td>
              <td>{config?.productName}</td>
              <td>{config?.amount}</td>
            </>
          )}
          <td>
            {editMode ? (
              <Button color="primary" className="edit-button" onClick={handleSave}>
                Save
              </Button>
            ) : (
              <Button color="secondary" className="edit-button" onClick={handleEdit}>
                Edit
              </Button>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
