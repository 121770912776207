import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import SignatureCanvas from 'react-signature-canvas';
import Icon from '@mdi/react';
import { PROPOSAL_MOBILE_MAX_WIDTH } from 'app/entities/proposal/ProposalView';
import { ISignature } from 'app/shared/signature/Signature';
import ValidationError from 'app/shared/error/ValidationError';
import { getError } from 'app/util/validation-utils';
import { mdi } from 'app/config/constants';
import ActionButton from 'app/shared/action-buttons/ActionButton';

interface SignaturePadModalProps {
  isOpen: boolean;
  color?: string;
  onApply: (signature: ISignature) => void;
  onClose: () => void;
}

const SignaturePadModal = ({ isOpen, color, onApply, onClose }: SignaturePadModalProps) => {
  const [signature, setSignature] = useState(null);
  const [isEmpty, setEmpty] = useState(true);
  const [width, setWidth] = useState(null);
  const [name, setName] = useState('');
  const [isMobile, setMobile] = useState(false);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const canvasRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= PROPOSAL_MOBILE_MAX_WIDTH;
      const modalWidth = isMobileView ? window.innerWidth - 100 : null;
      setWidth(modalWidth);
      setMobile(isMobileView);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const commitDrawingHandler = () => {
    if (canvasRef.current) {
      setSignature(canvasRef.current.toDataURL());
    }
  };

  const startDrawingHandler = () => {
    setEmpty(false);
  };

  const applyHandler = () => {
    onApply({ signature, signerName: name });
    closeHandler();
  };

  const closeHandler = () => {
    setSignature('');
    setEmpty(true);
    setErrors({});
    setTouched({});
    onClose();
  };

  const renderActionButton = (icon: string, name: string, disabled: boolean, clickHandler: () => void) => (
    <div
      className={`action-button ${disabled ? 'disabled' : ''}`}
      onClick={() => {
        if (!disabled) clickHandler();
      }}
    >
      <Icon path={icon} size={0.75} title={name} />
    </div>
  );

  const renderClearButton = () => (
    <div className="clear-button">
      <ActionButton
        icon={mdi.erase}
        name="Erase"
        disabled={!signature}
        clickHandler={() => {
          setSignature(null);
          canvasRef.current.clear();
        }}
      />
    </div>
  );

  const renderCanvas = () => (
    <div className="canvas-container">
      <SignatureCanvas ref={canvasRef} onBegin={startDrawingHandler} onEnd={commitDrawingHandler} penColor={color} />
    </div>
  );

  const renderPlaceholder = () => isEmpty && <div className="signature-placeholder in-editor">Draw your signature</div>;

  const renderEditor = () => (
    <div
      className="signature-edit-area"
      style={isMobile ? { width: `${width + 45}px`, height: `${(width + 45) / 2}px` } : { width: '660px', height: '308px' }}
    >
      {renderPlaceholder()}
      {renderCanvas()}
      {renderClearButton()}
    </div>
  );

  const touchId = (id: string) => {
    const updatedValues = { ...touched, [id]: id };
    setTouched(updatedValues);
    validate(id, name);
  };

  const validate = (id: string, value: any) => {
    const error = getError(id, value);
    setErrors(prevErrors => ({ ...prevErrors, [id]: error }));
  };

  const changeHandler = (e: any) => {
    const id = e.target.id;
    const value = e.target.value;
    setName(value);

    if (errors[id]) {
      validate(id, value);
    } else {
      touchId(id);
    }
  };

  const renderNameInput = () => {
    const id = 'signerName';

    return (
      <FormGroup style={{ paddingTop: '20px' }}>
        <Input
          id={id}
          type="text"
          value={name}
          onChange={changeHandler}
          onBlur={() => {
            touchId(id);
          }}
          invalid={!!errors[id]}
        />
        <span>
          <Label for={id}>Your name</Label>
          <ValidationError id={id} errors={errors} touched={touched} label="Your name" />
        </span>
      </FormGroup>
    );
  };

  const renderModalFooter = () => (
    <Row className="action-footer">
      <Col className="text-end">
        <Button color="secondary" onClick={closeHandler}>
          Cancel
        </Button>

        <Button color="primary" style={{ marginLeft: '20px' }} onClick={applyHandler} disabled={!signature || !name}>
          Apply
        </Button>
      </Col>
    </Row>
  );

  return (
    <Modal
      className={`${isMobile ? 'signature-small-dialog' : 'form-modal'}`}
      style={{ maxWidth: width }}
      isOpen={isOpen}
      toggle={closeHandler}
    >
      <div className="event-form">
        {renderEditor()}
        {renderNameInput()}
        {renderModalFooter()}
      </div>
    </Modal>
  );
};

export default SignaturePadModal;
