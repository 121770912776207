import { dateFromTimeString } from 'app/util/date-time-utils';
import { len } from 'app/util/structure-utils';
import { EMAIL_REGEX } from 'app/util/regexs-constants';
import { IProduct } from 'app/shared/model/product.model';
import { extractNumbers } from 'app/util/format-utils';

/**
 * Provides validators for different purposes each with its own set of rules.
 */
export const validators = {
  eventName: (value: any) => requireString(value, 'Event Name'),
  eventDate: (value: any) => requireDate(value, 'Event Date'),
  firstName: (value: any) => requireString(value, 'First Name'),
  lastName: (value: any) => requireString(value, 'Last Name'),
  streetAddress: (value: any) => requireString(value, 'Street Address'),
  streetAddressLine2: (value: any) => validateString(value, 'Street Address Line 2'),
  city: (value: any) => requireString(value, 'City'),
  state: (value: any) => requireString(value, 'State'),
  zipCode: (value: any) => requirePositiveNumber(value, 'Zip Code'),
  note: (value: any) => validateString(value, 'Note'),
  deliveryDate: (value: any) => requireDate(value, 'Delivery Date'),
  expirationDate: (value: any) => requireDate(value, 'Expiration Date'),
  flowersMarkup: (value: any) => requirePositiveNumber(value, 'Flowers Markup %'),
  suppliesMarkup: (value: any) => requirePositiveNumber(value, 'Supplies Markup %'),
  plantsMarkup: (value: any) => requirePositiveNumber(value, 'Plants Markup %'),
  laborFee: (value: any) => validateNumber(value, 'Labor Fee $'),
  setupFee: (value: any) => validateNumber(value, 'Setup Fee $'),
  deliveryFee: (value: any) => (value === undefined ? null : validateNumber(value, 'Delivery Fee $')),
  quantity: (value: any) => requirePositiveNumber(value, 'Quantity'),
  signerName: (value: any) => requireString(value, 'Your name'),
  eventTime: (value: any) => {
    const testStrings = requireString(value, 'Event Time');

    if (testStrings) return testStrings;
    if (dateFromTimeString(value) === null) return 'Event Time must be a valid time';
    return null;
  },
  email: (value: any) => {
    const testStrings = requireString(value, 'Email');

    if (testStrings) return testStrings;
    if (!EMAIL_REGEX.test(value)) return 'Email is invalid';
    return null;
  },
  phoneNumber: (value: any) => {
    if (!value) return 'Phone Number is required';

    const digits = extractNumbers(value);

    if (!((len(digits) === 10 && !digits.startsWith('1')) || (len(digits) === 11 && digits.startsWith('1'))))
      return 'Phone Number is invalid';

    return null;
  },
  recipes: (value: any) => {
    if (!value || !Array.isArray(value) || len(value) === 0) {
      return 'At least 1 product is required';
    }

    for (let i = 0; i < value.length; i++) {
      const recipe = value[i];

      if (validators.quantity(recipe.quantity)) return validators.quantity(recipe.quantity);

      if (!recipe.products || !Array.isArray(recipe.products) || len(recipe.products) === 0) {
        return 'Recipe can not be empty';
      }

      if (recipe.products.some((item: IProduct) => validators.quantity(item.quantity))) {
        return validators.quantity(-1);
      }
    }

    return null;
  },
};

/**
 * Validates if a value is a valid date.
 * @param {any} value - The value to be validated.
 * @param {string} name - The name of the value.
 * @returns {string} - The error message if the value is not a valid date, otherwise null.
 */
const requireDate = (value: any, name: string): string => {
  if (!value) return `${name} is required`;
  if (!(value instanceof Date) && isNaN(Date.parse(value))) return `${name} must be a valid date`;
  return null;
};

/**
 * Validates if the given value is a positive number and returns an error message if it is not.
 *
 * @param {*} value - The value to be validated.
 * @param {string} name - The name of the value being validated.
 * @returns {string} - The error message if the value is not a positive number, otherwise null.
 */
const requirePositiveNumber = (value: any, name: string): string => {
  if (!value) return `${name} is required`;
  return validatePositiveNumber(value, name);
};

/**
 * Validates if a value is a number.
 *
 * @param {any} value - The value to be validated.
 * @param {string} name - The name of the variable.
 * @returns {string|null} - Returns an error message if the value is not a number, otherwise returns null.
 */
const validateNumber = (value: any, name: string): string => (Number.isNaN(Number(value)) ? `${name} must be a number` : null);

/**
 * Validates if a value is a positive number.
 *
 * @param {any} value - The value to be validated.
 * @param {string} name - The name of the value being validated.
 * @returns {string|null} - Returns an error message if the value is not a positive number, otherwise returns null.
 */
const validatePositiveNumber = (value: any, name: string): string =>
  validateNumber(value, name) || value < 1 ? `${name} must be a positive number` : null;

/**
 * Validates if a given value is a non-empty string.
 *
 * @param {any} value - The value to be validated.
 * @param {string} name - The name of the value.
 * @returns {string} - Error message if the value is not valid, empty string otherwise.
 */
const requireString = (value: any, name: string): string => {
  if (!value) return `${name} is required`;
  return validateString(value, name);
};

/**
 * Validates if a given value is a string.
 *
 * @param {any} value - The value to be validated.
 * @param {string} name - The name of the variable being validated.
 *
 * @returns {string} - An error message if the value is not a string, otherwise null.
 */
const validateString = (value: any, name: string): string => (!!(value && typeof value !== 'string') ? `${name} must be a string` : null);

/**
 * Get error message for a given validator ID and value.
 *
 * @param {string} id - The ID of the validator.
 * @param {*} value - The value to be validated.
 * @returns {string | null} The error message if validation fails, or null if validation passes.
 * @throws {Error} If no validator is found for the given ID.
 */
export const getError = (id: string, value: any): string | null => {
  if (validators[id]) return validators[id](value);

  throw new Error(`No validator for ${id}`);
};

/**
 * Calculates the count of errors based on the provided errors object.
 *
 * @param {object} errors - The errors object containing error details.
 * @returns {number} - The total count of errors.
 */
export const errorsCount = (errors: object): number => Object.keys(errors).reduce((count, key) => count + Number(!!errors[key]), 0);
