import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { PRODUCT_CUSTOM_NAMES_URL } from 'app/config/url';

export const defaultCustomNames = {
  mapping: {},
};

interface IProductCustomNames {
  id?: number;
  mapping?: any;
}

const initialState: EntityState<IProductCustomNames> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultCustomNames,
  updating: false,
  updateSuccess: false,
};

const apiUrl = PRODUCT_CUSTOM_NAMES_URL;

// Actions

export const getCustomNames = createAsyncThunk('customNames/get', async () => {
  const response = await axios.get<IProductCustomNames>(apiUrl);
  return response.data;
});

export const saveCustomNames = createAsyncThunk(
  'customNames/save',
  async (settings: IProductCustomNames, thunkAPI) => {
    const response = await axios.post<IProductCustomNames>(apiUrl, settings);

    return response.data;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const CustomNamesSlice = createEntitySlice({
  name: 'customNames',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getCustomNames.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = { ...action.payload };
      })
      .addCase(saveCustomNames.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = { ...action.payload };
      })
      .addMatcher(isPending(getCustomNames), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(saveCustomNames), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getCustomNames, saveCustomNames), state => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = true;
      });
  },
});

export const { reset } = CustomNamesSlice.actions;

// Reducer
export default CustomNamesSlice.reducer;
