import React from 'react';
import Drawer from './Drawer';
import { useDrawer } from './Drawer';
import { useWindowWidth } from 'app/shared/hooks/useWindowWidth';
import { screenWidth } from '../../../model/enumerations/screen-modes';

const ResponsiveDrawer = () => {
  const { isOpen, toggleDrawer, closeDrawer, setDrawerOpen, openDrawer } = useDrawer();
  const width = useWindowWidth();

  if (width > screenWidth.MAX_TABLET) {
    return null;
  }

  return (
    <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} closeDrawer={closeDrawer} openDrawer={openDrawer} setDrawerOpen={setDrawerOpen} />
  );
};

export default ResponsiveDrawer;
