import { IRecipe } from 'app/shared/model/recipe.model';
import { IProduct } from 'app/shared/model/product.model';
import { IMarkupGroup } from 'app/shared/model/markup-group.model';

export interface IRecipeItem {
  id?: number;
  markup?: number | null;
  quantity?: number | null;
  recipe?: IRecipe | null;
  product?: IProduct | null;
  markupGroup?: IMarkupGroup | null;
}

export const defaultValue: Readonly<IRecipeItem> = {};
