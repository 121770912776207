import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import { Input } from 'reactstrap';
import React, { useRef, useState } from 'react';
import './search-input-styles.scss';

const DEFAULT_SUBMIT_TIMEOUT_IN_MS = 250;

interface SearchInputProps {
  submitTimeoutInMs?: number;
  placeholder?: string;
  onSearch: (query: string) => void;
}

const SearchInput = ({ submitTimeoutInMs = DEFAULT_SUBMIT_TIMEOUT_IN_MS, placeholder, onSearch }: SearchInputProps) => {
  const [search, setSearch] = useState<string>('');
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const searchInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      onSearch(value);
    }, submitTimeoutInMs);
  };

  return (
    <div className="search-box">
      <Icon path={mdiMagnify} size={1} />
      <Input type="search" value={search} onChange={searchInputHandler} placeholder={placeholder} />
    </div>
  );
};

export default SearchInput;
