import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './event.reducer';
import '../../shared/layout/Layout.scss';
import {
  calculateRecipeTotals,
  calculateTotals,
  countCategoryProducts,
  len,
  mergeRecipes,
  segregateProductCategories,
} from 'app/util/structure-utils';
import NotFound from 'app/shared/alert/not-found';
import { IEvent } from 'app/shared/model/event.model';
import { extractTimeFromTimestamp, time24To12, toUsaDate } from 'app/util/date-time-utils';
import StatusIndicator from 'app/entities/event/StatusIndicator';
import { getStatus, isOrdered } from 'app/util/event-utils';
import { IRecipe } from 'app/shared/model/recipe.model';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPencil } from '@fortawesome/free-solid-svg-icons';
import { EDIT_PATH, EVENTS_PATH, GUEST_PROPOSAL_VIEW_PATH } from 'app/paths';
import { statuses } from 'app/entities/event/event';
import ProductTable from 'app/entities/event/product-table/ProductTable';
import Note from './event-form-components/items-page/recipe/note/Note';
import Loading from 'app/shared/alert/loading';
import { IMarkupGroup } from 'app/shared/model/markup-group.model';
import { renderer } from 'app/util/table-column-renderers';
import { asPrice, formatPhoneNumber } from 'app/util/format-utils';
import { mdi, stateCodes } from 'app/config/constants';
import PhotoAlbum from 'react-photo-album';
import Icon from '@mdi/react';
import { mdiAccount, mdiCalendar, mdiImage, mdiInvoiceList } from '@mdi/js';
import { IPicture } from 'app/entities/gallery/gallery.reducer';
import NoItems from 'app/shared/alert/NoItems';
import { MergedEventStatus } from 'app/shared/model/enumerations/merged-event-statuses';
import ActionButton from 'app/shared/action-buttons/ActionButton';
import { useWindowWidth } from 'app/shared/hooks/useWindowWidth';
import { screenWidth } from 'app/shared/model/enumerations/screen-modes';

export const EventDetail = () => {
  const [isError, setError] = useState(false);
  const [proposalNumber, setProposalNumber] = useState(null);
  const [isExpanded, setExpanded] = useState<boolean[]>([]);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const event: IEvent = useAppSelector(state => state.event.entity);
  const taxes = useAppSelector(state => state.tax.entities);
  const pictures = useAppSelector(state => state.gallery.entities);
  const loading = useAppSelector(state => state.event.loading);
  const navigate = useNavigate();
  const width = useWindowWidth();

  useEffect(() => {
    const fullPath = location.pathname;
    const number = fullPath.split('event/')[1];
    setProposalNumber(number);
    dispatch(getEntity(number));
  }, [location]);

  useEffect(() => {
    if (len(event) > 0) {
      const recipes = event.markupGroup ? event.markupGroup.recipes : [];
      setExpanded(new Array(len(recipes)).fill(true));
    }

    setError(!loading && len(event) === 0);
  }, [loading, event]);

  const renderDataField = (label: string, content: any, bold: boolean = false) => (
    <div className={`data-field ${bold ? `bold ${statuses[getStatus(event)].className}` : ''}`}>
      <div className={`field-label ${bold ? 'bold' : ''}`} style={{ color: content ? 'gray' : 'lightgray' }}>
        {label}:{' '}
      </div>
      <div>{content}</div>
    </div>
  );

  const getLastProposal = () => event.proposals && event.proposals[event.proposals.length - 1];

  const renderSection = (title: string, content: any, icon?: string, flex?: number) => {
    const className = statuses[getStatus(event)].className;

    return (
      <div className={`details-section ${className}`} style={{ flex: flex || 1 }}>
        <div className={`details-section-title ${className}`}>
          {icon && <Icon path={icon} size={0.7} style={{ marginRight: '8px' }} />}
          {title}
        </div>
        <div className="details-section-content">{content}</div>
      </div>
    );
  };

  const getRecipeColumns = () => [
    { render: renderer.index },
    { render: renderer.image },
    { render: renderer.productName },
    { render: renderer.price },
    { render: renderer.um },
    { render: renderer.resultQty },
  ];

  const getAdjustedRecipeCopy = (recipe: IRecipe): IRecipe => {
    const qty = recipe.quantity;

    return {
      ...recipe,
      products: recipe.products ? recipe.products.map(p => ({ ...p, quantity: p.quantity / qty })) : [],
    };
  };

  const renderRecipeTotalField = (value: number, recipeQty: number = 1) => {
    let result: any = null;

    if (value !== undefined && value !== null) {
      const eachValue = recipeQty > 1 && <span style={{ fontWeight: 300, color: 'gray' }}>{`(${asPrice(value)} each)`}</span>;
      result = (
        <span>
          {asPrice(value * recipeQty)} {eachValue}
        </span>
      );
    }

    return result;
  };

  const renderExpandedRecipe = (sourceRecipe: IRecipe, i: number, markupGroup: IMarkupGroup) => {
    const qty = sourceRecipe.quantity;
    const recipe = getAdjustedRecipeCopy(sourceRecipe);
    const categories = segregateProductCategories(recipe);
    const totalProducts = countCategoryProducts(categories);
    const totals = calculateRecipeTotals({ ...recipe, quantity: 1 }, markupGroup.flowers, markupGroup.supplies, markupGroup.plants);
    const className = `details-recipe-container expanded ${statuses[getStatus(event)].className}`;
    const cost = renderRecipeTotalField(totals.cost, qty);
    const profit = renderRecipeTotalField(totals.profit, qty);
    const price = renderRecipeTotalField(totals.price, qty);
    const categoryLengths = categories.map(c => len(c.products));

    const getStartIndex = (idx: number): number => categoryLengths.slice(0, idx).reduce((acc, length) => acc + length, 1);

    return (
      <div key={i} className={className}>
        <div className="recipe-info">
          {renderDataField('Recipe Name', recipe.name)}
          {renderDataField('Qty', qty)}
          {renderDataField('Note', recipe.note ? <Note text={recipe.note} readOnly noMargin /> : null)}
          {renderDataField('Cost', cost)}
          {renderDataField('Profit', profit)}
          {renderDataField('Price', price)}
        </div>

        <div className="recipe-products">
          {totalProducts > 0 ? (
            categories.map(
              (category, idx) =>
                category.products &&
                len(category.products) > 0 && (
                  <ProductTable
                    key={idx}
                    title={category.title}
                    products={category.products}
                    startFromIndex={getStartIndex(idx)}
                    recipe={sourceRecipe}
                    columns={getRecipeColumns()}
                    onRecipeChange={() => {}}
                  />
                ),
            )
          ) : (
            <NoItems text="No Products Added" />
          )}
        </div>
      </div>
    );
  };

  const renderCollapsedRecipe = (sourceRecipe: IRecipe, i: number) => {
    const recipe = getAdjustedRecipeCopy(sourceRecipe);
    const qty = sourceRecipe.quantity;
    const className = `details-recipe-container collapsed ${statuses[getStatus(event)].className}`;

    return (
      <div key={i} className={className}>
        <Row style={{ width: '100%' }}>
          <Col md="4">{renderDataField('Recipe Name', recipe.name)}</Col>
          <Col md="2">{renderDataField('Qty', qty)}</Col>
          <Col>{renderDataField('Note', recipe.note ? <Note text={recipe.note} readOnly noMargin /> : null)}</Col>
        </Row>
      </div>
    );
  };

  const renderRecipe = (sourceRecipe: IRecipe, i: number, markupGroup: IMarkupGroup) => {
    const expanded = isExpanded[i];

    const spoilerButton = (
      <div style={{ position: 'absolute', top: '10px', right: 0 }}>
        <ActionButton
          icon={expanded ? mdi.collapse : mdi.expand}
          name={expanded ? 'Collapse' : 'Expand'}
          large
          clickHandler={() => setExpanded(prev => [...prev.slice(0, i), !expanded, ...prev.slice(i + 1)])}
        />
      </div>
    );

    return (
      <div key={i} style={{ position: 'relative' }}>
        {expanded ? renderExpandedRecipe(sourceRecipe, i, markupGroup) : renderCollapsedRecipe(sourceRecipe, i)}
        {spoilerButton}
      </div>
    );
  };

  const renderRecipes = (markupGroup: IMarkupGroup) => {
    const recipes = markupGroup ? markupGroup.recipes : [];
    const allExpanded = !isExpanded.some(state => !state);

    return len(recipes) > 0 ? (
      <>
        <div className="recipes-label">
          Recipes
          {len(recipes) > 1 && (
            <div style={{ position: 'absolute', bottom: '10px', right: 0 }}>
              <ActionButton
                icon={allExpanded ? mdi.collapseAll : mdi.expandAll}
                name={allExpanded ? 'Collapse All' : 'Expand All'}
                large
                clickHandler={() => setExpanded(prev => new Array(len(prev)).fill(!allExpanded))}
              />
            </div>
          )}
        </div>
        <div>{recipes.map((r, i) => renderRecipe(r, i, markupGroup))}</div>
      </>
    ) : (
      <NoItems text="No Recipes Added" />
    );
  };

  const renderProductsGeneralDetails = (markupGroup: IMarkupGroup) => {
    const deliveryDate = event.deliveryDate && toUsaDate(event.deliveryDate);
    const flowersMarkup = markupGroup && markupGroup.flowers && `${markupGroup.flowers}%`;
    const suppliesMarkup = markupGroup && markupGroup.supplies && `${markupGroup.supplies}%`;
    const plantsMarkup = markupGroup && markupGroup.plants && `${markupGroup.plants}%`;
    const recipes = markupGroup.recipes ? markupGroup.recipes.map(getAdjustedRecipeCopy) : [];
    const products = mergeRecipes(recipes);
    const state = (event.contact && event.contact.state) || null;
    const taxPercent = taxes[state];
    const taxRate = (state && taxPercent / 100) || 0;
    const totals = calculateTotals(
      products,
      taxRate,
      markupGroup.flowers,
      markupGroup.supplies,
      markupGroup.plants,
      markupGroup.laborFee,
      markupGroup.setupFee,
      markupGroup.deliveryFee,
    );
    const priceAmount = asPrice(totals.priceAmount);
    const markupAmount = asPrice(totals.markupAmount);
    const laborFeeAmount = asPrice(totals.laborFeeAmount);
    const setupFeeAmount = asPrice(totals.setupFeeAmount);
    const deliveryFeeAmount = asPrice(totals.deliveryFeeAmount);
    const taxAmount = state ? asPrice(totals.taxAmount) : <div className="validation-error">→ State needed</div>;
    const totalAmount = state ? asPrice(totals.totalAmount) : <div className="validation-error">→ State needed</div>;

    return (
      <div className={width > screenWidth.MAX_NARROW ? 'row-section' : ''}>
        <div className="row-part">
          {renderDataField('Delivery Date', deliveryDate)}
          {renderDataField('Flowers Markup', flowersMarkup)}
          {renderDataField('Plants Markup', plantsMarkup)}
          {renderDataField('Supplies Markup', suppliesMarkup)}
        </div>

        <div className="row-part">
          {renderDataField('Labor Fee', laborFeeAmount)}
          {renderDataField('Setup Fee', setupFeeAmount)}
          {renderDataField('Delivery Fee', deliveryFeeAmount)}
        </div>

        <div className="row-part">
          {renderDataField('Cost', priceAmount)}
          {renderDataField('Profit', markupAmount)}
          {renderDataField(state ? `${stateCodes[state]} Tax (${taxPercent}%)` : 'Tax', taxAmount)}
          {renderDataField('Total', totalAmount, !!state)}
        </div>
      </div>
    );
  };

  const renderProductInfo = () => {
    const markupGroup = event.markupGroup;
    const content = (
      <>
        {renderProductsGeneralDetails(markupGroup)}
        {renderRecipes(markupGroup)}
      </>
    );

    return renderSection('Products', content, mdiInvoiceList);
  };

  const renderContactInfo = () => {
    const contact = event.contact;
    const firstName = contact && contact.firstName;
    const lastName = contact && contact.lastName;
    const email = contact && contact.email;
    const phoneNumber = contact && contact.phoneNumber;
    const phone = phoneNumber && (
      <a className="phone-link" href={`tel:${phoneNumber}`}>
        {formatPhoneNumber(contact && contact.phoneNumber)}
      </a>
    );
    const streetAddress = contact && contact.streetAddress;
    const streetAddressLine2 = contact && contact.streetAddressLine2;
    const city = contact && contact.city;
    const state = contact && contact.state;
    const zipCode = contact && contact.zipCode;
    const note = contact && contact.note;

    const content = (
      <div className={width > screenWidth.MAX_NARROW ? 'row-section' : ''}>
        <div className="row-part">
          {renderDataField('First Name', firstName)}
          {renderDataField('Last Name', lastName)}
          {renderDataField('Email', email)}
          {renderDataField('Phone', phone)}
          {renderDataField('Note', note)}
        </div>

        <div className="row-part">
          {renderDataField('Street Address', streetAddress)}
          {renderDataField('Street Address Line 2', streetAddressLine2)}
          {renderDataField('City', city)}
          {renderDataField('State', state)}
          {renderDataField('Zip Code', zipCode)}
        </div>
      </div>
    );

    return renderSection('Contact Info', content, mdiAccount, 5);
  };

  const renderEventInfo = () => {
    const name = event.name;
    const eventDate = event.eventDate && toUsaDate(event.eventDate);
    const eventTime = event.eventDate && time24To12(extractTimeFromTimestamp(Number(event.eventDate)));
    const lastProposal = getLastProposal();
    const expirationDate = lastProposal && lastProposal.expirationDate && toUsaDate(lastProposal.expirationDate);
    const status = event.proposals && <StatusIndicator status={getStatus(event)} />;
    const proposal = getStatus(event) !== MergedEventStatus.DRAFT && (
      <a className="proposal-link" href={`${GUEST_PROPOSAL_VIEW_PATH}/${lastProposal.publicId}`} target="_blank" rel="noopener noreferrer">
        View Proposal
      </a>
    );
    const content = (
      <>
        {renderDataField('Event Name', name)}
        {renderDataField('Event Date', eventDate)}
        {renderDataField('Event Time', eventTime)}
        {renderDataField('Proposal Expires', expirationDate)}
        {renderDataField('Status', status)}
        {proposal}
      </>
    );

    return renderSection('Event Info', content, mdiCalendar, 3);
  };

  const renderPageHeader = () => (
    <Row>
      <Col>
        <h4 id="event-heading" data-cy="EventHeading">
          Proposal #{proposalNumber}
        </h4>
      </Col>

      <Col md="4" className="d-flex justify-content-end gap-3">
        <div className="d-flex justify-content-end">
          <Link to={EVENTS_PATH}>
            <Button className="btn" color="primary" outline>
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp; Back to List
            </Button>
          </Link>
        </div>

        <div className="d-flex justify-content-end">
          <Link
            to={`${EVENTS_PATH}/${proposalNumber}/${EDIT_PATH}`}
            style={isOrdered(event) ? { cursor: 'default' } : {}}
            onClick={e => {
              if (isOrdered(event)) {
                e.preventDefault();
              }
            }}
          >
            <Button
              className="btn"
              color="primary"
              outline
              disabled={isOrdered(event)}
              onClick={() => navigate(`${EVENTS_PATH}/${proposalNumber}/${EDIT_PATH}`)}
            >
              <FontAwesomeIcon icon={faPencil} />
              &nbsp; Edit
            </Button>
          </Link>
        </div>
      </Col>
    </Row>
  );

  const renderPictures = () => {
    const lastProposal = getLastProposal();
    const pictureNames: string[] = (lastProposal.imageUrls && lastProposal.imageUrls.split(' ')) || [];
    const attachedPictures = pictures.filter((p: IPicture) => pictureNames.some(name => p.src.includes(name)));
    const content =
      len(attachedPictures) > 0 ? (
        <div className="image-container">
          <PhotoAlbum layout="rows" photos={attachedPictures} spacing={10} />
        </div>
      ) : (
        <NoItems text="No Pictures Attached" />
      );

    return renderSection('Attached Pictures', content, mdiImage);
  };

  const renderDetails = () => (
    <div className="stretch">
      {renderPageHeader()}

      <div className="event-details">
        {width > screenWidth.MAX_TABLET ? (
          <div className="row-section">
            {renderEventInfo()}
            {renderContactInfo()}
          </div>
        ) : (
          <>
            <div className="row-section top-gap">{renderEventInfo()}</div>
            <div className="row-section top-gap">{renderContactInfo()}</div>
          </>
        )}

        <div className="row-section top-gap">{renderProductInfo()}</div>
        <div className="row-section top-gap">{renderPictures()}</div>
      </div>
    </div>
  );

  return loading ? <Loading /> : isError ? <NotFound /> : len(event) !== 0 && event.proposals && renderDetails();
};
