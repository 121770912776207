import { IFlorist } from 'app/shared/model/florist.model';

export interface IContact {
  id?: number;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  streetAddress?: string | null;
  streetAddressLine2?: string | null;
  city?: string | null;
  zipCode?: string | null;
  state?: string | null;
  phoneNumber?: string | null;
  note?: string | null;
  florist?: IFlorist | null;
}

export const defaultValue: Readonly<IContact> = {};
