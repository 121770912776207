import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import '../../../shared/layout/Layout.scss';
import '../../../styles/gallery-styles.scss';
import { Link } from 'react-router-dom';
import { ROOT_PATH } from 'app/paths';

const Privacy = () => {
  return (
    <div className="flori-form">
      <Col>
        <h4 id="contact-heading" data-cy="ContactHeading">
          Privacy Policy
        </h4>
      </Col>

      <p>
        Fall River Florist Supply is committed to protecting your privacy. This policy outlines how we collect, use, and protect the
        information you provide when using our service.
      </p>

      <p>
        <b>Information We Collect</b>
        <br />
        We may collect personal information when you use our service. This information may include your name, contact details (such as email
        address and phone number), and usage data related to your interactions with our platform.
      </p>

      <p>
        <b>How We Use Your Information</b>
        <br />
        Service Delivery: We use your information to provide and improve our service, including processing your requests and inquiries.
        <br />
        Communication: We may use your contact information to send you service-related notifications, updates, or promotional materials. You
        can opt out of marketing communications at any time.
        <br />
        Analytics: We may use aggregated and anonymized data to analyze user behavior and improve our service.
      </p>

      <p>
        <b>Data Security</b>
        <br />
        We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, or alteration.
        However, no method of transmission or storage is completely secure, and we cannot guarantee absolute security.
      </p>

      <p>
        <b>Cookies and Tracking Technologies</b>
        <br />
        We may use cookies and similar tracking technologies to collect information about your use of our service. This information helps us
        improve your experience and understand user behavior. You can adjust your browser settings to manage cookie preferences.
      </p>

      <p>
        <b>Your Rights</b>
        <br />
        You may have certain rights regarding your personal information, such as the right to access, correct, or delete your data. To
        exercise these rights or for any privacy-related inquiries, please contact us at privacy@floriplanner.com.
      </p>

      <p>
        <b>Changes to This Policy</b>
        <br />
        We may update this privacy policy from time to time. We will notify you of any material changes by posting the updated policy on our
        website.
      </p>

      <p>By using our service, you consent to this privacy policy.</p>

      <Row>
        <Col className="text-start action-footer">
          <Button tag={Link} to={ROOT_PATH} replace color="primary" style={{ marginLeft: '20px' }}>
            Home
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Privacy;
