import React from 'react';
import './events-styles.scss';
import Icon from '@mdi/react';
import { statuses } from 'app/entities/event/event';

const StatusIndicator = ({ status }) => {
  const icon = statuses[status].icon;
  const className = statuses[status].className;

  return (
    <div className={`event-status ${className}`}>
      <Icon size={0.7} path={icon} style={{ marginRight: '5px', minWidth: '17px' }} />
      <div className="status-label">{status}</div>
    </div>
  );
};

export default StatusIndicator;
