import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from 'reactstrap';
import './datepicker-styles.scss';
import { dateFromISO, dateToISO, ISOtoUSA } from 'app/util/date-time-utils';
import Icon from '@mdi/react';
import { mdiCalendarMonth } from '@mdi/js';
import { len } from 'app/util/structure-utils';

interface IDatepickerProps {
  id: string;
  value: string;
  disabled?: boolean;
  invalid?: boolean;
  placeholderText?: string;
  availableDates?: string[];
  availableFrom?: string;
  unavailableFrom?: string;
  onChange: (value: any) => void;
  onBlur?: (value: any) => void;
}

const Datepicker = ({
  value,
  onChange,
  disabled = false,
  onBlur = null,
  invalid = null,
  id,
  placeholderText = '',
  availableDates = null,
  availableFrom = null,
  unavailableFrom = null,
}: IDatepickerProps) => {
  const [availableDatesAsDate, setAvailableDatesAsDate] = useState(null);

  useEffect(() => {
    setAvailableDatesAsDate(filterDates(availableDates));
  }, [availableDates, availableFrom, unavailableFrom]);

  const [initialDate, setInitialDate] = useState(null);

  useEffect(() => {
    if (availableDatesAsDate && len(availableDatesAsDate) > 0) {
      setInitialDate(availableDatesAsDate[0]);
    }
  }, [availableDatesAsDate]);

  const filterDates = (dates: any) => {
    if (!dates) return null;

    let filteredDates = [...dates];
    if (availableFrom) {
      filteredDates = filteredDates.filter(date => date >= availableFrom);
    }
    if (unavailableFrom) {
      filteredDates = filteredDates.filter(date => date < unavailableFrom);
    }
    return filteredDates.map(date => new Date(date));
  };

  const handleDateChange = (date: Date) => {
    onChange({ target: { id, value: date ? dateToISO(date) : '' } });
  };

  const renderInput = () => (
    <div className="datepicker-input">
      <Input
        id={id}
        type="text"
        value={(value && ISOtoUSA(value)) || ''}
        onChange={() => {}}
        onClick={() => {
          if (!value) handleDateChange(dateFromISO(availableFrom));
        }}
        onBlur={onBlur}
        invalid={invalid}
        placeholder={placeholderText}
        disabled={disabled}
        readOnly
      />

      <Icon path={mdiCalendarMonth} className={`datepicker-input-icon ${invalid ? 'invalid-value' : ''}`} size={0.75} />
    </div>
  );

  return (
    <DatePicker
      selected={value ? dateFromISO(value) : initialDate}
      onChange={handleDateChange}
      includeDates={availableDatesAsDate}
      minDate={availableFrom ? dateFromISO(availableFrom) : null}
      maxDate={unavailableFrom ? dateFromISO(unavailableFrom) : null}
      openToDate={initialDate}
      onSelect={handleDateChange}
      customInput={renderInput()}
      disabled={disabled}
    />
  );
};

export default Datepicker;
