import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import '../../../shared/layout/Layout.scss';
import '../../../styles/gallery-styles.scss';
import { Link } from 'react-router-dom';
import { ROOT_PATH } from 'app/paths';
import axios from 'axios';
import { SUBSCRIPTION_CONFIG_URL } from 'app/config/url';
import { asPrice } from 'app/util/format-utils';

const Terms = () => {
  const [amount, setAmount] = useState(null);

  useEffect(() => {
    axios
      .get(`${SUBSCRIPTION_CONFIG_URL}/current`)
      .then(res => {
        setAmount(res.data.amount);
      })
      .catch(error => {
        console.error('Failed to fetch configuration:', error);
      });
  }, []);

  return (
    <div className="flori-form">
      <Col>
        <h4 id="contact-heading" data-cy="ContactHeading">
          Billing Disclaimer and Terms of Service
        </h4>
      </Col>

      <p>
        FloriPlanner is an additional service offered by Fall River Florist Supply Corp (FRFS) to help our customers plan their customer's
        weddings and events, available as an added feature through our website. Customers who choose to use the FloriPlanner service will
        enjoy an initial monthly trial period free of charge. After the trial period expires, customers will be billed a fixed amount of
        {amount ? ` ${asPrice(amount)}` : ' ...'} per month, with the service fee added to the customer's statement at the beginning of each
        month.
      </p>

      <p>
        A Florist is responsible for placing orders upon receiving confirmation from their customer and must follow proper procedure with
        FRFS in cases of potential wedding/event cancellation.
      </p>

      <p>
        To cancel the FloriPlanner service, customers must contact their Fall River Florist Supply representative by email or phone before
        the start of the next billing cycle to avoid being charged for the upcoming month. Once the cancellation request is received and
        processed, the service will be terminated, and no further charges will be applied. For any questions or to request a cancellation,
        please contact your Fall River Florist Supply representative.
      </p>

      <Row>
        <Col className="text-start action-footer">
          <Button tag={Link} to={ROOT_PATH} replace color="primary" style={{ marginLeft: '20px' }}>
            Home
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Terms;
