import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getError, validators } from 'app/util/validation-utils';
import { ITEMS_PAGE } from '../constants';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import '../event-form-styles.scss';
import ValidationError from 'app/shared/error/ValidationError';
import { DAYS_TO_EARLIEST_EVENT, mdi, states } from 'app/config/constants';
import { formatters } from 'app/util/format-utils';
import Datepicker from 'app/shared/datepicker/Datepicker';
import { dateToISO, getLastExpirationDateISO } from 'app/util/date-time-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Timepicker from 'app/shared/timepicker/Timepicker';
import AddressAutocomplete, { IPlace } from './address-autocomplete/AddressAutocomplete';
import { getShipdays } from 'app/entities/shipdays/shipdays.reducer';
import PhoneInput from 'react-phone-number-input/input';
import CustomInput from './phone-input/CustomInput';
import { len } from 'app/util/structure-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faClose, faSave } from '@fortawesome/free-solid-svg-icons';
import Icon from '@mdi/react';
import { Color } from 'app/shared/model/enumerations/color.model';
import { useWindowWidth } from 'app/shared/hooks/useWindowWidth';
import { screenWidth } from 'app/shared/model/enumerations/screen-modes';

const NEXT_PAGE: string = ITEMS_PAGE;

const selectOptions = {
  state: states,
};

type FormModel = {
  eventName?: string;
  eventDate?: Date;
  eventTime?: Date;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  streetAddress?: string;
  streetAddressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  note?: string;
};

const eventPageValidators = {
  eventName: validators.eventName,
  eventDate: validators.eventDate,
  eventTime: validators.eventTime,
  email: validators.email,
  phoneNumber: validators.phoneNumber,
  firstName: validators.firstName,
  lastName: validators.lastName,
  streetAddress: validators.streetAddress,
  streetAddressLine2: validators.streetAddressLine2,
  city: validators.city,
  state: validators.state,
  zipCode: validators.zipCode,
  note: validators.note,
};

const validateAll = (data: FormModel) => {
  const errors: { [key: string]: string | null } = {};

  (Object.keys(eventPageValidators) as (keyof FormModel)[]).forEach(key => {
    errors[key] = getError(key, data[key]);
  });

  return errors;
};

const getFirstAvailableDateISO = () => {
  const today = new Date();
  const future = new Date(today);
  future.setDate(today.getDate() + DAYS_TO_EARLIEST_EVENT);

  return dateToISO(future);
};

// To avoid request doubling
let fetchingDates = false;

// To Ensure the Timepicker is only toggled once on the date selection
let isDateSet = false;
let isTimeSet = false;

const EventPage = ({ initialData, onDataUpdate, onGoToPage, onCancel, onSave }) => {
  const [values, setValues] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [firstAvailableDate, setFirstAvailableDate] = useState('');
  const isSaving = useAppSelector(state => state.event.updating);
  const shipdays = useAppSelector(state => state.shipdays.entities);
  const dispatch = useAppDispatch();
  const width = useWindowWidth();
  const timepickerRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setFirstAvailableDate(getFirstAvailableDateISO());
  }, []);

  useEffect(() => {
    if (len(shipdays) > 0) setValues((prev: any) => ({ ...prev, deliveryDate: shipdays[0] }));
  }, [shipdays]);

  useEffect(() => {
    setValues(initialData);
    isDateSet = !!initialData['eventDate'];
    isTimeSet = !!initialData['eventTime'];
  }, [initialData]);

  const formattedValue = (id: string) => (formatters[id] ? formatters[id](values[id]) : values[id]);

  const nextClickHandler = () => {
    touchAll();
    const validationErrors = validateAll(values);
    setErrors(validationErrors);
    onGoToPage(NEXT_PAGE);
  };

  const saveClickHandler = () => {
    onSave();
  };

  const touchAll = () => {
    const touchedFields = (Object.keys(eventPageValidators) as (keyof FormModel)[]).reduce(
      (obj, field) => ({
        ...obj,
        [field]: field,
      }),
      {},
    );
    setTouched(touchedFields);
  };

  const changeHandler = useCallback(
    (e: { target: { id: string; value: any } }) => {
      const { id, value } = e.target;

      setValues((prevValues: any) => {
        if (prevValues[id] === value) {
          return prevValues;
        }

        const updatedValues = { ...prevValues, [id]: value };

        if (id === 'eventDate') {
          updatedValues.expirationDate = getLastExpirationDateISO(value);

          if (!fetchingDates) {
            dispatch(getShipdays(value));
            fetchingDates = true;
            setTimeout(() => (fetchingDates = false), 100);
          }
        }

        onDataUpdate(updatedValues);

        return updatedValues;
      });

      if (id === 'eventDate') {
        if (isDateSet) {
          toggleTimePicker();
        } else {
          setTimeout(() => {
            isDateSet = true;
          }, 10);
        }
      }

      if (touched[id]) {
        const error = getError(id, value);
        setErrors(prevErrors => ({ ...prevErrors, [id]: error }));
      }
    },
    [onDataUpdate, dispatch],
  );

  const toggleTimePicker = () => {
    if (!isTimeSet && timepickerRef.current) {
      setTimeout(() => {
        timepickerRef.current.openPicker();
      }, 500);
      isTimeSet = true;
    }
  };

  const autocompleteHandler = (place: IPlace) => {
    const { route: streetAddress, city, state, zipCode } = place;
    let updatedValues = { ...values, streetAddress, city, state, zipCode };
    setValues(updatedValues);
    onDataUpdate(updatedValues);
  };

  const touchHandler = (e: any) => {
    const id = e.target.id;
    const updatedValues = { ...touched, [id]: id };
    setTouched(updatedValues);
    validate(id, e.target.value);

    if (id === 'eventDate') {
      toggleTimePicker();
    }
  };

  const validate = (id: string, value: any) => {
    const error = getError(id, value);
    setErrors(prevErrors => ({ ...prevErrors, [id]: error }));
  };

  const renderSelect = (id: string, label?: string) => {
    const selectedValue = (values[id] && selectOptions[id].includes(formattedValue(id)) && formattedValue(id)) || '';

    return (
      <Input
        id={id}
        type="select"
        value={selectedValue}
        onChange={changeHandler}
        onBlur={touchHandler}
        invalid={!!(errors[id] && touched[id])}
      >
        {!selectedValue && (
          <option value="" disabled style={{ color: 'lightgray', fontSize: '12px' }}>
            Select {label || id}
          </option>
        )}

        {selectOptions[id].map((state: string, i: number) => (
          <option key={i} value={state}>
            {state}
          </option>
        ))}
      </Input>
    );
  };

  const renderField = (
    id: string,
    label?: any,
    type: InputType | 'address' | 'phone' = 'text',
    disabled: boolean = false,
    disabledText?: string,
    placeholder?: string,
  ) => {
    let input: React.JSX.Element;
    const value = formattedValue(id) || '';

    switch (type) {
      case 'select':
        input = renderSelect(id, label);
        break;
      case 'date':
        input = (
          <Datepicker
            id={id}
            value={value}
            onChange={changeHandler}
            onBlur={touchHandler}
            invalid={!!(errors[id] && touched[id])}
            availableFrom={firstAvailableDate}
            disabled={disabled}
          />
        );
        break;
      case 'time':
        input = (
          <Timepicker
            id={id}
            ref={timepickerRef}
            value={value}
            invalid={!!(errors[id] && touched[id])}
            disabled={disabled}
            minutesOptions={[0, 30]}
            defaultTime={{ hours: 12, minutes: 0, am: false }}
            onChange={changeHandler}
            onBlur={touchHandler}
          />
        );
        break;
      case 'phone':
        input = (
          <PhoneInput
            id={id}
            className={'form-control'}
            country="US"
            value={value}
            placeholder={placeholder}
            invalid={!!(errors[id] && touched[id])}
            onChange={value => changeHandler({ target: { id, value } })}
            onBlur={touchHandler}
            inputComponent={CustomInput}
          />
        );
        break;
      case 'address':
        input = (
          <AddressAutocomplete
            id={id}
            value={value}
            invalid={!!(errors[id] && touched[id])}
            onChange={changeHandler}
            onBlur={touchHandler}
            onAutocomplete={autocompleteHandler}
          />
        );
        break;
      default:
        input = (
          <Input
            id={id}
            type={type}
            value={formattedValue(id)}
            onChange={changeHandler}
            placeholder={placeholder}
            onBlur={touchHandler}
            invalid={!!(errors[id] && touched[id])}
            disabled={disabled}
            min={type === 'number' ? 0 : null}
          />
        );
    }

    return (
      <FormGroup>
        {input}

        <span>
          {label && <Label for={id}>{label}</Label>}
          <ValidationError id={id} label={label} errors={errors} touched={touched} />
          {disabled && <div className="validation-error">{disabledText}</div>}
        </span>
      </FormGroup>
    );
  };

  const renderTitle = (title: string, subtitle: boolean = false) => (
    <div className={`form-section-title ${subtitle ? 'sub' : ''}`}>{title}</div>
  );

  const renderClaim = (icon: string, label: string, color: Color = Color.PRIMARY) => (
    <div className={`claim ${color}`}>
      {icon && <Icon path={icon} size={0.6} />}
      {label}
    </div>
  );

  const renderLabelWithClaim = (label: string, icon: string, claim: string, color: Color) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {label}
      {renderClaim(icon, claim, color)}
    </div>
  );

  const renderActionFooter = () => (
    <Row className="action-footer">
      <Col md="auto" sm="auto" xs="auto">
        <Button color="secondary" outline onClick={onCancel} disabled={isSaving}>
          <FontAwesomeIcon icon={faClose} />
          &nbsp; Cancel
        </Button>
      </Col>

      <Col className="text-end">
        <Button color="secondary" onClick={saveClickHandler} style={{ marginRight: '20px' }} disabled={isSaving}>
          {isSaving ? (
            'Saving...'
          ) : (
            <>
              <FontAwesomeIcon icon={faSave} />
              &nbsp; {width > screenWidth.MAX_NARROW ? 'Save Draft' : 'Save'}
            </>
          )}
        </Button>

        <Button color="primary" onClick={nextClickHandler} disabled={isSaving}>
          Next &nbsp;
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </Col>
    </Row>
  );

  return (
    <div className="event-form" style={{ paddingTop: 0 }}>
      {renderTitle('Event Details')}
      <Row>
        <Col>{renderField('eventName', 'Event Name')}</Col>
        <Col md={3}>{renderField('eventDate', 'Event Date', 'date')}</Col>
        <Col md={3}>{renderField('eventTime', 'Event Time', 'time', !values.eventDate, '→ Event Date needed')}</Col>
      </Row>

      {renderTitle('Contact Details')}
      {renderTitle('Name', true)}
      <Row>
        <Col>{renderField('firstName', 'First Name')}</Col>
        <Col>{renderField('lastName', 'Last Name')}</Col>
      </Row>

      {renderTitle('Email & Phone', true)}
      <Row>
        <Col>{renderField('email', 'Email')}</Col>
        <Col>{renderField('phoneNumber', 'Phone Number', 'phone', false, '', '(xxx) xxx-xxxx')}</Col>
      </Row>

      {renderTitle('Address', true)}
      <Row>
        <Col>{renderField('streetAddress', 'Street Address', 'address')}</Col>
        <Col>{renderField('streetAddressLine2', 'Street Address Line 2')}</Col>
      </Row>

      <Row>
        <Col>{renderField('city', 'City')}</Col>
        <Col>{renderField('state', 'State', 'select')}</Col>
        <Col>{renderField('zipCode', 'Zip Code')}</Col>
      </Row>

      <Row>
        <Col>{renderField('note', renderLabelWithClaim('Note', mdi.hide, "Customers can't see this field", Color.SUCCESS))}</Col>
      </Row>

      {renderActionFooter()}
    </div>
  );
};

export default EventPage;
