import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Privacy from './privacy';

const PrivacyRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Privacy />} />
  </ErrorBoundaryRoutes>
);

export default PrivacyRoutes;
