// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-row {
  align-items: center;
}

.edit-button {
  padding: 0.375rem 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/admin/SubscriptionPage.css"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".table-row {\n  align-items: center;\n}\n\n.edit-button {\n  padding: 0.375rem 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
