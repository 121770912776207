import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { mdi } from 'app/config/constants';
import { SEND_EMAIL_URL } from 'app/config/url';
import { toast } from 'react-toastify';
import ActionButton from 'app/shared/action-buttons/ActionButton';

const subjects = ['General Questions', 'Account & Billing', 'Feedback'];

const Communication = ({ onToggle }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setSending] = useState(false);

  const sendClickHandler = () => {
    setSending(true);
    axios
      .post<any>(SEND_EMAIL_URL, { subject, message })
      .then(() => {
        toast.success('Thank you! Your message has been sent');
        reset();
      })
      .finally(() => {
        setSending(false);
        toast.error('Failed to send the message. Something went wrong');
      });
  };

  const reset = () => {
    setSubject('');
    setMessage('');
    onToggle();
  };

  const renderSubjectSelect = () => (
    <FormGroup>
      <span>
        <Label for="subject">Subject</Label>
      </span>

      <Input id="subject" type="select" value={subject} onChange={e => setSubject(e.target.value)}>
        {!subject && (
          <option value="" disabled style={{ color: 'lightgray', fontSize: '12px' }}>
            Select subject
          </option>
        )}

        {subjects.map((state: string, i: number) => (
          <option key={i} value={state}>
            {state}
          </option>
        ))}
      </Input>
    </FormGroup>
  );

  const renderTextBox = () => (
    <FormGroup>
      <span>
        <Label for="message">Message</Label>
      </span>

      <Input
        id="message"
        type="textarea"
        value={message}
        onChange={e => setMessage(e.target.value)}
        placeholder="Write your message here"
      />
    </FormGroup>
  );

  const renderSendButton = () => (
    <Button color="primary" disabled={!subject || !message || isSending} onClick={sendClickHandler} style={{ width: '100%' }}>
      <FontAwesomeIcon icon={faPaperPlane} />
      &nbsp; {isSending ? 'Sending...' : 'Send Message'}
    </Button>
  );

  const renderPhone = () => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '25px',
        color: 'gray',
        fontSize: '14px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon icon={faPhone} style={{ marginRight: '10px' }} />1 800 825 8252
      </div>
    </div>
  );

  const renderHeader = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div className="form-section-title">Contact Us</div>
      <ActionButton icon={mdi.close} name="Close" clickHandler={reset} />
    </div>
  );

  return (
    <Modal isOpen={true} toggle={onToggle}>
      <ModalBody>
        <div className="event-form" style={{ padding: '0 10px 10px' }}>
          {renderHeader()}
          {renderSubjectSelect()}
          {renderTextBox()}
          {renderSendButton()}
          {renderPhone()}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Communication;
