// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  display: flex;
}
.layout .stretch {
  flex: 1;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/Layout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,OAAA;EACA,WAAA;AACJ","sourcesContent":[".layout {\n  display: flex;\n\n  .stretch {\n    flex: 1;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
