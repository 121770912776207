import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import './subscriptions-modal.scss';
import axios from 'axios';
import { SUBSCRIPTION_CONFIG_URL } from 'app/config/url';
import { SubscriptionConfig } from 'app/shared/model/subscription-config.model';

const SubscriptionPopup = ({ onAccept }) => {
  const [config, setConfig] = useState<SubscriptionConfig | null>(null);

  useEffect(() => {
    axios
      .get(`${SUBSCRIPTION_CONFIG_URL}/current`)
      .then(res => {
        setConfig(res.data);
      })
      .catch(error => {
        console.error('Failed to fetch configuration:', error);
      });
  }, []);

  return (
    <Modal isOpen={true}>
      <ModalHeader>Subscription Plan</ModalHeader>

      <ModalBody style={{ borderTop: '2px solid gray' }}>
        <Row className="text-center subscription-price-container">
          <div className="subscription-name">{config?.name}</div>

          <div className="subscription-price">${config?.amount} / monthly</div>

          <div>
            <Button onClick={onAccept} color="primary">
              Start a 30-Day Trial
            </Button>
          </div>
        </Row>

        <div className="key-features">Key features:</div>

        <ul>
          <li>
            <b>Build Proposals with Detailed Recipes:</b> Include flowers, supplies, and plants in your proposals with clear breakdowns.
          </li>
          <li>
            <b>Transparent Pricing:</b> Know exactly what you'll pay for items in advance.
          </li>
          <li>
            <b>Seamless Contract Integration:</b> Easily include your contract agreement within proposals for client convenience.
          </li>
          <li>
            <b>Effortless Order Conversion:</b> Convert approved proposals into orders with a single click.
          </li>
          <li>
            <b>Tax and Profit Breakdown:</b> See the breakdown of taxes and profits for each event.
          </li>
          <li>
            <b>Streamlined Login:</b> Access FloriPlanner with a single sign-on using your existing myFRFS username and password.
          </li>
        </ul>
      </ModalBody>
      <ModalFooter>
        <Row style={{ width: '100%' }}>
          <label style={{ textAlign: 'start' }}>
            <b>Note:</b> After the trial ends, you will be charged ${config?.amount}/monthly for the service.
          </label>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default SubscriptionPopup;
