import { IProduct } from 'app/shared/model/product.model';
import { PRODUCT_PHOTO_SIZE_FULL, PRODUCT_PHOTO_SIZE_MOBILE } from 'app/config/constants';
import { fitQty } from 'app/util/structure-utils';
import React from 'react';
import { asPrice } from 'app/util/format-utils';
import ProductNameCell from 'app/entities/event/product-table/ProductNameCell';

/**
 * Renderer object for generating product table cells with specific content.
 */
export const renderer = {
  index: (_, i: number) => <td className="product-index">{i}.</td>,
  image: (product: IProduct, _, __, isMobile: boolean) => (
    <td className="table-image">
      <img
        src={product.imageName}
        alt={product.productId}
        width={isMobile ? PRODUCT_PHOTO_SIZE_MOBILE : PRODUCT_PHOTO_SIZE_FULL}
        height={isMobile ? PRODUCT_PHOTO_SIZE_MOBILE : PRODUCT_PHOTO_SIZE_FULL}
      />
    </td>
  ),
  productName: (product: IProduct, _, name: string) => <ProductNameCell product={product} name={name} />,
  um: (product: IProduct) => (
    <td className="um">
      <div className="um-container">
        <div>{product.um}</div>
        {product.minQuantity && product.minQuantity > 1 && (
          <div className="min-qty">
            {product.um.toUpperCase() === 'BU' ? `${product.minQuantity} /bunch` : `min ${product.minQuantity}`}
          </div>
        )}
      </div>
    </td>
  ),
  initialQty: (product: IProduct) => (
    <td className="price" style={{ maxWidth: '75px' }}>
      <div className="price-container">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <div className="min-qty" style={{ paddingRight: '5px' }}>
            Qty:
          </div>
          <div>{product.quantity}</div>
        </div>
      </div>
    </td>
  ),
  arrow: () => (
    <td className="um" style={{ maxWidth: '40px' }}>
      <div style={{ width: '100%' }}>→</div>
    </td>
  ),
  price: (product: IProduct) => <td className="price">{asPrice(product.price1)}</td>,
  adjustedQty: (product: IProduct) => (
    <td className="price" style={{ maxWidth: '55px', paddingLeft: 0 }}>
      <div className="price-container">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '18px' }}>
          <b>{fitQty(product)}</b>
        </div>
      </div>
    </td>
  ),
  resultQty: (product: IProduct) => (
    <td className="price" style={{ maxWidth: '95px' }}>
      <div className="price-container">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className="min-qty" style={{ paddingRight: '5px' }}>
            Qty:
          </div>
          <div>{product.quantity}</div>
        </div>
      </div>
    </td>
  ),
};
