import React from 'react';
import './address-autocomplete-styles.scss';
import { IPrediction } from './AddressAutocomplete';
import Icon from '@mdi/react';
import { mdiMapMarker } from '@mdi/js';

interface ISuggestionsPopupProps {
  placePredictions: IPrediction[];
  highlight?: string;
  onSelect: (place: IPrediction) => void;
}

const highlightedText = (text: string, query: string = '') => {
  if (!query) return text;

  const regex = new RegExp(`(${query})`, 'gi');
  const parts = text.split(regex);
  const spaceMargin = '4px';

  return (
    <div className="suggestion-label">
      {parts.map((part, index) => (
        <div
          key={index}
          className={part.toLowerCase() === query.toLowerCase() ? 'highlight' : ''}
          style={{
            marginLeft: part.startsWith(' ') ? spaceMargin : '0',
            marginRight: part.endsWith(' ') ? spaceMargin : '0',
          }}
        >
          {part}
        </div>
      ))}
    </div>
  );
};

const SuggestionsPopup = ({ placePredictions, highlight, onSelect }: ISuggestionsPopupProps) => (
  <div className="suggestions-popup">
    {placePredictions.map((prediction, index) => (
      <div key={index} className="suggestion-item" onClick={() => onSelect(prediction)}>
        <Icon path={mdiMapMarker} size={0.65} />
        {highlightedText(prediction.displayText, highlight)}
      </div>
    ))}
  </div>
);

export default SuggestionsPopup;
