import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { TAX_URL } from 'app/config/url';

interface ITax {
  state: string;
  tax: number;
}

const initialState: EntityState<ITax> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  updateSuccess: false,
};

const apiUrl = TAX_URL;

// Actions

export const getAllTaxes = createAsyncThunk('tax/fetch_tax_list', async () => {
  return axios.get<ITax[]>(apiUrl);
});

export const getTax = createAsyncThunk(
  'tax/fetch_tax',
  async (state: string) => {
    return axios.get<ITax>(`${apiUrl}/state`, {
      params: { state },
    });
  },
  { serializeError: serializeAxiosError },
);

// Slice

export const TaxSlice = createEntitySlice({
  name: 'tax',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAllTaxes.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getTax.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getAllTaxes, getTax), state => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getAllTaxes, getTax), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(getAllTaxes, getTax), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = TaxSlice.actions;

// Reducer
export default TaxSlice.reducer;
