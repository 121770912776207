import { EventStatus, getEventStatus } from 'app/shared/model/enumerations/event-status.model';
import { getProposalStatus, ProposalStatus } from 'app/shared/model/enumerations/proposal-status.model';
import { IEvent } from 'app/shared/model/event.model';
import { len } from 'app/util/structure-utils';
import { MergedEventStatus } from 'app/shared/model/enumerations/merged-event-statuses';

/**
 * Returns the merge status based on the event status and proposal status.
 * If the event status is "ORDERED", it returns the event status by calling the function "getEventStatus".
 * Otherwise, it returns the proposal status by calling the function "getProposalStatus".
 *
 * @param {EventStatus} eventStatus - The current event status.
 * @param {ProposalStatus | null} proposalStatus - The current proposal status.
 * @returns {string} - The merge status.
 */
const mergeStatus = (eventStatus: EventStatus, proposalStatus: ProposalStatus | null): string =>
  eventStatus === EventStatus.ORDERED ? getEventStatus(eventStatus) : getProposalStatus(proposalStatus);

/**
 * Gets the merged status of an event based on its orders, event status, and latest proposal status.
 *
 * @param {IEvent} event - The event object.
 * @returns {string} - The merged status of the event.
 */
export const getStatus = (event: IEvent): string => {
  let status: string;
  const orders = event.orders;

  if (orders && len(orders) > 0) {
    status = MergedEventStatus.ORDERED;
  } else {
    const eventStatus = (event && event.status) || null;
    const proposalStatus = (len(event.proposals) > 0 && event.proposals[len(event.proposals) - 1].status) || null;
    status = mergeStatus(eventStatus, proposalStatus);
  }

  return status;
};

/**
 * Find an event by number of one of its proposals from a given array of events.
 *
 * @param {Array} events - The array of events to search.
 * @param {string} proposalNumber - The proposal number to search for.
 * @returns {Object|null} - The event object that matches the given proposal number, or null if no match is found.
 */
export const pickEventByProposalNumber = (events: IEvent[], proposalNumber: string): IEvent =>
  events.find(event => event.proposals.some(p => p.proposalNumber === proposalNumber)) || null;

/**
 * Retrieves the proposal number of the last proposal in the given event.
 *
 * @param {IEvent} event - The event object containing the proposals.
 * @returns {string} - The proposal number of the last proposal.
 */
export const getLastProposalNumber = (event: IEvent): string => event.proposals[len(event.proposals) - 1].proposalNumber;

/**
 * Determines whether the given event is ordered.
 *
 * @param {IEvent} event - The event to check.
 * @returns {boolean} Returns true if the event is ordered, otherwise false.
 */
export const isOrdered = (event: IEvent): boolean => getStatus(event) === MergedEventStatus.ORDERED;
