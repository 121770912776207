import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { ITEMS_PAGE, PROPOSAL_SUMMARY_PAGE } from '../constants';
import PhotoAlbum from 'react-photo-album';
import { getMutedMarkupValidators, len } from 'app/util/structure-utils';
import { useAppSelector } from 'app/config/store';
import { extractFileName } from 'app/util/format-utils';
import { Link } from 'react-router-dom';
import { GALLERY_PATH } from 'app/paths';
import Datepicker from 'app/shared/datepicker/Datepicker';
import { dateToISO, getLastExpirationDateISO } from 'app/util/date-time-utils';
import { getError, validators } from 'app/util/validation-utils';
import { IRecipe } from 'app/shared/model/recipe.model';
import { MergedEventStatus } from 'app/shared/model/enumerations/merged-event-statuses';
import NoItems from 'app/shared/alert/NoItems';
import ValidationErrorsPopup from 'app/shared/error/ValidationErrorsPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const BACK_PAGE: string = PROPOSAL_SUMMARY_PAGE;
const MAX_PICTURES: number = 5;

type FormModel = {
  expirationDate?: Date;
  eventName?: string;
  eventDate?: Date;
  eventTime?: Date;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: number;
  deliveryDate?: Date;
  flowersMarkup?: number;
  suppliesMarkup?: number;
  plantsMarkup?: number;
  laborFee?: number;
  setupFee?: number;
  deliveryFee?: number;
  recipes?: IRecipe[];
};

const itemsPageValidators = {
  eventName: validators.eventName,
  eventDate: validators.eventDate,
  eventTime: validators.eventTime,
  email: validators.email,
  phoneNumber: validators.phoneNumber,
  firstName: validators.firstName,
  lastName: validators.lastName,
  streetAddress: validators.streetAddress,
  streetAddressLine2: validators.streetAddressLine2,
  city: validators.city,
  state: validators.state,
  zipCode: validators.zipCode,
  deliveryDate: validators.deliveryDate,
  recipes: validators.recipes,
  flowersMarkup: validators.flowersMarkup,
  suppliesMarkup: validators.suppliesMarkup,
  plantsMarkup: validators.suppliesMarkup,
  laborFee: validators.laborFee,
  setupFee: validators.setupFee,
  deliveryFee: validators.deliveryFee,
  expirationDate: validators.expirationDate,
};

const validateAll = (data: FormModel, ignoredKeys: string[]) => {
  const errors: { [key: string]: string | null } = {};

  (Object.keys(itemsPageValidators) as (keyof FormModel)[]).forEach(key => {
    errors[key] = ignoredKeys.includes(key) ? null : getError(key, data[key]);
  });

  return errors;
};

const GalleryAndExpirationPage = ({ initialData, isSendingProposal, onDataUpdate, onGoToPage, onSend }) => {
  const [values, setValues] = useState(initialData);
  const [selectedImages, setSelectedImages] = useState([]);
  const [areValidationErrorsVisible, setValidationErrorsVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const isSaving = useAppSelector(state => state.event.updating);
  const pictures = useAppSelector(state => state.gallery.entities);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const initialPictures = initialData && initialData.selectedImages;

    if (initialPictures && initialPictures.length > 0) {
      setSelectedImages(initialPictures);
    }
  }, [initialData]);

  useEffect(() => {
    const mutedValidators = getMutedMarkupValidators(values.recipes);
    const validationErrors = validateAll(values, mutedValidators);
    const errors: string[] = [];
    Object.values(validationErrors).forEach(value => {
      if (value) errors.push(value);
    });
    setValidationErrors(errors);
  }, [values]);

  useEffect(() => {
    onDataUpdate({ selectedImages });
  }, [selectedImages]);

  const togglePictureSelection = (event: any) => {
    const fileName = extractFileName(event.photo.src);

    if (selectedImages.includes(fileName)) {
      const toUpdate = selectedImages.filter(picture => picture !== fileName);
      setSelectedImages(toUpdate);
    } else if (len(selectedImages) < MAX_PICTURES) {
      setSelectedImages([...selectedImages, fileName]);
    }
  };

  const isSelected = (url: string) => selectedImages.includes(extractFileName(url));

  const backClickHandler = () => {
    const productsErrors = {
      deliveryDate: validators.deliveryDate(values.deliveryDate),
      flowersMarkup: validators.flowersMarkup(values.flowersMarkup),
      suppliesMarkup: validators.suppliesMarkup(values.suppliesMarkup),
      plantsMarkup: validators.plantsMarkup(values.plantsMarkup),
      laborFee: validators.laborFee(values.laborFee),
      setupFee: validators.setupFee(values.setupFee),
      deliveryFee: validators.deliveryFee(values.deliveryFee),
      recipes: validators.recipes(values.recipes),
    };
    onGoToPage(BACK_PAGE);
  };

  const sendClickHandler = () => {
    onSend();
  };

  const changeHandler = e => {
    const id = e.target.id;
    const value = e.target.value;
    const updatedValues = { ...values, [id]: value };
    setValues(updatedValues);
    onDataUpdate(updatedValues);
  };

  const renderTitle = (title: string) => <div className="form-section-title">{title}</div>;

  const renderExpirationDatepicker = () => {
    const availableFrom = dateToISO(new Date());
    const unavailableFrom = getLastExpirationDateISO(values.eventDate);
    const disabled = !values.eventDate;
    const id = 'expirationDate';

    return (
      <div className="short-field">
        {renderTitle('Proposal Expiration Date')}

        <FormGroup>
          <Datepicker
            id={id}
            value={values[id]}
            onChange={changeHandler}
            availableFrom={availableFrom}
            unavailableFrom={unavailableFrom}
            disabled={disabled}
          />
          {disabled && <div className="validation-error">Event Date needed</div>}
        </FormGroup>
      </div>
    );
  };

  const renderGalleryPicker = () =>
    len(pictures) > 0 ? (
      <>
        {renderTitle(`Choose pictures from your Gallery (${len(selectedImages)} / ${Math.min(MAX_PICTURES, len(pictures))})`)}
        <div className="image-container">
          <PhotoAlbum
            layout="rows"
            photos={pictures}
            spacing={10}
            onClick={togglePictureSelection}
            renderPhoto={({ photo, layoutOptions, imageProps }) => {
              const { className, src } = imageProps;
              const newClassName = `${className} ${isSelected(src) ? 'selected' : ''}`;

              return <img alt="No Image" {...imageProps} className={newClassName} />;
            }}
          />
        </div>
      </>
    ) : (
      <>
        {renderTitle('Choose pictures from your Gallery')}
        <div className="empty-gallery-container">
          <NoItems text="Your Gallery is empty" />

          <Link to={GALLERY_PATH}>
            <Button color="primary" disabled={isSaving} outline>
              Go To Gallery
            </Button>
          </Link>
        </div>
      </>
    );

  const renderActionFooter = () => (
    <Row className="action-footer">
      <Col md="auto" sm="auto" xs="auto">
        <Button color="secondary" disabled={isSaving || isSendingProposal} onClick={backClickHandler}>
          <FontAwesomeIcon icon={faChevronLeft} />
          &nbsp; Back
        </Button>
      </Col>

      <Col className="text-end">
        <div
          onMouseEnter={() => setValidationErrorsVisible(true)}
          onMouseLeave={() => setValidationErrorsVisible(false)}
          style={{ position: 'relative' }}
        >
          <Button color="primary" disabled={len(validationErrors) > 0 || isSaving || isSendingProposal} onClick={sendClickHandler}>
            {isSaving ? (
              'Saving...'
            ) : isSendingProposal ? (
              'Sending...'
            ) : initialData.status === MergedEventStatus.PENDING_APPROVAL ? (
              'Re-Send'
            ) : (
              <>
                <FontAwesomeIcon icon={faPaperPlane} />
                &nbsp; Send
              </>
            )}
          </Button>
          {areValidationErrorsVisible && <ValidationErrorsPopup messages={validationErrors} />}
        </div>
      </Col>
    </Row>
  );

  return (
    <div className="event-form" style={{ paddingTop: 0 }}>
      {renderGalleryPicker()}
      {renderExpirationDatepicker()}
      {renderActionFooter()}
    </div>
  );
};

export default GalleryAndExpirationPage;
