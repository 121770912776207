import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { getAllTaxes } from 'app/entities/tax/tax.reducer';
import { getCustomNames } from 'app/entities/custom-product-names/custom-product-names.reducer';
import { getPictures } from 'app/entities/gallery/gallery.reducer';
import { getEntities as getEvents } from 'app/entities/event/event.reducer';
import { getEntities as getProducts } from 'app/entities/product/product.reducer';
import { DrawerProvider } from 'app/shared/layout/menus/drawer/Drawer';
import ResponsiveDrawer from 'app/shared/layout/menus/drawer/ResponsiveDrawer';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const [isHeaderLoaded, setHeaderLoaded] = useState(false);
  const isCheckingAuth = useAppSelector(state => state.authentication.loading);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAllTaxes());
      dispatch(getPictures());
      dispatch(getEvents({}));
      dispatch(getProducts({}));
      dispatch(getCustomNames());
    }
  }, [isAuthenticated]);

  return (
    <DrawerProvider>
      <BrowserRouter basename={baseHref}>
        <div className="app-container">
          <ToastContainer position="top-left" className="toastify-container" toastClassName="toastify-toast" />
          <ResponsiveDrawer />

          {isAuthenticated && (
            <ErrorBoundary>
              <Header
                isAuthenticated={isAuthenticated}
                isAdmin={isAdmin}
                ribbonEnv={ribbonEnv}
                isInProduction={isInProduction}
                isOpenAPIEnabled={isOpenAPIEnabled}
                onLoaded={() => setHeaderLoaded(true)}
              />
            </ErrorBoundary>
          )}

          {(isHeaderLoaded || (!isAuthenticated && !isCheckingAuth)) && (
            <div className="container-fluid view-container" id="app-view-container">
              <ErrorBoundary>
                <AppRoutes />
              </ErrorBoundary>

              {isAuthenticated && <Footer />}
            </div>
          )}
        </div>
      </BrowserRouter>
    </DrawerProvider>
  );
};

export default App;
