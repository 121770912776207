export enum ChargePeriod {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export const ChargePeriodDays: { [key in ChargePeriod]: number } = {
  [ChargePeriod.WEEKLY]: 7,
  [ChargePeriod.MONTHLY]: 30,
  [ChargePeriod.QUARTERLY]: 90,
  [ChargePeriod.YEARLY]: 365,
};
