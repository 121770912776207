import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import '../../shared/layout/Layout.scss';
import './settings-styles.scss';
import '../../styles/gallery-styles.scss';
import { getSettings, saveSettings } from 'app/entities/settings/settings.reducer';
import classnames from 'classnames';
import Loading from 'app/shared/alert/loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { SETTINGS_PATH } from 'app/paths';
import { DEFAULT_TAB, settingsTabs } from 'app/entities/settings/tabs/tabs-config';

const getTab = (path: string) => Object.entries(settingsTabs).find(([key, tab]) => !!path && key.toLowerCase() === path.toLowerCase());

const Settings = () => {
  const [settingsCache, setSettingsCache] = useState<any>({});
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);
  const dispatch = useAppDispatch();
  const settings = useAppSelector(state => state.settings.entity);
  const loading = useAppSelector(state => state.settings.loading);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    const fullPath = location.pathname;
    const tabKey = fullPath.split('settings/')[1];
    const tab = getTab(tabKey);

    if (tab) {
      setActiveTab(tabKey.toLowerCase());
    } else {
      navigate(`${SETTINGS_PATH}/${DEFAULT_TAB}`);
    }
  }, [location]);

  useEffect(() => {
    setSettingsCache({ ...settings });
  }, [settings]);

  const saveClickHandler = () => {
    const updatedSettings = {
      ...settings,
      settingEntries: {
        ...settingsCache.settingEntries,
      },
    };
    dispatch(saveSettings(updatedSettings));
  };

  const isDisabled = () => !settings || !settings.settingEntries;

  const changeSettingsEntries = (entries: any) => {
    setSettingsCache((prevCache: any) => ({
      ...prevCache,
      settingEntries: {
        ...prevCache.settingEntries,
        ...entries,
      },
    }));
  };

  const renderTabLinks = () => (
    <Nav tabs>
      {Object.entries(settingsTabs).map(([path, tab]) => (
        <NavItem>
          <NavLink className={classnames({ active: activeTab === path })} onClick={() => navigate(`${SETTINGS_PATH}/${path}`)}>
            {tab.name}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  const renderTabContent = () => (
    <TabContent activeTab={activeTab}>
      {Object.entries(settingsTabs).map(([path, tab]) => (
        <TabPane tabId={path}>
          <tab.tabComponent
            settingEntries={settingsCache.settingEntries}
            onChangeSetting={(entries: any) => changeSettingsEntries(entries)}
          />
        </TabPane>
      ))}
    </TabContent>
  );

  return (
    <div className="flori-form">
      <Col>
        <h4>Settings</h4>
      </Col>

      {renderTabLinks()}

      {loading ? (
        <Loading padding />
      ) : (
        <>
          {renderTabContent()}

          <Row>
            <Col className="text-end action-footer">
              <Button color="primary" style={{ marginLeft: '20px' }} onClick={saveClickHandler} disabled={isDisabled()}>
                Save
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Settings;
