import React, { useState } from 'react';
import { ValidatedField } from 'react-jhipster';
import { Button, Alert, Form } from 'reactstrap';
import { type FieldError, useForm } from 'react-hook-form';
import './login-styles.scss';
import axios from 'axios';
import { RESET_PASSWORD_URL } from 'app/config/url';
import { createGlobalStyle } from 'styled-components';

export interface ILoginPageProps {
  loginError: boolean;
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
}

const LoginPage = (props: ILoginPageProps) => {
  const [isPasswordRecovering, setIsPasswordRecovering] = useState(false);
  const [emailForPasswordRecovery, setEmailForPasswordRecovery] = useState<string>(null);

  const login = ({ username, password, rememberMe }) => {
    props.handleLogin(username, password, rememberMe);
  };

  const GlobalStyle = createGlobalStyle`
  .app-container .view-container {
    padding: 0;
    max-width: 100%;
  }
`;

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const { loginError } = props;

  const handleLoginSubmit = e => {
    e.preventDefault();
    handleSubmit(login)(e);
  };

  const onRequestReset = (data: any) => {
    const email = data.username;
    axios
      .post(RESET_PASSWORD_URL, email, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .then(res => {
        if (res.status === 200) setEmailForPasswordRecovery(email);
      });
  };

  const renderResetRequestConfirmation = () => (
    <>
      <span style={{ paddingBottom: '20px' }}>
        We sent a recovery link to <span style={{ fontWeight: 600 }}>{emailForPasswordRecovery}</span>
      </span>

      <Button
        color="primary"
        outline
        style={{ marginTop: '75px' }}
        onClick={() => {
          setIsPasswordRecovering(false);
          setEmailForPasswordRecovery(null);
        }}
      >
        Back to Login Form
      </Button>
    </>
  );

  const renderPasswordRecoveryForm = () => (
    <>
      <div style={{ paddingBottom: '20px' }}>We will send a recovery link to</div>

      <ValidatedField
        name="username"
        placeholder="Email"
        required
        autoFocus
        data-cy="username"
        validate={{ required: 'Username cannot be empty!' }}
        register={register}
        error={errors.username as FieldError}
        isTouched={touchedFields.username}
      />

      <Button color="primary" style={{ marginTop: '15px' }} onClick={handleSubmit(onRequestReset)}>
        Send Recovery Link
      </Button>

      <Button color="primary" outline style={{ marginTop: '75px' }} onClick={() => setIsPasswordRecovering(false)}>
        Back
      </Button>
    </>
  );

  const renderLoginForm = () => (
    <Form onSubmit={handleLoginSubmit}>
      {loginError ? (
        <Alert color="danger" data-cy="loginError">
          <strong>Failed to sign in!</strong> Please check your credentials and try again.
        </Alert>
      ) : null}

      <ValidatedField
        name="username"
        placeholder="Email"
        required
        autoFocus
        data-cy="username"
        validate={{ required: 'Username cannot be empty!' }}
        register={register}
        error={errors.username as FieldError}
        isTouched={touchedFields.username}
      />

      <div style={{ position: 'relative', margin: '30px 0 75px 0' }}>
        <ValidatedField
          name="password"
          type="password"
          placeholder="Password"
          required
          data-cy="password"
          validate={{ required: 'Password cannot be empty!' }}
          register={register}
          error={errors.password as FieldError}
          isTouched={touchedFields.password}
        />

        <a className="forgot-password" onClick={() => setIsPasswordRecovering(true)}>
          Forgot Password?
        </a>
      </div>

      <Button color="primary" type="submit" data-cy="submit">
        Sign in
      </Button>
    </Form>
  );

  return (
    <>
      <GlobalStyle />

      <div className="login-container">
        <div className="login-form">
          <div className="logo-header">
            <img src="../../../content/images/logo.svg" alt="Logo" />
          </div>

          {isPasswordRecovering
            ? !!emailForPasswordRecovery
              ? renderResetRequestConfirmation()
              : renderPasswordRecoveryForm()
            : renderLoginForm()}
        </div>
      </div>
    </>
  );
};

export default LoginPage;
