import { Button, Col, Input, Row } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import { asPrice } from 'app/util/format-utils';
import { calculateRecipeTotals, countCategoryProducts, len, segregateProductCategories } from 'app/util/structure-utils';
import { renderDashboardSection } from '../ItemsPage';
import Qty from 'app/shared/qty/Qty';
import ProductTable from 'app/entities/event/product-table/ProductTable';
import { IRecipe } from 'app/shared/model/recipe.model';
import Note from './note/Note';
import NoItems from 'app/shared/alert/NoItems';
import { Department } from 'app/shared/model/enumerations/department.model';
import { IProduct } from 'app/shared/model/product.model';
import ActionButton from 'app/shared/action-buttons/ActionButton';
import { mdi } from 'app/config/constants';
import { useWindowWidth } from 'app/shared/hooks/useWindowWidth';
import { screenWidth } from 'app/shared/model/enumerations/screen-modes';

const Recipe = ({ recipe, markups, onRecipeChange, onRecipeRemove, onAddProductClick }) => {
  const [isEditName, setEditName] = useState(false);
  const [isExpanded, setExpanded] = useState(true);
  const [recipeName, setRecipeName] = useState<string>(recipe.name);
  const [recipeQty, setRecipeQty] = useState<number>(recipe.quantity);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [recipeNameBackup, setRecipeNameBackup] = useState<string>('');
  const width = useWindowWidth();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const depts: Department[] = recipe.products ? Array.from(new Set(recipe.products.map((p: IProduct) => p.department))) : [];
    setDepartments(depts);
  }, [recipe.products]);

  useEffect(() => {
    const newRecipe = { ...recipe, name: recipeName };
    onRecipeChange(newRecipe);
  }, [recipeName]);

  useEffect(() => {
    const newRecipe = { ...recipe, quantity: recipeQty };
    onRecipeChange(newRecipe);
  }, [recipeQty]);

  useEffect(() => {
    if (isEditName) {
      inputRef.current?.focus();
    }
  }, [isEditName]);

  const updateNoteHandler = (text: string) => {
    const newRecipe = { ...recipe, note: text };
    onRecipeChange(newRecipe);
  };

  const renderProducts = (recipe: IRecipe) => {
    const categories = segregateProductCategories(recipe);
    const totalProducts = countCategoryProducts(categories);
    const categoryLengths = categories.map(c => len(c.products));

    const getStartIndex = (idx: number): number => categoryLengths.slice(0, idx).reduce((acc, length) => acc + length, 1);

    return (
      <div>
        {totalProducts > 0 ? (
          categories.map(
            (category, idx) =>
              category.products &&
              len(category.products) > 0 && (
                <ProductTable
                  key={idx}
                  title={category.title}
                  products={category.products}
                  startFromIndex={getStartIndex(idx)}
                  recipe={recipe}
                  editableNames
                  onRecipeChange={onRecipeChange}
                />
              ),
          )
        ) : (
          <NoItems text="No Products Added" />
        )}
      </div>
    );
  };

  const renderTotalDashboard = () => {
    const totals = calculateRecipeTotals(recipe, markups.flowers, markups.supplies, markups.plants);

    const hasError = (department: Department, key: string) => departments.includes(department) && (!markups[key] || markups[key] <= 0);

    const isMarkupError =
      hasError(Department.FLOWERS, 'flowers') || hasError(Department.SUPPLIES, 'supplies') || hasError(Department.PLANTS, 'plants');

    return (
      <div className="total-dashboard">
        {renderDashboardSection('Cost', asPrice(totals.cost))}
        {renderDashboardSection('Price', isMarkupError ? null : asPrice(totals.price))}
        {renderDashboardSection(
          'Profit',
          isMarkupError ? null : asPrice(totals.profit),
          totals.profit && totals.profit > 0 && 'primary-text',
        )}
      </div>
    );
  };

  const cancelRename = () => {
    setEditName(false);
    setRecipeName(recipeNameBackup);
  };

  const applyName = () => {
    setRecipeName(recipeName.trim());
    setEditName(false);
  };

  const renameHandler = () => {
    setRecipeNameBackup(recipeName);
    setEditName(true);
  };

  const renderRenameButton = () => <ActionButton icon={mdi.edit} name="Rename" clickHandler={renameHandler} />;

  const renderDeleteButton = () => <ActionButton icon={mdi.delete} name="Delete" clickHandler={onRecipeRemove} />;

  const renderSpoilerStateButton = () => (
    <ActionButton
      icon={isExpanded ? mdi.collapse : mdi.expand}
      name={isExpanded ? 'Collapse' : 'Expand'}
      large
      clickHandler={() => setExpanded(prev => !prev)}
    />
  );

  const renderRecipeName = () => (
    <div className="recipe-title-container">
      {isEditName ? (
        <Input
          innerRef={inputRef}
          value={recipe.name}
          onChange={e => setRecipeName(e.target.value)}
          onBlur={() => {
            if (len(recipeName.trim()) > 0) {
              applyName();
            } else {
              cancelRename();
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Escape') {
              cancelRename();
            } else if (event.key === 'Enter' && len(recipeName.trim()) > 0) {
              applyName();
            }
          }}
        />
      ) : (
        <div style={{ display: 'flex' }} onClick={renameHandler}>
          <div className="form-section-subtitle recipe-name" title={recipe.name}>
            {recipe.name}
          </div>

          <div style={{ margin: '-4px 0 0 10px' }}>{renderRenameButton()}</div>
        </div>
      )}
    </div>
  );

  const renderRecipeQty = () => (
    <div className="recipe-qty">
      <Qty min={1} value={recipe.quantity} width={'90px'} onChange={setRecipeQty} />
    </div>
  );

  const renderActions = () => (
    <div className="recipe-actions">
      {renderDeleteButton()}
      <div style={{ minWidth: '10px' }} />
      {renderSpoilerStateButton()}
    </div>
  );

  const renderSpoilerHeader = () => (
    <div className="recipe-spoiler-header">
      {renderRecipeName()}
      {renderRecipeQty()}
      {width > screenWidth.MAX_NARROW && renderTotalDashboard()}
      {renderActions()}
    </div>
  );

  const renderRecipeContent = () => (
    <Row>
      <div className="products">
        <Row className="products-table embedded">
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Note text={recipe.note} onSubmit={updateNoteHandler} />
            </div>

            {width <= screenWidth.MAX_NARROW && renderTotalDashboard()}
          </div>

          {renderProducts(recipe)}

          <Row>
            <Col className="text-center">
              <Button onClick={onAddProductClick} style={{ margin: '15px 0 5px' }} color="primary" outline>
                + Add Product(s)
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </Row>
  );

  return (
    <div className="recipe">
      {renderSpoilerHeader()}
      {isExpanded && renderRecipeContent()}
    </div>
  );
};

export default Recipe;
