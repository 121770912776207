import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Row, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deletePicture, uploadPicture } from './gallery.reducer';
import '../../shared/layout/Layout.scss';
import './gallery-styles.scss';
import '../../styles/gallery-styles.scss';
import PhotoAlbum from 'react-photo-album';
import Icon from '@mdi/react';
import { mdiFolderOpenOutline } from '@mdi/js';
import { len } from 'app/util/structure-utils';
import NoItems from 'app/shared/alert/NoItems';
import { AUTHORITIES } from 'app/config/constants';
import Loading from 'app/shared/alert/loading';
import { compressImage, convertToJpeg, resizeImage } from 'app/util/file-utils';

const Gallery = () => {
  const [modal, setModal] = useState(false);
  const [currentPicture, setCurrentPicture] = useState<string | null>(null);
  const [isAdmin, setAdmin] = useState(false);
  const dispatch = useAppDispatch();
  const pictures = useAppSelector(state => state.gallery.entities);
  const isUploading = useAppSelector(state => state.gallery.updating);
  const account = useAppSelector(state => state.authentication.account);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setAdmin(account.authorities.includes(AUTHORITIES.ADMIN));
  }, [account]);

  const openFileDialogHandler = () => document.getElementById('file-input')?.click();

  const fileChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Object.values(event.target.files);

    if (files && len(files) > 0) {
      files.forEach(file => {
        try {
          uploadFile(file);
        } catch (e) {
          console.error('Failed to upload file', file.name);
        }
      });
    }
  };

  const uploadFile = async (file: File) => {
    const resizedImage = await resizeImage(file);
    const jpegImage = await convertToJpeg(resizedImage);
    const compressedImage = await compressImage(jpegImage);

    const image = new File([compressedImage], file.name, {
      type: file.type,
      lastModified: Date.now(),
    });

    dispatch(uploadPicture(image)).then(() => {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    });
  };

  const handleImageClick = (pictureUrl: string) => {
    if (!pictureUrl.toLowerCase().includes('/public/') || isAdmin) {
      setCurrentPicture(pictureUrl);
      setModal(true);
    }
  };

  const noClickHandler = () => {
    resetModal();
  };

  const yesClickHandler = () => {
    dispatch(deletePicture(currentPicture));
    resetModal();
  };

  const resetModal = () => {
    setModal(false);
    setCurrentPicture(null);
  };

  return (
    <div className="stretch col-md-8">
      <Row>
        <Col>
          <h4 id="contact-heading" data-cy="ContactHeading">
            {isAdmin ? 'Public' : ''} Gallery
          </h4>
        </Col>

        <Col>
          <div className="d-flex justify-content-end">
            <input
              type="file"
              id="file-input"
              style={{ display: 'none' }}
              accept="image/*"
              multiple
              onChange={fileChangeHandler}
              ref={fileInputRef}
            />

            {isUploading && (
              <div className="justify-content-end" style={{ display: 'flex', alignItems: 'center' }}>
                <Loading text="" />
              </div>
            )}

            <Button
              className="btn jh-create-entity"
              color="primary"
              id="jh-create-entity"
              data-cy="entityCreateButton"
              onClick={openFileDialogHandler}
            >
              <Icon path={mdiFolderOpenOutline} size={0.75} />
              &nbsp; Upload Picture
            </Button>
          </div>
        </Col>
      </Row>

      {len(pictures) > 0 ? (
        <div className="image-container" style={{ position: 'relative' }}>
          <PhotoAlbum
            layout="rows"
            photos={pictures}
            spacing={10}
            renderPhoto={({ photo, layoutOptions, imageProps }) => {
              const { className, src, ...rest } = imageProps;
              const isSelected = currentPicture === src;
              const newClassName = `${className} ${isSelected ? 'selected-danger' : ''}`;

              return <img alt="No Image" {...rest} src={src} className={newClassName} onClick={() => handleImageClick(photo.src)} />;
            }}
          />

          <Modal isOpen={modal} toggle={resetModal}>
            <ModalBody>Remove selected picture?</ModalBody>

            <ModalFooter>
              <Button color="secondary" onClick={noClickHandler}>
                No
              </Button>{' '}
              <Button color="danger" onClick={yesClickHandler}>
                Yes
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      ) : (
        <NoItems text={`${isAdmin ? 'Public' : 'Your'} Gallery is empty so far`} />
      )}
    </div>
  );
};

export default Gallery;
