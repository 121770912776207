import axios from 'axios';
import { SUBSCRIPTIONS_URL } from 'app/config/url';
import SubscriptionPopup from 'app/modules/startup/subscription/SubscriptionPopup';
import React from 'react';

const Subscription = ({ onDone }) => {
  const acceptSubscription = () => {
    axios.get(`${SUBSCRIPTIONS_URL}/assign`, { params: { chargeAfterTrial: true } }).then(() => {
      onDone();
    });
  };

  return <SubscriptionPopup onAccept={acceptSubscription} />;
};

export default Subscription;
