export default [
  { name: 'First Name', value: '[first name]' },
  { name: 'Last Name', value: '[last name]' },
  { name: 'Event Name', value: '[event]' },
  { name: 'Event Date', value: '[event date]' },
  { name: 'Event Time', value: '[event time]' },
  { name: 'Email', value: '[email]' },
  { name: 'Phone', value: '[phone]' },
  { name: 'Delivery Date', value: '[delivery date]' },
  { name: 'Address', value: '[address]' },
  { name: 'My Email', value: '[my email]' },
  { name: 'My Phone', value: '[my phone]' },
  { name: 'My Business Name', value: '[my business name]' },
];
