import './validation-styles.scss';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import React from 'react';
import { len } from 'app/util/structure-utils';

const ValidationErrorsPopup = ({ messages }: { messages: string[] }) => {
  return (
    messages &&
    len(messages) > 0 && (
      <div className="validation-errors-popup">
        {messages.map((message, index) => (
          <div key={index} className="validation-message">
            <Icon path={mdiAlertCircleOutline} size={0.5} style={{ marginRight: '10px' }} />
            {message}
          </div>
        ))}
      </div>
    )
  );
};

export default ValidationErrorsPopup;
