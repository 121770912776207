import React, { useEffect, useState } from 'react';
import { fitQty, getProductIdQty, len, mergeRecipes } from 'app/util/structure-utils';
import { Department } from 'app/shared/model/enumerations/department.model';
import ProductTable from 'app/entities/event/product-table/ProductTable';
import { Button, Col, Modal, Row } from 'reactstrap';
import './event-form-components/event-form-styles.scss';
import axios from 'axios';
import { ORDER_URL } from 'app/config/url';
import { IProduct } from 'app/shared/model/product.model';
import { IRecipe } from 'app/shared/model/recipe.model';
import NoItems from 'app/shared/alert/NoItems';

const RECIPE_NAME: string = 'Merged Recipe';

const ReviseBeforeOrder = ({ initialEvent, onOrderPlaced, onClose }) => {
  const [event, setEvent] = useState({ ...initialEvent });
  const [productCount, setProductCount] = useState(0);
  const [placing, setPlacing] = useState(false);

  useEffect(() => {
    const eventCopy = { ...initialEvent };
    const recipe = {
      ...eventCopy.markupGroup.recipes[0],
      products: mergeRecipes(eventCopy.markupGroup.recipes, true).map(p => ({ ...p, quantity: fitQty(p) })),
      name: RECIPE_NAME,
    };
    const newEvent = {
      ...eventCopy,
      markupGroup: {
        ...eventCopy.markupGroup,
        recipes: [recipe],
      },
    };
    setEvent(newEvent);
  }, [initialEvent]);

  useEffect(() => {
    const recipe = event.markupGroup && event.markupGroup.recipes[0];

    if (!recipe) return;

    const products = recipe.products;
    const totalProducts = products.reduce((productSum: number, product: IProduct) => productSum + product.quantity, 0);
    setProductCount(totalProducts);
  }, [event]);

  const placeOrderHandler = async () => {
    setPlacing(true);
    axios
      .post(`${ORDER_URL}`, event)
      .then(res => {
        onOrderPlaced();
        onClose();
      })
      .finally(() => {
        setPlacing(false);
      });
  };

  const changeRecipe = (recipe: IRecipe) => {
    const newEvent = {
      ...event,
      markupGroup: {
        ...event.markupGroup,
        recipes: [{ ...recipe, productsNamesQuantity: getProductIdQty(recipe.products) }],
      },
    };
    setEvent(newEvent);
  };

  return (
    <Modal className="form-modal" isOpen={true}>
      <div className="event-form">
        <div className="form-section-title">Confirm Your Order</div>
        <Row className="products-table embedded">
          {event.markupGroup &&
            event.markupGroup.recipes.map((recipe: IRecipe) => {
              const categories = [
                {
                  title: 'Flowers',
                  products: recipe.products.filter(product => product.department === Department.FLOWERS),
                },
                {
                  title: 'Supplies',
                  products: recipe.products.filter(product => product.department === Department.SUPPLIES),
                },
                {
                  title: 'Plants',
                  products: recipe.products.filter(product => product.department === Department.PLANTS),
                },
              ];

              return productCount > 0 ? (
                categories.map(
                  (category, idx) =>
                    category.products &&
                    len(category.products) > 0 && (
                      <ProductTable
                        key={idx}
                        title={category.title}
                        products={category.products}
                        stepQtyChange
                        recipe={recipe}
                        onRecipeChange={changeRecipe}
                      />
                    ),
                )
              ) : (
                <NoItems text="No Products" />
              );
            })}
        </Row>

        <Row className="action-footer text-end">
          <Col className="text-end">
            <Button onClick={onClose} disabled={placing}>
              Cancel
            </Button>

            <Button color="primary" onClick={placeOrderHandler} style={{ marginLeft: '20px' }} disabled={productCount === 0 || placing}>
              {placing ? 'Placing Order...' : 'Place My Order'}
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ReviseBeforeOrder;
