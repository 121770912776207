/**
 * Enum representing screen width options. <br><br>
 * <b>Keep synced with the corresponding constants in app.scss.</b>
 *
 * @enum {number}
 */
export enum screenWidth {
  MAX_PHONE = 840,
  MAX_TABLET = 1080,
  MAX_NARROW = 768,
  MIN_TABLET = screenWidth.MAX_PHONE + 1,
  MIN_WIDE = screenWidth.MAX_TABLET + 1,
  MIN_NOT_NARROW = screenWidth.MAX_NARROW + 1,
}
