import React, { useEffect, useState } from 'react';
import { calculateTotals, fitQty, ITotals, len, mergeRecipes } from 'app/util/structure-utils';
import { Department } from 'app/shared/model/enumerations/department.model';
import ProductTable from 'app/entities/event/product-table/ProductTable';
import { Button, Col, Row } from 'reactstrap';
import '../event-form-styles.scss';
import { IProduct } from 'app/shared/model/product.model';
import { GALLERY_AND_EXPIRATION_PAGE, ITEMS_PAGE } from '../constants';
import { asPrice } from 'app/util/format-utils';
import { mdiCheckCircleOutline, mdiInformationVariant } from '@mdi/js';
import Hint from 'app/shared/layout/hint/Hint';
import { useAppSelector } from 'app/config/store';
import { renderer } from 'app/util/table-column-renderers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faSave } from '@fortawesome/free-solid-svg-icons';
import { Color } from 'app/shared/model/enumerations/color.model';
import NoItems from 'app/shared/alert/NoItems';
import { useWindowWidth } from 'app/shared/hooks/useWindowWidth';
import { screenWidth } from 'app/shared/model/enumerations/screen-modes';

const BACK_PAGE: string = ITEMS_PAGE;
const NEXT_PAGE: string = GALLERY_AND_EXPIRATION_PAGE;

const ProposalSummaryPage = ({ initialData, onGoToPage, onSave }) => {
  const [values, setValues] = useState(initialData);
  const [adjustedProducts, setAdjustedProducts] = useState<IProduct[]>([]);
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const [adjustedCount, setAdjustedCount] = useState(0);
  const [isCollapsed, setCollapsed] = useState(true);
  const isSaving = useAppSelector(state => state.event.updating);
  const width = useWindowWidth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const roundedQtyProducts = mergeRecipes(values.recipes).filter(p => p.quantity !== fitQty(p));
    const products = mergeRecipes(values.recipes);
    products.forEach((p: IProduct) => {
      p.quantity = fitQty(p);
    });
    setAdjustedProducts(roundedQtyProducts);
    setAdjustedCount(len(roundedQtyProducts));
    setAllProducts(products);
  }, [values]);

  const backClickHandler = () => {
    onGoToPage(BACK_PAGE);
  };

  const nextClickHandler = () => {
    onGoToPage(NEXT_PAGE);
  };

  const saveClickHandler = () => {
    onSave();
  };

  const getAdjustedColumns = () => [
    { render: renderer.image },
    { render: renderer.productName },
    { render: renderer.um },
    { render: renderer.initialQty },
    { render: renderer.arrow },
    { render: renderer.adjustedQty },
  ];

  const getSummaryColumns = () => [
    { render: renderer.image },
    { render: renderer.productName },
    { render: renderer.um },
    { render: renderer.resultQty },
  ];

  const prepareRecipeForTable = (products: IProduct[]) => {
    const categories = [
      {
        title: 'Flowers',
        products: products.filter(product => product.department === Department.FLOWERS),
      },
      {
        title: 'Supplies',
        products: products.filter(product => product.department === Department.SUPPLIES),
      },
      {
        title: 'Plants',
        products: products.filter(product => product.department === Department.PLANTS),
      },
    ];
    const recipe = { products };

    return { categories, recipe };
  };

  const renderAdjustedTable = () => {
    const { categories, recipe } = prepareRecipeForTable(adjustedProducts);

    return (
      <Row className="products-table stretch-height">
        {categories.map(
          (category, i) =>
            category.products &&
            len(category.products) > 0 && (
              <ProductTable
                key={i}
                title={category.title}
                products={category.products}
                recipe={recipe}
                readOnly
                columns={getAdjustedColumns()}
                onRecipeChange={() => {}}
              />
            ),
        )}
      </Row>
    );
  };

  const renderSummaryTable = () => {
    const { categories, recipe } = prepareRecipeForTable(allProducts);

    return (
      <Row className="products-table stretch-height">
        {categories.map(
          (category, i) =>
            category.products &&
            len(category.products) > 0 && (
              <ProductTable
                key={i}
                title={category.title}
                products={category.products}
                recipe={recipe}
                readOnly
                columns={getSummaryColumns()}
                onRecipeChange={() => {}}
              />
            ),
        )}
      </Row>
    );
  };

  const renderHint = () => {
    if (len(values.recipes) === 0 || len(mergeRecipes(values.recipes)) === 0) return;

    const isAdjusted = adjustedCount > 0;
    const icon = isAdjusted ? mdiInformationVariant : mdiCheckCircleOutline;
    const layout = isAdjusted ? 'horizontal' : 'vertical';
    const color = isAdjusted ? Color.WARNING : Color.SUCCESS;
    const content = isAdjusted ? (
      <div>
        <div>This summarizes what you would order from us upon proposal acceptance and conversion into a formal order.</div>
        <div>All proposals converted to orders are rounded up to the nearest bunch/case size minimum.</div>
      </div>
    ) : len(values.recipes) > 1 ? (
      'Your recipes are set up perfectly!'
    ) : (
      'Your recipe is set up perfectly!'
    );

    return (
      <Hint icon={icon} color={color} layout={layout}>
        {content}
      </Hint>
    );
  };

  const calculateAllTotals = () => {
    return calculateTotals(
      allProducts,
      values.tax?.rate || 0,
      values.flowersMarkup,
      values.suppliesMarkup,
      values.plantsMarkup,
      values.laborFee,
      values.setupFee,
      values.deliveryFee,
    );
  };

  const renderTotals = (totals: ITotals) => (
    <div className="totals-container" style={{ padding: '0 20px 20px 0' }}>
      <Row>
        <div className="totals-title" style={{ border: 'none', paddingTop: 0 }}>
          Total amount of Your Order
        </div>
      </Row>

      <div className="totals">
        <div className="totals-row">
          <div className="totals-value">
            <b>{asPrice(totals.priceAmount)}</b>
          </div>
        </div>
      </div>
    </div>
  );

  const renderResultOrderSpoiler = () =>
    len(values.recipes) > 0 && len(mergeRecipes(values.recipes)) > 0 ? (
      <div className="recipe" style={{ marginTop: '20px' }}>
        <div
          className="recipe-spoiler-header primary-text"
          style={{ padding: '5px 20px', cursor: 'pointer' }}
          onClick={() => setCollapsed(prevState => !prevState)}
        >
          {isCollapsed ? 'See the whole order' : 'Hide details'}
        </div>

        {!isCollapsed && (
          <div>
            {renderSummaryTable()}
            {renderTotals(calculateAllTotals())}
          </div>
        )}
      </div>
    ) : (
      <NoItems text="Add at least 1 product to see summary" />
    );

  const renderActionFooter = () => (
    <Row className="action-footer">
      <Col md="auto" sm="auto" xs="auto">
        <Button color="secondary" onClick={backClickHandler} disabled={isSaving}>
          <FontAwesomeIcon icon={faChevronLeft} />
          &nbsp; Back
        </Button>
      </Col>

      <Col className="text-end">
        <Button color="secondary" onClick={saveClickHandler} style={{ marginRight: '20px' }} disabled={isSaving}>
          {isSaving ? (
            'Saving...'
          ) : (
            <>
              <FontAwesomeIcon icon={faSave} />
              &nbsp; {width > screenWidth.MAX_NARROW ? 'Save Draft' : 'Save'}
            </>
          )}
        </Button>

        <Button color="primary" onClick={nextClickHandler} disabled={isSaving}>
          Next &nbsp;
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </Col>
    </Row>
  );

  return (
    <div className="event-form" style={{ paddingTop: 0 }}>
      <div className="form-section-title">Proposal Summary</div>
      {renderHint()}
      {renderAdjustedTable()}
      {renderResultOrderSpoiler()}
      {renderActionFooter()}
    </div>
  );
};

export default ProposalSummaryPage;
