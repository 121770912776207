import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import React from 'react';
import './subscriptions-modal.scss';

const RestrictionPopup = ({ isVisible }) => (
  <Modal isOpen={isVisible}>
    <ModalHeader>Restricted Access</ModalHeader>
    <ModalBody style={{ borderTop: '2px solid gray' }}>
      <h4>
        Your subscription has been cancelled. To reactivate, contact your salesperson or send a message to info@fallriverfloristsupply.com.
      </h4>
    </ModalBody>
  </Modal>
);

export default RestrictionPopup;
