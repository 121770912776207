import PersonalizedEditor from 'app/entities/settings/PersonalizedEditor';
import React from 'react';
import { ISettingsTabProps } from 'app/entities/settings/tabs/tabs-config';

const AgreementTab = ({ settingEntries, onChangeSetting }: ISettingsTabProps) => (
  <div className="setting-tab-container">
    <PersonalizedEditor
      setting={settingEntries ? settingEntries.agreement : ''}
      title="Personalize your agreement by clicking on the buttons to insert details into the text."
      onChange={(text: string) => onChangeSetting({ agreement: text })}
    />
  </div>
);

export default AgreementTab;
