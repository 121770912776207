import dayjs from 'dayjs';
import { IContact } from 'app/shared/model/contact.model';
import { IFlorist } from 'app/shared/model/florist.model';
import { IProposal } from 'app/shared/model/proposal.model';
import { IEventPayment } from 'app/shared/model/event-payment.model';
import { EventStatus } from 'app/shared/model/enumerations/event-status.model';
import { IMarkupGroup } from 'app/shared/model/markup-group.model';
import { IOrderHistory } from 'app/shared/model/order-history.model';

class IOrder {
  orderNumber?: string | null;
}

export interface IEvent {
  id?: number | null;
  name?: string | null;
  eventDate?: dayjs.Dayjs | null;
  description?: string | null;
  tag?: string | null;
  deliveryDate?: dayjs.Dayjs | null;
  contact?: IContact | null;
  florist?: IFlorist | null;
  status?: EventStatus | null;
  proposals?: IProposal[] | null;
  eventPayment?: IEventPayment | null;
  markupGroup?: IMarkupGroup | null;
  orders?: IOrder[] | null;
  orderHistories?: IOrderHistory[];
}

export const defaultValue: Readonly<IEvent> = {};
