import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getEntities, updateEntity } from './event.reducer';
import '../../shared/layout/Layout.scss';
import { len } from 'app/util/structure-utils';
import NotFound from 'app/shared/alert/not-found';
import { defaultValue, IEvent } from 'app/shared/model/event.model';
import { dateTimeToDayjs, extractTimeFromTimestamp, getLastExpirationDateISO, timeStampToISO } from 'app/util/date-time-utils';
import { getLastProposalNumber, getStatus, isOrdered, pickEventByProposalNumber } from 'app/util/event-utils';
import { IRecipe } from 'app/shared/model/recipe.model';
import { EVENTS_PATH } from 'app/paths';
import Loading from 'app/shared/alert/loading';
import { IMarkupGroup } from 'app/shared/model/markup-group.model';
import { EVENT_PAGE, GALLERY_AND_EXPIRATION_PAGE, ITEMS_PAGE, PROPOSAL_SUMMARY_PAGE } from './event-form-components/constants';
import EventPage from './event-form-components/event-page/EventPage';
import ItemsPage from './event-form-components/items-page/ItemsPage';
import ProposalSummaryPage from './event-form-components/proposal-summary-page/ProposalSummaryPage';
import GalleryAndExpirationPage from './event-form-components/gallery-and-expiration-page/GalleryAndExpirationPage';
import axios from 'axios';
import { SEND_PROPOSAL_URL } from 'app/config/url';
import { IContact } from 'app/shared/model/contact.model';
import { IProposal } from 'app/shared/model/proposal.model';
import dayjs from 'dayjs';
import { Col, Row } from 'reactstrap';

const INITIAL_PAGE = EVENT_PAGE;

const pages = [EVENT_PAGE, ITEMS_PAGE, PROPOSAL_SUMMARY_PAGE, GALLERY_AND_EXPIRATION_PAGE];

type EventData = {
  id?: number;
  contactId?: number;
  city?: string;
  deliveryDate?: any;
  email?: string;
  eventDate?: any;
  eventName?: string;
  eventTime?: string;
  firstName?: string;
  flowersMarkup?: number;
  plantsMarkup?: number;
  lastName?: string;
  phoneNumber?: string;
  recipes?: IRecipe[];
  selectedImages?: string[];
  state?: string;
  streetAddress?: string;
  streetAddressLine2?: string;
  suppliesMarkup?: number;
  zipCode?: string;
  note?: string;
  expirationDate?: any;
  tax?: any;
  status?: string;
  laborFee?: number;
  setupFee?: number;
  deliveryFee?: number;
};

const initialData: EventData = {
  flowersMarkup: 200,
  plantsMarkup: 200,
  recipes: [],
  selectedImages: [],
  suppliesMarkup: 200,
  laborFee: 100,
  setupFee: 100,
};

const EventForm = () => {
  const [activePage, setActivePage] = useState(INITIAL_PAGE);
  const [eventData, setEventData] = useState<EventData>(initialData);
  const [shouldSend, setShouldSend] = useState(false);
  const [isSendingProposal, setSendingProposal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<any>(defaultValue);
  const [isError, setError] = useState(false);
  const isSaved = useAppSelector(state => state.event.updateSuccess);
  const isLoading = useAppSelector(state => state.event.loading);
  const isSaving = useAppSelector(state => state.event.updating);
  const eventList = useAppSelector(state => state.event.entities);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { proposalNumber } = useParams<'proposalNumber'>();

  useEffect(() => {
    if (proposalNumber) {
      const event = pickEventByProposalNumber(eventList, proposalNumber);

      if (!event && !isLoading) {
        setError(true);
        return;
      } else {
        setError(false);
      }

      if (event) {
        if (isOrdered(event)) {
          navigate(`${EVENTS_PATH}/${getLastProposalNumber(event)}`);
        } else {
          setCurrentEvent(event);
        }
      }
    }
  }, [eventList, proposalNumber]);

  useEffect(() => {
    if (!isSaving && isSaved) {
      dispatch(getEntities({}));

      if (!shouldSend) {
        resetAndClose();
      }
    }
  }, [isSaved, isSaving, shouldSend, dispatch]);

  useEffect(() => {
    if (!currentEvent || currentEvent === defaultValue) return;

    const recipes = JSON.parse(JSON.stringify(currentEvent.markupGroup.recipes));
    recipes.forEach((recipe: IRecipe) => {
      const products = recipe.products || [];
      products.forEach(product => {
        product.quantity = Math.floor(product.quantity / recipe.quantity);
      });
    });
    const eventDate = timeStampToISO(currentEvent.eventDate);
    const lastProposal = currentEvent.proposals && currentEvent.proposals[currentEvent.proposals.length - 1];
    const selectedImages = (lastProposal && lastProposal.imageUrls && lastProposal.imageUrls.split(' ')) || '';
    const expirationDate = (lastProposal && timeStampToISO(lastProposal.expirationDate)) || getLastExpirationDateISO(eventDate);
    const markupGroup = currentEvent.markupGroup;

    setEventData({
      id: currentEvent.id,
      deliveryDate: timeStampToISO(currentEvent.deliveryDate),
      eventDate,
      eventTime: extractTimeFromTimestamp(currentEvent.eventDate),
      eventName: currentEvent.name,
      contactId: currentEvent.contact?.id,
      city: currentEvent.contact?.city,
      email: currentEvent.contact?.email,
      firstName: currentEvent.contact?.firstName,
      lastName: currentEvent.contact?.lastName,
      phoneNumber: currentEvent.contact?.phoneNumber,
      state: currentEvent.contact?.state,
      streetAddress: currentEvent.contact?.streetAddress,
      streetAddressLine2: currentEvent.contact?.streetAddressLine2,
      zipCode: currentEvent.contact?.zipCode,
      note: currentEvent.contact?.note,
      recipes,
      flowersMarkup: markupGroup.flowers,
      suppliesMarkup: markupGroup.supplies,
      plantsMarkup: markupGroup.plants,
      laborFee: markupGroup.laborFee,
      setupFee: markupGroup.setupFee,
      deliveryFee: markupGroup.deliveryFee,
      expirationDate,
      selectedImages,
      tax: currentEvent.tax,
      status: getStatus(currentEvent),
    });
  }, [currentEvent]);

  useEffect(() => {
    if (shouldSend) {
      onSaveHandler();
    }
  }, [shouldSend]);

  const sendProposal = (eventId: number) => {
    setSendingProposal(true);
    axios
      .post(SEND_PROPOSAL_URL, eventId)
      .then(r => {
        dispatch(getEntities({}));
        resetAndClose();
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        setShouldSend(false);
      });
  };

  const resetAndClose = () => {
    navigate(EVENTS_PATH);
  };

  const dataUpdateHandler = (data: any) => {
    const updatedData = { ...eventData, ...data };
    setEventData(updatedData);
  };

  const onSaveHandler = () => {
    const markupGroup: IMarkupGroup = {
      flowers: eventData.flowersMarkup,
      supplies: eventData.suppliesMarkup,
      plants: eventData.plantsMarkup,
      laborFee: eventData.laborFee || 0,
      setupFee: eventData.setupFee || 0,
      deliveryFee: eventData.deliveryFee || 0,
      recipes: eventData.recipes,
    };

    const phone = eventData.phoneNumber || '';
    const phoneNumber = len(phone) === 11 && phone.startsWith('1') ? phone.substring(1) : phone;

    const contact: IContact = {
      id: eventData.contactId,
      firstName: eventData.firstName,
      lastName: eventData.lastName,
      phoneNumber,
      email: eventData.email,
      streetAddress: eventData.streetAddress,
      streetAddressLine2: eventData.streetAddressLine2,
      city: eventData.city,
      state: eventData.state,
      zipCode: eventData.zipCode,
      note: eventData.note,
    };

    const proposals: IProposal[] = [
      {
        imageUrls: eventData.selectedImages && eventData.selectedImages.reduce((urls: string, url: string) => `${urls} ${url}`, '').trim(),
        expirationDate: new Date(eventData.expirationDate).getTime() / 1000,
        htmlPath: currentEvent.proposals && currentEvent.proposals[0].htmlPath,
        pdfPath: currentEvent.proposals && currentEvent.proposals[0].pdfPath,
        publicId: currentEvent.proposals && currentEvent.proposals[0].publicId,
        proposalNumber: currentEvent.proposals && currentEvent.proposals[0].proposalNumber,
      },
    ];

    const event: IEvent = {
      id: eventData.id,
      florist: currentEvent.florist,
      name: eventData.eventName,
      eventDate: eventData.eventDate && dateTimeToDayjs(eventData.eventDate, eventData.eventTime),
      deliveryDate: eventData.deliveryDate && dayjs(eventData.deliveryDate),
      contact,
      markupGroup,
      proposals,
    };

    handleCreateOrUpdate(dispatch, event.id != null ? updateEntity : createEntity, event);
  };

  const handleCreateOrUpdate = async (dispatch: AppDispatch, action: any, event: IEvent) => {
    const result = await dispatch(action(event));

    if (shouldSend) {
      const savedEvent = result.payload.data;
      sendProposal(savedEvent.id);
    }
  };

  const onSendHandler = () => {
    setShouldSend(true);
  };

  const renderFormPage = () => {
    switch (activePage) {
      case EVENT_PAGE:
        return (
          <EventPage
            initialData={eventData}
            onDataUpdate={dataUpdateHandler}
            onGoToPage={setActivePage}
            onCancel={resetAndClose}
            onSave={onSaveHandler}
          />
        );
      case ITEMS_PAGE:
        return <ItemsPage initialData={eventData} onDataUpdate={dataUpdateHandler} onGoToPage={setActivePage} onSave={onSaveHandler} />;
      case PROPOSAL_SUMMARY_PAGE:
        return <ProposalSummaryPage initialData={eventData} onGoToPage={setActivePage} onSave={onSaveHandler} />;
      case GALLERY_AND_EXPIRATION_PAGE:
        return (
          <GalleryAndExpirationPage
            initialData={eventData}
            onDataUpdate={dataUpdateHandler}
            onGoToPage={setActivePage}
            onSend={onSendHandler}
            isSendingProposal={isSendingProposal}
          />
        );
    }
  };

  const renderFormHeader = () => (
    <Row>
      <Col md="2">
        <h4 id="event-heading" data-cy="EventHeading" style={{ textWrap: 'nowrap' }}>
          {`${currentEvent === defaultValue ? 'Create a New Event' : 'Edit Event'} (${pages.indexOf(activePage) + 1}/${len(pages)})`}
        </h4>
      </Col>
    </Row>
  );

  const renderFormContent = () => (
    <div className="form-modal">
      {renderFormHeader()}
      {renderFormPage()}
    </div>
  );

  return isLoading ? <Loading /> : isError ? <NotFound /> : renderFormContent();
};

export default EventForm;
