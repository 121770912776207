import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Col, Row, Table } from 'reactstrap';
import './subscription-management-styles.scss';
import Icon from '@mdi/react';
import { SUBSCRIPTIONS_URL } from 'app/config/url';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mdi, SUBSCRIPTION_CHARGE_PERIOD } from 'app/config/constants';
import { convertDateToUSAFormat } from 'app/shared/util/date-utils';
import ActionButton from 'app/shared/action-buttons/ActionButton';
import Loading from 'app/shared/alert/loading';
import ModalDialog from 'app/shared/layout/dialog/ModalDialog';
import { Color } from 'app/shared/model/enumerations/color.model';

const status = {
  TRIAL: (
    <div className="subscription-status">
      <Icon path={mdi.trial} size={1} className="trial" />
      Trial
    </div>
  ),
  ACTIVE: (
    <div className="subscription-status">
      <Icon path={mdi.active} size={1} className="active" />
      Active
    </div>
  ),
  CANCELED: (
    <div className="subscription-status">
      <Icon path={mdi.canceled} size={1} className="canceled" />
      Canceled
    </div>
  ),
};

const isTrialEnded = (record: any): boolean => {
  const trialStartDate = new Date(record.timestamp * 1000);
  const trialEndDate = new Date(trialStartDate);
  trialEndDate.setDate(trialStartDate.getDate() + SUBSCRIPTION_CHARGE_PERIOD);
  const currentDate = new Date();

  return trialEndDate.getTime() <= currentDate.getTime();
};

const getNextChargeDate = (record: any): Date => {
  const startDate = new Date(record.timestamp * 1000);
  const currentDate = new Date();
  const chargeDate = new Date(startDate);

  do {
    chargeDate.setDate(startDate.getDate() + SUBSCRIPTION_CHARGE_PERIOD);
  } while (chargeDate.getTime() <= currentDate.getTime());

  return chargeDate;
};

const getRemainingDays = (record: any): number => {
  const endDate = getNextChargeDate(record);
  const currentDate = new Date();
  const diff = endDate.getTime() - currentDate.getTime();

  return Math.ceil(diff / (1000 * 60 * 60 * 24));
};

const renderStatus = (record: any) => {
  let result: any;

  if (record.isCanceledEarly) {
    result = status.CANCELED;
  } else if (isTrialEnded(record)) {
    result = status.ACTIVE;
  } else {
    result = status.TRIAL;
  }

  return result;
};

export const SubscriptionManagement = () => {
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [sort, setSort] = useState({ field: 'id', order: 'asc' });
  const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);

  useEffect(() => {
    fetchDataByPage();
  }, [page, sort]);

  const fetchDataByPage = () => {
    setLoading(true);
    axios
      .post(`${SUBSCRIPTIONS_URL}/all`, { fieldName: sort.field, ascending: sort.order === 'asc' }, { params: { page: page - 1 } })
      .then(res => {
        const records = res.data.content;
        setRecords(records);
        setTotalPages(res.data.totalPages);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSort = (fieldName: string) => {
    const isAsc = sort.field === fieldName && sort.order === 'asc';
    setSort({ field: fieldName, order: isAsc ? 'desc' : 'asc' });
  };

  const getSortIcon = (fieldName: string) => (sort.field !== fieldName ? faSort : sort.order === 'asc' ? faSortUp : faSortDown);

  const renewSubscription = record => {
    setLoading(true);
    axios.post(`${SUBSCRIPTIONS_URL}/renew`, record).then(() => {
      fetchDataByPage();
    });
  };

  const cancelSubscription = record => {
    setLoading(true);
    axios.get(`${SUBSCRIPTIONS_URL}/cancel`, { params: { id: record.id } }).then(() => {
      fetchDataByPage();
    });
  };

  const nextPage = () => {
    setPage(prev => prev + 1);
  };

  const prevPage = () => {
    setPage(prev => prev - 1);
  };

  const resetModal = () => {
    setSubscriptionToCancel(null);
  };

  const cancelSubscriptionHandler = () => {
    cancelSubscription(subscriptionToCancel);
    resetModal();
  };

  const renderPageContent = () => (
    <div className="stretch col-md-8">
      <Row className="justify-content-center">
        <Row>
          {!loading && (
            <Col className="justify-content-end">
              <Row className="justify-content-end">
                <Col className="col-md-2">
                  <Button disabled={page === 1} onClick={() => prevPage()}>
                    {'<'}
                  </Button>
                </Col>

                <Col className="col-md-3">
                  <div className="d-flex">
                    Page {page} of {totalPages}
                  </div>
                </Col>

                <Col className="col-md-2">
                  <Button disabled={page === totalPages} onClick={() => nextPage()}>
                    {'>'}
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        <div className="subscriptions-table">
          <Table>
            <thead>
              <tr>
                <th className="hand" onClick={() => handleSort('accountNumber')}>
                  Account ID <FontAwesomeIcon icon={getSortIcon('accountNumber')} />
                </th>
                <th>Business Name</th>
                <th>Remaining days</th>
                <th>Next Billing Date</th>
                <th>Status</th>
                <th className="short-actions" />
                <th className="short-actions" />
              </tr>
            </thead>

            {!loading &&
              (!records || records.length === 0 ? (
                <div className="alert alert-warning">No Records found</div>
              ) : (
                <tbody>
                  {records.map((record, index) => (
                    <tr key={index}>
                      <td>{record.florist.accountNumber}</td>
                      <td>{record.florist.name}</td>
                      <td>{getRemainingDays(record) || '-'}</td>
                      <td>{convertDateToUSAFormat(getNextChargeDate(record))}</td>
                      <td>{renderStatus(record)}</td>
                      <td className="short-actions">
                        <ActionButton
                          icon={mdi.cancel}
                          color={Color.DANGER}
                          disabled={record.isCanceledEarly}
                          name="Cancel"
                          clickHandler={() => setSubscriptionToCancel(record)}
                        />
                      </td>
                      <td className="short-actions">
                        <ActionButton
                          icon={mdi.refresh}
                          disabled={!record.isCanceledEarly}
                          name="Start"
                          clickHandler={() => renewSubscription(record)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              ))}
          </Table>

          {loading && <Loading />}
        </div>
      </Row>
    </div>
  );

  const renderConfirmationDialog = () =>
    subscriptionToCancel && (
      <ModalDialog
        isOpen={true}
        modalText={`Are you sure you want to cancel subscription for account ${subscriptionToCancel.florist.accountNumber}?`}
        toggleHandler={resetModal}
        buttons={[
          {
            label: 'Yes, cancel the subscription',
            color: Color.DANGER,
            clickHandler: cancelSubscriptionHandler,
          },
          {
            label: 'Discard',
            color: Color.SECONDARY,
            outline: true,
            clickHandler: resetModal,
          },
        ]}
      />
    );

  return (
    <>
      {renderPageContent()}
      {renderConfirmationDialog()}
    </>
  );
};
