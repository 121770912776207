import React, { useEffect, useRef, useState } from 'react';
import './note-styles.scss';
import { Input } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiTagPlusOutline, mdiTagTextOutline } from '@mdi/js';
import { Color } from 'app/shared/model/enumerations/color.model';

interface NoteProps {
  text?: string;
  readOnly?: boolean;
  noMargin?: boolean;
  color?: 'none' | Color;
  onSubmit?: (recipe: any) => void;
}

const Note = ({ text, color = Color.PRIMARY, readOnly, noMargin, onSubmit }: NoteProps) => {
  const [currentText, setCurrentText] = useState<string>(text);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditMode) inputRef.current?.focus();
  }, [isEditMode]);

  const renderNote = () => (
    <div className={`note ${color} ${readOnly ? ' read-only' : ''}`}>
      <div>
        <Icon path={mdiTagTextOutline} size={0.75} />
      </div>
      <div>{text}</div>
    </div>
  );

  const changeHandler = e => {
    const value = e.target.value;
    setCurrentText(value);
  };

  const submitHandler = () => {
    setEditMode(false);
    onSubmit(currentText);
  };

  const openEditorHandler = () => {
    if (!readOnly) setEditMode(true);
  };

  const renderEditor = () => (
    <div className="note-editor">
      <Input
        type="text"
        innerRef={inputRef}
        value={currentText}
        onChange={changeHandler}
        onBlur={submitHandler}
        maxLength={255}
        onKeyDown={event => {
          if (event.key === 'Enter') submitHandler();
        }}
      />
    </div>
  );

  const renderPlaceHolder = () => (
    <div className="note-placeholder">
      <div>
        <Icon path={mdiTagPlusOutline} size={0.75} />
      </div>
      <div>Add Note</div>
    </div>
  );

  return (
    (!readOnly || currentText) && (
      <div
        className={`note-container ${readOnly ? 'read-only' : ''} ${noMargin ? 'no-margin' : ''} ${!noMargin && (currentText || isEditMode) ? 'padding' : ''}`}
        onClick={openEditorHandler}
      >
        {isEditMode ? renderEditor() : currentText ? renderNote() : renderPlaceHolder()}
      </div>
    )
  );
};

export default Note;
