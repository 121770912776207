import React from 'react';
import { ISettingsTabProps } from 'app/entities/settings/tabs/tabs-config';
import { Input, Label } from 'reactstrap';

const renderTitle = (title: string) => <div className="form-section-title">{title}</div>;

const renderLabel = (label: string) => <Label for="business-name">{label}</Label>;

const GeneralTab = ({ settingEntries, onChangeSetting }: ISettingsTabProps) => (
  <div className="setting-tab-container">
    {renderTitle('Business Name')}
    <div className="setting-field-container">
      <div className="field">
        <Input
          id="business-name"
          className="short-field"
          placeholder="Business Display Name"
          value={settingEntries ? settingEntries.businessName : ''}
          onChange={e => onChangeSetting({ businessName: e.target.value })}
        />
      </div>

      {renderLabel('Your Business Name as it should appear to customers.')}
    </div>
  </div>
);

export default GeneralTab;
