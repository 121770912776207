import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getFlorists } from 'app/entities/florist/florist.reducer';
import { createEntity, getEntity, reset, updateEntity } from './contact.reducer';
import { CONTACTS_PATH } from 'app/paths';
import '../../shared/layout/Layout.scss';
import { errorsCount, getError, validators } from 'app/util/validation-utils';
import { InputType } from 'reactstrap/types/lib/Input';
import ValidationError from 'app/shared/error/ValidationError';
import { formatters } from 'app/util/format-utils';
import { states } from 'app/config/constants';

const selectOptions = {
  state: states,
};

type FormModel = {
  id?: number;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: number;
};

const contactPageValidators = {
  email: validators.email,
  phoneNumber: validators.phoneNumber,
  firstName: validators.firstName,
  lastName: validators.lastName,
  streetAddress: validators.streetAddress,
  streetAddressLine2: validators.streetAddressLine2,
  city: validators.city,
  state: validators.state,
  zipCode: validators.zipCode,
};

const validateAll = (data: FormModel) => {
  const errors: { [key: string]: string | null } = {};

  (Object.keys(contactPageValidators) as (keyof FormModel)[]).forEach(key => {
    errors[key] = getError(key, data[key]);
  });

  return errors;
};

export const ContactUpdate = () => {
  const [values, setValues] = useState<FormModel>({});
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const contactEntity = useAppSelector(state => state.contact.entity);
  const loading = useAppSelector(state => state.contact.loading);
  const updating = useAppSelector(state => state.contact.updating);
  const updateSuccess = useAppSelector(state => state.contact.updateSuccess);

  const isNew = !id;

  const formattedValue = (id: string) => (formatters[id] ? formatters[id](values[id]) : values[id]);

  useEffect(() => {
    setValues(contactEntity);
  }, [contactEntity]);

  const handleClose = () => {
    navigate(CONTACTS_PATH);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getFlorists({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const addClickHandler = () => {
    touchAll();
    const validationErrors = validateAll(values);
    setErrors(validationErrors);

    if (errorsCount(validationErrors) === 0) {
      saveEntity();
    }
  };

  // eslint-disable-next-line complexity
  const saveEntity = () => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = { ...contactEntity, ...values };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const touchAll = () => {
    const touchedFields = (Object.keys(contactPageValidators) as (keyof FormModel)[]).reduce(
      (obj, field) => ({
        ...obj,
        [field]: field,
      }),
      {},
    );
    setTouched(touchedFields);
  };

  const changeHandler = e => {
    const id = e.target.id;
    const value = e.target.value;
    const updatedValues = { ...values, [id]: value };
    setValues(updatedValues);

    if (touched[id]) {
      validate(id, value);
    }
  };

  const touchHandler = e => {
    const id = e.target.id;
    const updatedValues = { ...touched, [id]: id };
    setTouched(updatedValues);
    validate(id, e.target.value);
  };

  const validate = (id: string, value: any) => {
    const error = getError(id, value);
    setErrors({ ...errors, [id]: error });
  };

  const renderInput = (id: string, type: InputType = 'text') => (
    <Input
      id={id}
      type={type}
      value={formattedValue(id)}
      onChange={changeHandler}
      onBlur={touchHandler}
      invalid={!!(errors[id] && touched[id])}
    />
  );

  const renderSelect = (id: string, label?: string) => {
    const selectedValue = (values[id] && selectOptions[id].includes(formattedValue(id)) && formattedValue(id)) || '';

    return (
      <Input
        id={id}
        type="select"
        value={selectedValue}
        onChange={changeHandler}
        onBlur={touchHandler}
        invalid={!!(errors[id] && touched[id])}
      >
        {!selectedValue && (
          <option value="" disabled style={{ color: 'lightgray', fontSize: '12px' }}>
            Select {label || id}
          </option>
        )}

        {selectOptions[id].map((state: string, i: number) => (
          <option key={i} value={state}>
            {state}
          </option>
        ))}
      </Input>
    );
  };

  const renderField = (id: string, label?: string, type: InputType = 'text') => (
    <FormGroup>
      {type === 'select' ? renderSelect(id, label) : renderInput(id, type)}

      <span>
        {label && <Label for={id}>{label}</Label>}
        <ValidationError id={id} label={label} errors={errors} touched={touched} />
      </span>
    </FormGroup>
  );

  const renderTitle = (title: string) => <div className="form-section-title">{title}</div>;

  return (
    <div className="flori-form">
      <Row className="justify-content-center">
        <h4 id="floriPlannerApp.contact.home.createOrEditLabel" data-cy="ContactCreateUpdateHeading">
          {isNew ? 'Add' : 'Edit'} Contact
        </h4>
      </Row>

      <Row className="justify-content-center">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <Row>
              <Col>
                {renderTitle('Email')}
                {renderField('email')}
              </Col>

              <Col>
                {renderTitle('Phone Number')}
                {renderField('phoneNumber')}
              </Col>
            </Row>

            {renderTitle('Name')}
            <Row>
              <Col>{renderField('firstName', 'First Name')}</Col>
              <Col>{renderField('lastName', 'Last Name')}</Col>
            </Row>

            {renderTitle('Address')}
            {renderField('streetAddress', 'Street Address')}
            {renderField('streetAddressLine2', 'Street Address Line 2')}

            <Row>
              <Col>{renderField('city', 'City')}</Col>
              <Col>{renderField('state', 'State', 'select')}</Col>
              <Col>{renderField('zipCode', 'Postal / Zip Code')}</Col>
            </Row>

            <Row>
              <Col className="text-end">
                <Link to={CONTACTS_PATH}>
                  <Button color="secondary">Cancel</Button>
                </Link>

                <Button color="primary" style={{ marginLeft: '20px' }} onClick={addClickHandler} disabled={updating}>
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Row>
    </div>
  );
};

export default ContactUpdate;
