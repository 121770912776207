import './drawer-styles.scss';
import NavMenu from 'app/shared/layout/menus/nav-menu/NavMenu';
import { getMenuItems } from 'app/shared/layout/menus/nav-menu/nav-menu-items';
import { useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES, mdi } from 'app/config/constants';
import React, { useState, useContext, createContext, useEffect, useRef } from 'react';
import './drawer-styles.scss';
import { eventBus } from 'app/shared/EventBus';
import { DRAWER, HIDE_DRAWER } from 'app/shared/EventBusAction';
import ActionButton from 'app/shared/action-buttons/ActionButton';

interface DrawerProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  closeDrawer?: () => void;
  openDrawer?: () => void;
  setDrawerOpen?: (open: boolean) => void;
}

const DrawerContext = createContext<DrawerProps | null>(null);

export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => setIsOpen(!isOpen);

  const closeDrawer = () => setIsOpen(false);

  const openDrawer = () => setIsOpen(true);

  const setDrawerOpen = (open: boolean) => setIsOpen(open);

  return (
    <DrawerContext.Provider value={{ isOpen, toggleDrawer, closeDrawer, openDrawer, setDrawerOpen }}>{children}</DrawerContext.Provider>
  );
};

const Drawer: React.FC<DrawerProps> = ({ isOpen, toggleDrawer, openDrawer, setDrawerOpen, closeDrawer }) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    const bus = eventBus.subscribe(DRAWER, (name: string) => {
      if (name === HIDE_DRAWER) {
        closeDrawer();
      }
    });

    return () => bus.unsubscribe();
  }, []);

  useEffect(() => {
    setUserRole(isAdmin ? AUTHORITIES.ADMIN : isAuthenticated ? AUTHORITIES.USER : AUTHORITIES.GUEST);
  }, [isAuthenticated, isAdmin]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node) && isOpen) {
        toggleDrawer();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleDrawer]);

  return (
    <>
      <div className={`drawer-overlay ${isOpen ? 'open' : ''}`} onClick={closeDrawer} />

      <div ref={drawerRef} className={`drawer ${isOpen ? 'open' : ''}`}>
        <div className="drawer-title">
          <img className="logo" src="content/images/logo.svg" alt="FloriPlanner Logo" />
          <ActionButton icon={mdi.close} name="Close Menu" clickHandler={closeDrawer} />
        </div>

        <div className="drawer-content">
          <NavMenu items={getMenuItems(userRole, true)} />
        </div>
      </div>
    </>
  );
};

export default Drawer;
