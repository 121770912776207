import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useAppSelector } from 'app/config/store';
import { eventBus } from 'app/shared/EventBus';
import { MODAL, SHOW_PRODUCT_SELECTOR_MODAL } from 'app/shared/EventBusAction';
import { EVENT_PAGE, PROPOSAL_SUMMARY_PAGE } from '../constants';
import { IProduct } from 'app/shared/model/product.model';
import '../event-form-styles.scss';
import { calculateTotals, getCurrentProductDepartments, getMutedMarkupValidators, len, mergeRecipes } from 'app/util/structure-utils';
import ValidationError from 'app/shared/error/ValidationError';
import { asPrice, formatters } from 'app/util/format-utils';
import { getError, validators } from 'app/util/validation-utils';
import { stateCodes } from 'app/config/constants';
import { IRecipe } from 'app/shared/model/recipe.model';
import ProductSelectorModal from './recipe/product-selector/ProductSelectorModal';
import moment from 'moment';
import Recipe from './recipe/Recipe';
import { InputType } from 'reactstrap/types/lib/Input';
import Datepicker from 'app/shared/datepicker/Datepicker';
import { Department } from 'app/shared/model/enumerations/department.model';
import NoItems from 'app/shared/alert/NoItems';
import ModalDialog from 'app/shared/layout/dialog/ModalDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faSave } from '@fortawesome/free-solid-svg-icons';
import { Color } from 'app/shared/model/enumerations/color.model';
import { useWindowWidth } from 'app/shared/hooks/useWindowWidth';
import { screenWidth } from 'app/shared/model/enumerations/screen-modes';

const BACK_PAGE: string = EVENT_PAGE;
const NEXT_PAGE: string = PROPOSAL_SUMMARY_PAGE;

type FormModel = {
  deliveryDate?: Date;
  flowersMarkup?: number;
  suppliesMarkup?: number;
  plantsMarkup?: number;
  laborFee?: number;
  setupFee?: number;
  deliveryFee?: number;
  recipes?: IRecipe[];
};

const itemsPageValidators = {
  deliveryDate: validators.deliveryDate,
  flowersMarkup: validators.flowersMarkup,
  suppliesMarkup: validators.suppliesMarkup,
  plantsMarkup: validators.suppliesMarkup,
  laborFee: validators.laborFee,
  setupFee: validators.setupFee,
  deliveryFee: validators.deliveryFee,
  recipes: validators.recipes,
};

const validateAll = (data: FormModel, ignoredKeys: string[]) => {
  const errors: { [key: string]: string | null } = {};

  (Object.keys(itemsPageValidators) as (keyof FormModel)[]).forEach(key => {
    errors[key] = ignoredKeys.includes(key) ? null : getError(key, data[key]);
  });

  return errors;
};

type ITax = {
  percent: number;
  rate: number;
  stateCode: string;
};

const initialTax: ITax = {
  percent: 0,
  rate: 0,
  stateCode: null,
};

/**
 * Calculate the number of days from now to the selected date.
 * @param {string} selectedDate - The selected date in ISO format (e.g., '2024-06-30').
 * @returns {number} The number of days from now to the selected date.
 */
const calculateDaysToSelectedDate = selectedDate => {
  const now = moment().startOf('day');
  const endDate = moment(selectedDate).startOf('day');

  if (!endDate.isValid()) {
    throw new Error('Invalid date format');
  }

  return endDate.diff(now, 'days');
};

export const renderDashboardSection = (label: string, value: any | null, mode: string = null) => (
  <div className="total-dashboard-section">
    <div className={`total-dashboard-value ${!value ? 'danger-text' : mode || ''}`} style={{ backgroundColor: 'transparent' }}>
      {value || '-'}
    </div>
    <div className="total-dashboard-label">{label}</div>
  </div>
);

const ItemsPage = ({ initialData, onDataUpdate, onGoToPage, onSave }) => {
  const [values, setValues] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState<any>({
    flowersMarkup: 'flowersMarkup',
    suppliesMarkup: 'suppliesMarkup',
    plantsMarkup: 'plantsMarkup',
    laborFee: 'laborFee',
    setupFee: 'setupFee',
    deliveryFee: 'deliveryFee',
  });
  const [tax, setTax] = useState<ITax>(initialTax);
  const [currentRecipe, setCurrentRecipe] = useState<IRecipe>({});
  const [recipeCounter, setRecipeCounter] = useState(1);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [mergedProducts, setMergedProducts] = useState<IProduct[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [recipeToDelete, setRecipeToDelete] = useState<IRecipe>(null);
  const taxes = useAppSelector(state => state.tax.entities);
  const availableDates = useAppSelector(state => state.shipdays.entities);
  const isSaving = useAppSelector(state => state.event.updating);
  const width = useWindowWidth();
  const scrollRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setValues(initialData);
    revalidateMarkups();
  }, [initialData]);

  useEffect(() => {
    if (Object.keys(currentRecipe).length > 0) {
      eventBus.publish(MODAL, SHOW_PRODUCT_SELECTOR_MODAL);
    }
  }, [currentRecipe]);

  useEffect(() => {
    if (shouldScroll) {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }

      setShouldScroll(false);
    }
  }, [shouldScroll]);

  useEffect(() => {
    if (values.state || taxes.length > 0) {
      updateTax();
    }

    validateIfTouched('recipes');
  }, [values.state, taxes, touched, values.recipes, values.flowersMarkup, values.suppliesMarkup, values.plantsMarkup]);

  useEffect(() => {
    updateMutedValidators();
  }, [mergedProducts, values.flowersMarkup, values.suppliesMarkup, values.plantsMarkup, values.recipes]);

  useEffect(() => {
    revalidateMarkups();
  }, [values.mutedValidators]);

  const revalidateMarkups = () => {
    validateIfTouched('flowersMarkup');
    validateIfTouched('suppliesMarkup');
    validateIfTouched('plantsMarkup');
  };

  const validateIfTouched = (id: string) => {
    if (touched[id]) {
      validate(id, values[id]);
    }
  };

  const updateMutedValidators = () => {
    const mutedValidators = getMutedMarkupValidators(values.recipes);
    const updatedValues = { ...values, mutedValidators };
    setValues(updatedValues);
  };

  const updateTax = () => {
    const state = formatters.state(values.state);
    const stateCode = stateCodes[state];
    const percent = taxes[state];
    const rate = percent / 100;
    const data = { stateCode, rate, percent };

    if (tax.stateCode !== stateCode || tax.rate !== rate || tax.percent !== percent) {
      const updatedValues = { ...values, tax: data };
      setTax(data);
      onDataUpdate(updatedValues);
    }
  };

  const formattedValue = (id: string) => (formatters[id] ? formatters[id](values[id]) : values[id]);

  const touchAll = () => {
    const touchedFields = (Object.keys(itemsPageValidators) as (keyof FormModel)[]).reduce(
      (obj, field) => ({
        ...obj,
        [field]: field,
      }),
      {},
    );
    setTouched(touchedFields);
  };

  const touchHandler = e => {
    const id = e.target.id;
    touchId(id);
    validate(id, e.target.value);
  };

  const touchId = (id: string) => {
    const updatedValues = { ...touched, [id]: id };
    setTouched(updatedValues);
  };

  const changeHandler = e => {
    const id = e.target.id;
    const value = e.target.value;
    const updatedValues = { ...values, [id]: value };
    setValues(updatedValues);
    onDataUpdate(updatedValues);

    if (touched[id]) {
      validate(id, value);
    } else {
      touchId(id);
    }
  };

  const validate = (id: string, value: any) => {
    const mutedValidators = values.mutedValidators || [];
    const error = mutedValidators.includes(id) ? null : getError(id, value);
    setErrors(prevErrors => ({ ...prevErrors, [id]: error }));
  };

  const renderField = (id: string, label?: string, type: InputType = 'text', disabled: boolean = false, disabledText?: string) => {
    const input =
      type === 'date' ? (
        <Datepicker
          id={id}
          value={values[id]}
          onChange={changeHandler}
          onBlur={touchHandler}
          invalid={!!(errors[id] && touched[id])}
          placeholderText={label}
          availableDates={availableDates}
          disabled={disabled}
        />
      ) : (
        <Input
          id={id}
          type={type}
          value={formattedValue(id)}
          onChange={changeHandler}
          onBlur={touchHandler}
          invalid={!!(errors[id] && touched[id])}
          min={type === 'number' ? 0 : null}
        />
      );

    return (
      <FormGroup style={{ width: '200px' }}>
        {input}
        <span>
          {label && <Label for={id}>{label}</Label>}
          <ValidationError id={id} label={label} errors={errors} touched={touched} />
          {disabled && <div className="validation-error">{disabledText}</div>}
        </span>
      </FormGroup>
    );
  };

  const toggleProductSelector = (recipe: IRecipe) => {
    setCurrentRecipe(recipe);
  };

  const backClickHandler = () => {
    onGoToPage(BACK_PAGE);
  };

  const nextClickHandler = () => {
    touchAll();
    const mutedValidators = values.mutedValidators || [];
    const validationErrors = validateAll(values, mutedValidators);
    setErrors(validationErrors);
    onGoToPage(NEXT_PAGE);
  };

  const saveClickHandler = () => {
    onSave();
  };

  const renderTitle = (title: string, subtitle: boolean = false) => (
    <div className={`form-section-title ${subtitle ? 'sub' : ''}`}>{title}</div>
  );

  const renderTotals = () => {
    const totals = calculateAllTotals();
    const taxLabel = tax.rate ? `${tax.stateCode} Tax (${tax.percent}%):` : 'Tax:';

    return (
      totals.priceAmount > 0 &&
      !errors['flowersMarkup'] &&
      !errors['suppliesMarkup'] &&
      !errors['plantsMarkup'] && (
        <div className="totals-container">
          <Row>
            <div className="totals-title">Your Client’s Due Amount to You</div>
          </Row>

          <div className="totals">
            <div className="totals-row">
              <div className="totals-label">Cost:</div>
              <div className="totals-value">{asPrice(totals.priceAmount)}</div>
            </div>

            <div className="totals-row">
              <div className="totals-label">Profit:</div>
              <div className="totals-value">{asPrice(totals.markupAmount)}</div>
            </div>

            {!!(totals.laborFeeAmount && totals.laborFeeAmount > 0) && (
              <div className="totals-row">
                <div className="totals-label">Labor Fee:</div>
                <div className="totals-value">{asPrice(totals.laborFeeAmount)}</div>
              </div>
            )}

            {!!(totals.setupFeeAmount && totals.setupFeeAmount > 0) && (
              <div className="totals-row">
                <div className="totals-label">Setup Fee:</div>
                <div className="totals-value">{asPrice(totals.setupFeeAmount)}</div>
              </div>
            )}

            {!!(totals.deliveryFeeAmount && totals.deliveryFeeAmount > 0) && (
              <div className="totals-row">
                <div className="totals-label">Delivery Fee:</div>
                <div className="totals-value">{asPrice(totals.deliveryFeeAmount)}</div>
              </div>
            )}

            <div className="totals-row">
              <div className="totals-label">{taxLabel}</div>
              {tax.rate ? (
                <div className="totals-value">{asPrice(totals.taxAmount)}</div>
              ) : (
                <div className="validation-error">→ State needed</div>
              )}
            </div>

            <div className="totals-row">
              <div className="totals-label">Total:</div>
              {tax.rate ? (
                <div className="totals-value">{asPrice(totals.totalAmount)}</div>
              ) : (
                <div className="validation-error nowrap">→ State needed</div>
              )}
            </div>
          </div>
        </div>
      )
    );
  };

  const changeRecipe = (i: number, recipe: IRecipe) => {
    let recipes = [...values.recipes];

    while (recipes.some((r: IRecipe, index: number) => index !== i && r.name === recipe.name)) {
      recipe.name += '_';
    }

    recipes[i] = recipe;

    const newValues = {
      ...values,
      recipes,
    };

    setValues(newValues);
    onDataUpdate(newValues);
  };

  const deleteRecipeHandler = () => {
    const valuesPrev = values;

    if (valuesPrev.recipes) {
      const updatedRecipes = valuesPrev.recipes.filter((r: IRecipe) => r.name !== recipeToDelete.name);

      const updatedValues = {
        ...valuesPrev,
        recipes: updatedRecipes,
      };

      setValues(updatedValues);
      onDataUpdate(updatedValues);
    }

    resetModal();
  };

  const calculateAllTotals = () => {
    const products = mergeRecipes([
      {
        quantity: 1,
        products:
          values.recipes?.flatMap((recipe: IRecipe) => {
            const products = recipe.products || [];

            return products.map(product => ({ ...product, quantity: product.quantity * recipe.quantity }));
          }) || [],
      },
    ]);

    if (JSON.stringify(mergedProducts) !== JSON.stringify(products)) {
      setMergedProducts(products);
      setDepartments(getCurrentProductDepartments(values.recipes));
    }

    return calculateTotals(
      products,
      tax.rate,
      values.flowersMarkup,
      values.suppliesMarkup,
      values.plantsMarkup,
      values.laborFee,
      values.setupFee,
      values.deliveryFee,
    );
  };

  const renderAddRecipeButton = () => (
    <Button onClick={addRecipe} color="primary" outline style={{ marginBottom: '5px' }}>
      + Add Recipe
    </Button>
  );

  const renderRecipes = () => {
    const recipes = values.recipes;

    return recipes && len(recipes) > 0 ? (
      <div>
        {recipes.map((recipe: IRecipe, i: number) => (
          <>
            {i === len(recipes) - 1 && <div ref={scrollRef} />}

            <Recipe
              key={i}
              recipe={recipe}
              markups={{
                flowers: values.flowersMarkup,
                supplies: values.suppliesMarkup,
                plants: values.plantsMarkup,
              }}
              onRecipeChange={(newRecipe: IRecipe) => changeRecipe(i, newRecipe)}
              onRecipeRemove={() => setRecipeToDelete(recipe)}
              onAddProductClick={() => toggleProductSelector(recipe)}
            />
          </>
        ))}

        {renderTitle('Markup')}
        {len(departments) > 0 ? (
          <Row>
            {departments.includes(Department.FLOWERS) && renderField('flowersMarkup', 'Flowers Markup %', 'number')}
            {departments.includes(Department.SUPPLIES) && renderField('suppliesMarkup', 'Supplies Markup %', 'number')}
            {departments.includes(Department.PLANTS) && renderField('plantsMarkup', 'Plants Markup %', 'number')}
          </Row>
        ) : (
          <NoItems text="Add at least 1 product to set markup" />
        )}

        {renderTitle('Fees')}
        <Row>
          {renderField('laborFee', 'Labor Fee $', 'number')}
          {renderField('setupFee', 'Setup Fee $', 'number')}
          {renderField('deliveryFee', 'Delivery Fee $', 'number')}
        </Row>

        {renderTotals()}

        <ValidationError id={'recipes'} errors={errors} touched={touched} />
      </div>
    ) : (
      <NoItems text="No Recipes Added" />
    );
  };

  const resetModal = () => {
    setRecipeToDelete(null);
  };

  const addRecipe = () => {
    let counter = recipeCounter;

    while (values.recipes.some((r: IRecipe) => r.name === `Recipe ${counter}`)) {
      counter++;
    }

    const newRecipe: IRecipe = {
      name: `Recipe ${counter}`,
      products: [],
      quantity: 1,
    };

    const valuesPrev = values;
    let updatedValues: any;

    if (valuesPrev.recipes) {
      updatedValues = {
        ...valuesPrev,
        recipes: [...valuesPrev.recipes, newRecipe],
      };
    } else {
      updatedValues = {
        ...valuesPrev,
        recipes: [newRecipe],
      };
    }

    setRecipeCounter(++counter);
    setValues(updatedValues);
    setShouldScroll(true);
    onDataUpdate(updatedValues);
  };

  const updateRecipeProducts = (products: { add: IProduct[]; remove: IProduct[] }) => {
    const productsToAdd = products.add;
    const productsToRemove = products.remove;
    const currentProducts = currentRecipe.products || [];
    const newRecipe = {
      ...currentRecipe,
      products: currentProducts
        .filter((p: IProduct) => !productsToRemove.some(toRemove => toRemove.productId === p.productId))
        .concat(productsToAdd),
    };

    const index = values.recipes.indexOf(currentRecipe);
    const recipes = values.recipes;
    recipes[index] = newRecipe;
    const newValues = { ...values, recipes };
    setValues(newValues);
    onDataUpdate(newValues);
  };

  const renderTotalDashboard = () => {
    const totals = calculateAllTotals();
    const hasError = (department: Department, key: string) => departments.includes(department) && (!values[key] || values[key] <= 0);

    const isMarkupError =
      hasError(Department.FLOWERS, 'flowersMarkup') ||
      hasError(Department.SUPPLIES, 'suppliesMarkup') ||
      hasError(Department.PLANTS, 'plantsMarkup');

    return (
      <div className="total-dashboard">
        {renderDashboardSection('Cost', asPrice(totals.priceAmount))}
        {renderDashboardSection('Price', isMarkupError ? null : asPrice(totals.priceAmount + totals.markupAmount))}
        {renderDashboardSection(
          'Profit',
          isMarkupError || (totals.markupAmount && totals.markupAmount < 0) ? null : asPrice(totals.markupAmount),
          totals.markupAmount && totals.markupAmount > 0 && 'primary-text',
        )}
      </div>
    );
  };

  const renderActionFooter = () => (
    <Row className="action-footer">
      <Col md="auto" sm="auto" xs="auto">
        <Button color="secondary" onClick={backClickHandler} disabled={isSaving}>
          <FontAwesomeIcon icon={faChevronLeft} />
          &nbsp; Back
        </Button>
      </Col>

      <Col className="text-end">
        <Button color="secondary" onClick={saveClickHandler} style={{ marginRight: '20px' }} disabled={isSaving}>
          {isSaving ? (
            'Saving...'
          ) : (
            <>
              <FontAwesomeIcon icon={faSave} />
              &nbsp; {width > screenWidth.MAX_NARROW ? 'Save Draft' : 'Save'}
            </>
          )}
        </Button>

        <Button color="primary" onClick={nextClickHandler} disabled={isSaving}>
          Next &nbsp;
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </Col>
    </Row>
  );

  const renderTotalRecipesDashboard = () => (
    <div className="total-recipes-dashboard">
      {renderTitle('Recipes Total')}
      {renderTotalDashboard()}
    </div>
  );

  const renderPageContent = () => (
    <div className="event-form" style={{ paddingTop: 0 }}>
      <Row>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {renderTitle('Delivery Date')}
            <Row>
              <Col>
                <div className="short-field">{renderField('deliveryDate', '', 'date', !values.eventDate, 'Event Date needed')}</div>
              </Col>

              <Col className="justify-start">
                <Row className="total-dashboard">
                  {renderDashboardSection('Days to event', calculateDaysToSelectedDate(values.eventDate))}
                </Row>
              </Col>
            </Row>
          </div>

          {width > screenWidth.MAX_NARROW && renderTotalRecipesDashboard()}
        </div>

        {width <= screenWidth.MAX_NARROW && renderTotalRecipesDashboard()}
      </Row>

      <Row>
        <Col>{renderTitle('Recipes')}</Col>
        <Col className="text-end">{renderAddRecipeButton()}</Col>
      </Row>

      {renderRecipes()}
      {renderActionFooter()}
    </div>
  );

  const renderProductSelector = () => (
    <ProductSelectorModal
      initialSelectedProducts={currentRecipe.products || []}
      onApply={updateRecipeProducts}
      onCancel={() => setCurrentRecipe({})}
    />
  );

  const renderConfirmationDialog = () =>
    recipeToDelete && (
      <ModalDialog
        isOpen={true}
        modalText={`Are you sure you want to delete recipe '${recipeToDelete.name}'?`}
        toggleHandler={resetModal}
        buttons={[
          {
            label: 'Delete',
            color: Color.DANGER,
            clickHandler: deleteRecipeHandler,
          },
          {
            label: 'Cancel',
            color: Color.SECONDARY,
            outline: true,
            clickHandler: resetModal,
          },
        ]}
      />
    );

  return (
    <>
      {renderPageContent()}
      {renderProductSelector()}
      {renderConfirmationDialog()}
    </>
  );
};

export default ItemsPage;
