import { IEvent } from 'app/shared/model/event.model';
import { ProposalStatus } from 'app/shared/model/enumerations/proposal-status.model';

export interface IProposal {
  id?: number;
  proposalNumber?: string | null;
  fileUrl?: string | null;
  htmlPath?: string | null;
  pdfPath?: string | null;
  status?: ProposalStatus | null;
  event?: IEvent | null;
  htmlContent?: string | null;
  publicId?: string | null;
  imageUrls?: string | null;
  expirationDate?: any | null;
  signedAt?: any | null;
  signerName?: any | null;
}

export const defaultValue: Readonly<IProposal> = {};
