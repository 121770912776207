import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from 'reactstrap';
import './timepicker-styles.scss';
import Icon from '@mdi/react';
import { mdiClockOutline } from '@mdi/js';
import { hours24ToTime, ITime, timeTo12hours } from 'app/util/date-time-utils';

const defaultTimeString: string = '--:-- --';
const defaultHoursOptions = [12, ...Array.from({ length: 11 }, (_, i) => i + 1)];
const defaultMinutesOptions = Array.from({ length: 60 }, (_, i) => i + 1);
const defaultTimePreset: ITime = { hours: 12, minutes: 0, am: true };

interface ITimepickerProps {
  id: string;
  value: string;
  disabled?: boolean;
  invalid?: boolean;
  hoursOptions?: number[];
  minutesOptions?: number[];
  defaultTime?: ITime;
  onChange: (value: any) => void;
  onBlur: (value: any) => void;
}

const Timepicker = forwardRef(
  (
    {
      id,
      value,
      disabled = false,
      invalid = null,
      hoursOptions = defaultHoursOptions,
      minutesOptions = defaultMinutesOptions,
      defaultTime = defaultTimePreset,
      onChange,
      onBlur,
    }: ITimepickerProps,
    ref,
  ) => {
    const [time, setTime] = useState<ITime>(null);
    const [timeToDisplay, setTimeToDisplay] = useState<string>(defaultTimeString);
    const [showPicker, setShowPicker] = useState(false);
    const pickerRef = useRef(null);

    useImperativeHandle(ref, () => ({
      openPicker() {
        setShowPicker(true);

        if (!time) {
          setTime(defaultTime);
          submit(getComponentResponse(defaultTime));
        }
      },
      closePicker() {
        setShowPicker(false);
      },
    }));

    useEffect(() => {
      if (value) {
        setTime(hours24ToTime(value));
      }
    }, [value]);

    useEffect(() => {
      if (!time) return;
      setTimeToDisplay(timeTo12hours(time));
    }, [time]);

    const changeHandler = (updatedTime: Partial<ITime>) => {
      const newTime = { ...time, ...updatedTime };
      setTime(newTime);
      submit(getComponentResponse(newTime));
    };

    const getComponentResponse = (time: ITime) => {
      const hours24 = time.am ? time.hours % 12 : (time.hours % 12) + 12;
      const timeString = `${hours24.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}`;

      return { target: { id, value: timeString } };
    };

    const togglePicker = () => {
      setShowPicker(prevShowPicker => !prevShowPicker);

      if (!time) {
        setTime(defaultTime);
        submit(getComponentResponse(defaultTime));
      }
    };

    const submit = (response: any) => {
      if (onChange) onChange(response);
      if (onBlur) onBlur(response);
    };

    const handleClickOutside = (e: any) => {
      if (pickerRef.current && !pickerRef.current.contains(e.target)) {
        setShowPicker(false);
      }
    };

    useEffect(() => {
      if (showPicker) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [showPicker]);

    const renderSection = (title: string, options: any[], key: string, getValueFunction: any) => (
      <div className="timepicker-section">
        <div className="section-title">{title}</div>
        {options.map(option => (
          <div
            key={option}
            className={`timepicker-option ${time && time[key] === getValueFunction(option) ? 'selected' : ''}`}
            onClick={() => changeHandler({ [key]: getValueFunction(option) })}
          >
            {option}
          </div>
        ))}
      </div>
    );

    const renderHoursPicker = () => renderSection('Hours', hoursOptions, 'hours', (value: number) => value);

    const renderMinutesPicker = () => renderSection('Minutes', minutesOptions, 'minutes', (value: number) => value);

    const renderAmPmPicker = () => renderSection(null, ['AM', 'PM'], 'am', (value: string) => value === 'AM');

    const renderPicker = () => (
      <div className="timepicker-dropdown">
        {renderHoursPicker()}
        {renderMinutesPicker()}
        {renderAmPmPicker()}
      </div>
    );

    return (
      <div className="timepicker-container" ref={pickerRef}>
        <div className="timepicker-input">
          <Input type="text" value={timeToDisplay} readOnly onClick={togglePicker} invalid={invalid} disabled={disabled} />
          <div onClick={!disabled ? togglePicker : null}>
            <Icon
              path={mdiClockOutline}
              className={`timepicker-input-icon ${invalid ? 'invalid-value' : ''}`}
              size={0.75}
              style={{ cursor: disabled ? 'default' : 'pointer' }}
            />
          </div>
        </div>
        {showPicker && renderPicker()}
      </div>
    );
  },
);

export default Timepicker;
