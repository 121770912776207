import React from 'react';
import ModalDialog from 'app/shared/layout/dialog/ModalDialog';
import { useNavigate } from 'react-router-dom';
import { AGREEMENT_SETTINGS_PATH, SETTINGS_PATH } from 'app/paths';
import { Color } from 'app/shared/model/enumerations/color.model';

const Agreement = ({ onDone }) => {
  const navigate = useNavigate();

  const goToSettingsHandler = () => {
    navigate(`${SETTINGS_PATH}/${AGREEMENT_SETTINGS_PATH}`);
    onDone();
  };

  return (
    <ModalDialog
      isOpen={true}
      modalTitle="Your Contract"
      modalText={
        <div style={{ padding: '0 0 20px' }}>
          To set your contract requirements between you and your clients, please click on <b>Settings</b> → <b>Terms and Conditions</b>.
        </div>
      }
      buttons={[
        {
          label: 'Go to Settings',
          color: Color.PRIMARY,
          clickHandler: goToSettingsHandler,
        },
        {
          label: "Ok, I'll do it later",
          color: Color.PRIMARY,
          outline: true,
          clickHandler: onDone,
        },
      ]}
    />
  );
};

export default Agreement;
