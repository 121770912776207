import PersonalizedEditor from 'app/entities/settings/PersonalizedEditor';
import React from 'react';
import { ISettingsTabProps } from 'app/entities/settings/tabs/tabs-config';

const CoverLetterTab = ({ settingEntries, onChangeSetting }: ISettingsTabProps) => (
  <div className="setting-tab-container">
    <PersonalizedEditor
      setting={settingEntries ? settingEntries.coverLetter : ''}
      title="Personalize your proposal by clicking on the buttons to insert details into the text."
      onChange={(text: string) => onChangeSetting({ coverLetter: text })}
    />
  </div>
);

export default CoverLetterTab;
