import React from 'react';
import Icon from '@mdi/react';
import './hint-styles.scss';
import { Color } from 'app/shared/model/enumerations/color.model';

interface HintProps {
  icon: string;
  iconSize?: number;
  color: 'none' | Color;
  layout?: 'horizontal' | 'vertical';
  children?: React.ReactNode;
}

const Hint = ({ icon, iconSize = 1.5, color, layout = 'horizontal', children }: HintProps) => (
  <div className={`hint ${color} ${layout}`}>
    <div className="hint-icon">
      <Icon path={icon} size={iconSize} />
    </div>

    <div className="hint-content">{children}</div>
  </div>
);

export default Hint;
