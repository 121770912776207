import { FILENAME_FILTER_REGEX } from 'app/util/regexs-constants';
import { stateCodes } from 'app/config/constants';

/**
 * Formats a value as a price string in currency format.
 *
 * @param {*} value - The value to format as a price <i>(e.g. 1234.5)</i>.
 *
 * @returns {string} - The formatted price string in currency format <i>($1,234.50 for the example case)</i>.
 */
export const asPrice = (value: number | string): string => {
  return `$${Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

/**
 * Rounds the given value to two decimal places.
 *
 * @param {number|string} value - The value to be rounded.
 * @returns {number} The rounded value.
 */
export const roundPrice = (value: number | string): number =>
  value === null || value === undefined ? 0 : Math.round(Number(value) * 100) / 100;

/**
 * Formats the given value to a date input format.
 *
 * @param {any} value - The value to be formatted.
 * @returns {any} - The formatted date string or the original value if it is not a Date object.
 */
const formatDateForDateInput = (value: any): any => (value instanceof Date ? value.toISOString().split('T')[0] : value);

/**
 * Removes all non-numeric characters from a given string or number.
 * If the input is a number, it will be converted to a string before processing.
 *
 * @param {string|number} string - The input string or number.
 * @returns {string} - The resulting string with non-numeric characters removed.
 */
export const extractNumbers = (string: string | number): string => (string ? string.toString().replace(/\D/g, '') : '');

/**
 * Holds a collection of variable formatters.
 * Each formatter is a function that takes a value and returns a formatted version of that value.
 *
 * @typedef {Object} Formatters
 */
export const formatters = {
  eventDate: formatDateForDateInput,
  deliveryDate: formatDateForDateInput,
  expirationDate: formatDateForDateInput,
  state: (value: any) =>
    value &&
    value
      .toString()
      .split(' ')
      .map((word: string) => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
      .join(' '),
  price: asPrice,
};

/**
 * <p>
 * Extracts the file name from the given URL.
 * </p>
 * <p>
 * This method uses a regular expression to find the file name in the URL string.
 * </p>
 *<p>
 * <b>Usage example</b>
 *</p>
 * <pre>
 * String url1 = "http://foo.com/bar/file.jpg?par1=val1&par2=val2";
 * String url2 = "https://foo.com/file.jpg?param=value";
 * String url3 = "https://foo.com/foo@bar.com/file.jpg";
 *
 * System.out.println(extractFileName(url1)); // > file.jpg
 * System.out.println(extractFileName(url2)); // > file.jpg
 * System.out.println(extractFileName(url3)); // > file.jpg
 * </pre>
 *
 * @param url the URL string from which to extract the file name.
 * @return the file name if found in the URL, otherwise null.
 */
export const extractFileName = (url: string): string | null => {
  const match = url.match(FILENAME_FILTER_REGEX);

  if (match && match[1]) {
    return match[1];
  }

  return null;
};

/**
 * Gets the state name by its code.
 *
 * @param {string} code - The code of the state (e.g. CA).
 *
 * @return {string} The state name corresponding to the code (California, respectively).
 */
export const getStateByCode = (code: string): string =>
  Object.entries(stateCodes)
    .filter(([stateName, stateCode]) => stateCode === code)
    .map(([stateName, stateCode]) => stateName)[0];

/**
 * Formats the given phone number to the format "(XXX) XXX-XXXX"
 *
 * @param {string} number - The phone number to be formatted.
 *
 * @returns {string} The formatted phone number.
 */
export const formatPhoneNumber = (number: string): string => {
  let result = '';

  if (number) {
    let cleaned = number.startsWith('+1') ? number.substring(2) : number.startsWith('1') ? number.substring(1) : number;
    cleaned = cleaned.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      result = `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  }

  return result;
};
