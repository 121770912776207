import React from 'react';
import './product-filter-styles.scss';

const filterOptions = {
  FLW: 'Flowers',
  PLT: 'Plants',
  SUP: 'Supplies',
};

const DEFAULT_OPTION: string = 'All';
export type FilterKeys = keyof typeof filterOptions;

interface ProductFilterProps {
  value: FilterKeys | null;
  onChangeFilter: (filter: string | null) => void;
}

const optionsToDisplay = [DEFAULT_OPTION, ...Object.values(filterOptions)];

const ProductFilter = ({ value, onChangeFilter }: ProductFilterProps) => {
  const onFilterClick = (filterTitle: string) => {
    const matchedFilter = Object.entries(filterOptions).find(entry => entry[1] === filterTitle);
    const filter = (matchedFilter && matchedFilter[0]) || null;
    onChangeFilter(filter);
  };

  const isCurrent = (option: string) => (!filterOptions[value] && option === DEFAULT_OPTION) || option === filterOptions[value];

  return (
    <div className="product-filter-container">
      {optionsToDisplay.map((option: string, i: number) => (
        <div key={i} className={`product-filter ${isCurrent(option) ? 'current-filter' : ''}`} onClick={() => onFilterClick(option)}>
          {option}
        </div>
      ))}
    </div>
  );
};

export default ProductFilter;
