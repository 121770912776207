// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container .dropdown-menu,
.image-container .dropdown-menu-end,
.image-container .show {
  transform: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/gallery/gallery-styles.scss"],"names":[],"mappings":"AACE;;;EAGE,2BAAA;AAAJ","sourcesContent":[".image-container {\n  .dropdown-menu,\n  .dropdown-menu-end,\n  .show {\n    transform: unset !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
