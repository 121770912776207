import React from 'react';
import './order-history-styles.scss';
import { IOrderHistory } from 'app/shared/model/order-history.model';
import { asPrice } from 'app/util/format-utils';
import { Table } from 'reactstrap';

interface OrderHistoryProps {
  order: IOrderHistory;
}

const OrderHistory = ({ order }: OrderHistoryProps) =>
  order && (
    <div className="order-history-container">
      <Table>
        <thead>
          <tr>
            <th className="product-dsc">Product</th>
            <th className="product-price">Price</th>
            <th className="product-qty">Qty</th>
            <th className="product-amt">Amount</th>
          </tr>
        </thead>

        <tbody>
          {order.products.map((product, index) => (
            <tr key={index}>
              <td className="product-dsc">{product.productDescription}</td>
              <td className="product-price">{asPrice(product.unitPrice)}</td>
              <td className="product-qty">{product.quantity}</td>
              <td className="product-amt">{asPrice(product.unitPrice * product.quantity)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

export default OrderHistory;
