import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdi } from 'app/config/constants';
import { len } from 'app/util/structure-utils';
import { asPrice } from 'app/util/format-utils';

export interface IStats {
  current: any;
  past?: any;
}

export interface IData {
  title: string;
  value: string | number;
}

interface DashboardSectionProps {
  title: string;
  periods?: any;
  currentData: any;
  pastData?: any;
  border?: boolean;
  onPeriodChange?: (period: number) => void;
}

const BADGE_DISPLAY_PERCENT_THRESHOLD: number = 5000;

const DashboardSection = ({ title, periods, currentData, pastData, border, onPeriodChange }: DashboardSectionProps) => {
  const [selectedPeriod, setSelectedPeriod] = useState<number>(null);

  useEffect(() => {
    if (selectedPeriod === null && periods && len(periods) > 0) {
      changePeriodHandler(Number(Object.keys(periods)[0]));
    }
  }, [periods]);

  const changePeriodHandler = (period: number | string) => {
    if (!onPeriodChange) return;

    const numPeriod = Number(period);

    if (numPeriod !== selectedPeriod) {
      onPeriodChange(numPeriod);
      setSelectedPeriod(numPeriod);
    }
  };

  const renderBadge = (current: number, past: number) => {
    if (past === undefined || past === null || past === current) return;

    let isGrowth = true;
    let change = 0;

    if (past !== 0 && past !== current) {
      const changeAmount: number = current - past;
      change = Math.round((changeAmount / past) * 100);
      isGrowth = change > 0;
    }

    return (
      <div className={`change-badge ${isGrowth ? 'pos' : 'neg'}`} title="Compared to the previous period">
        {change < BADGE_DISPLAY_PERCENT_THRESHOLD ? (
          <div className="value">{`${isGrowth ? '+' : '-'}${Math.abs(change)}`}%</div>
        ) : (
          <Icon path={isGrowth ? mdi.growth : mdi.fall} size={0.6} />
        )}
      </div>
    );
  };

  const renderCard = (key: string, data: IData) => {
    const title = data.title;
    const value = data.value;
    const isMoney = key.toLowerCase().includes('amount');

    return (
      <div className={`dashboard-card ${!value || value === 0 ? 'empty' : ''}`}>
        <div className={`card-value ${value === 0 ? 'empty' : ''}`}>{`${isMoney ? `${asPrice(value)}` : value}`}</div>

        <div className="card-name">
          {title}
          {renderBadge(Number(value), pastData?.[key].value)}
        </div>
      </div>
    );
  };

  return (
    <div className={`dashboard-section ${border ? 'section-border' : ''}`}>
      <div className="section-header">
        <div className="dashboard-title">{title}</div>

        {periods && (
          <div className="period-container">
            {Object.entries(periods).map(([period, title]: [string, string]) => (
              <div
                className={`period-option ${period && Number(period) === selectedPeriod ? 'selected' : ''}`}
                onClick={() => changePeriodHandler(period)}
              >
                {title}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="cards-container">{Object.entries(currentData).map(([key, data]: [string, IData]) => renderCard(key, data))}</div>
    </div>
  );
};

export default DashboardSection;
