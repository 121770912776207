import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import React, { useState } from 'react';
import { saveSettings } from 'app/entities/settings/settings.reducer';
import { useAppDispatch } from 'app/config/store';
import { DEFAULT_COVER_LETTER } from 'app/config/constants';

const DisplayName = ({ onDone }) => {
  const [name, setName] = useState('');
  const dispatch = useAppDispatch();

  const submitNameHandler = () => {
    const settings = {
      settingEntries: {
        businessName: name || '',
        coverLetter: DEFAULT_COVER_LETTER,
      },
    };
    dispatch(saveSettings(settings));
    onDone();
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader>Display Name</ModalHeader>
      <ModalBody>
        <div style={{ paddingBottom: '30px' }}>Enter your Business Name as it should appear to customers.</div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ paddingBottom: '30px', display: 'flex', flexDirection: 'row', width: '75%' }}>
            <Input
              value={name}
              style={{ flex: 1 }}
              onChange={e => setName(e.target.value)}
              onKeyDown={event => {
                if (event.key === 'Escape') {
                  onDone();
                } else if (event.key === 'Enter') {
                  submitNameHandler();
                }
              }}
            />
            <Button style={{ marginLeft: '20px' }} color="primary" onClick={submitNameHandler}>
              Apply
            </Button>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Row style={{ width: '100%' }}>
          <label style={{ textAlign: 'start' }}>
            You may leave it empty to use your legal business name. <br />
            It can be changed in Settings.
          </label>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default DisplayName;
