import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { USER_SETTINGS_URL } from 'app/config/url';
import { IFlorist } from 'app/shared/model/florist.model';

interface ISettings {
  id?: number;
  settingEntries?: any;
  florist?: IFlorist;
}

const initialState: EntityState<ISettings> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  updateSuccess: false,
};

const apiUrl = USER_SETTINGS_URL;

// Actions

export const getSettings = createAsyncThunk('settings/fetch_settings', async () => {
  const response = await axios.get<ISettings>(apiUrl);
  return response.data;
});

export const saveSettings = createAsyncThunk(
  'settings/save_settings',
  async (settings: ISettings, thunkAPI) => {
    const response = await axios.post<ISettings>(apiUrl, settings);

    if (response.status === 200) {
      thunkAPI.dispatch(getSettings());
    }

    return response.data;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const SettingsSlice = createEntitySlice({
  name: 'settings',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(saveSettings.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload;
      })
      .addMatcher(isPending(getSettings), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(saveSettings), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getSettings, saveSettings), state => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = true;
      });
  },
});

export const { reset } = SettingsSlice.actions;

// Reducer
export default SettingsSlice.reducer;
