export const API: string = 'api/v1';

export const GALLERY_URL: string = `${API}/gallery`;
export const PICTURE_URL = (pictureKey: string): string => `${GALLERY_URL}/picture/${pictureKey}`;
export const CONTACTS_URL: string = `${API}/contacts`;
export const EVENTS_URL: string = `${API}/events`;
export const EVENT_PAYMENTS_URL: string = `${API}/event-payments`;
export const FLORISTS_URL: string = `${API}/florists`;
export const MARKUP_GROUPS_URL: string = `${API}/markup-groups`;
export const SEARCH_URL: string = `${API}/search`;
export const PROPOSAL_URL: string = `${API}/proposal`;
export const RECIPES_URL: string = `${API}/recipes`;
export const RECIPE_ITEMS_URL: string = `${API}/recipe-items`;
export const TAX_URL: string = `${API}/tax`;
export const USER_SETTINGS_URL: string = `${FLORISTS_URL}/settings`;
export const SUBSCRIPTIONS_URL: string = `${API}/subscription`;
export const SHIPMENT_URL: string = `${API}/shipment`;
export const ORDER_URL: string = `${API}/order`;
export const ORDER_HISTORY_URL: string = `${ORDER_URL}/history`;
export const SEND_PROPOSAL_URL: string = `${EVENTS_URL}/send-proposal`;
export const RESET_PASSWORD_URL: string = `${API}/reset-password`;
export const SUBSCRIPTION_CONFIG_URL: string = `${SUBSCRIPTIONS_URL}/config`;
export const COMMUNICATION_URL: string = `${API}/communication`;
export const SEND_EMAIL_URL: string = `${COMMUNICATION_URL}/send-email`;
export const PRODUCT_CUSTOM_NAMES_URL: string = `${API}/custom-product-names`;
export const STATISTICS_URL: string = `${API}/statistics`;
export const ACCOUNTS_DATA_URL: string = `${STATISTICS_URL}/admin/accounts`;
export const ALL_PROPOSALS_DATA_URL: string = `${STATISTICS_URL}/admin/proposals`;
export const FLORIST_PROPOSALS_DATA_URL: string = `${STATISTICS_URL}/proposals`;
