import React, { useState } from 'react';
import NavItem from 'app/shared/layout/menus/nav-menu/NavItem';
import Communication from 'app/shared/layout/menus/nav-menu/communication/Communication';
import { eventBus } from 'app/shared/EventBus';
import { DRAWER, HIDE_DRAWER } from 'app/shared/EventBusAction';

export const SEPARATOR = 'separator';

export enum NavMenuHandler {
  CONTACT_US = 'CONTACT_US',
}

export default ({ items }) => {
  const [isCommunicationVisible, setCommunicationVisible] = useState(false);

  const clickHandler = {
    [NavMenuHandler.CONTACT_US]: () => setCommunicationVisible(true),
  };

  return (
    <div className="nav-menu-container">
      {items.map((item: any, i: number) =>
        item === SEPARATOR ? (
          <div key={i} className="menu-separator" />
        ) : (
          <div
            key={i}
            onClick={() => {
              const handler = clickHandler[item.clickHandler];
              handler && handler();
              eventBus.publish(DRAWER, HIDE_DRAWER);
            }}
          >
            <NavItem key={i} name={item.name} path={item.path} icon={item.icon} />
          </div>
        ),
      )}

      {isCommunicationVisible && <Communication onToggle={() => setCommunicationVisible(false)} />}
    </div>
  );
};
