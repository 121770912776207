import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ACCOUNTS_DATA_URL, ALL_PROPOSALS_DATA_URL } from 'app/config/url';
import './stats-dashboard-styles.scss';
import { Col, Row } from 'reactstrap';
import DashboardSection, { IData, IStats } from './dashboard-components/DashboardSection';

const defaultAccountsData: Record<string, IData> = {
  total: {
    title: 'Total',
    value: 0,
  },
  trial: {
    title: 'Trial',
    value: 0,
  },
  business: {
    title: 'Business',
    value: 0,
  },
  canceled: {
    title: 'Canceled',
    value: 0,
  },
};

const defaultProposalsData: Record<string, IData> = {
  total: {
    title: 'Total Sent',
    value: 0,
  },
  pending: {
    title: 'Pending',
    value: 0,
  },
  approved: {
    title: 'Approved',
    value: 0,
  },
  ordered: {
    title: 'Ordered',
    value: 0,
  },
  totalOrderAmount: {
    title: 'Ordered Amt.',
    value: 0,
  },
};

const AdminDashboard = () => {
  const [accounts, setAccounts] = useState(defaultAccountsData);
  const [proposals, setProposals] = useState<IStats>({
    current: defaultProposalsData,
    past: defaultProposalsData,
  });

  useEffect(() => {
    fetchAccountsData();
  }, []);

  const fetchAccountsData = () => {
    axios.get(ACCOUNTS_DATA_URL).then(r => {
      const data = r.data;
      const accountsDataToSet: Record<string, IData> = {
        total: {
          title: 'Total',
          value: data.totalAccounts,
        },
        trial: {
          title: 'Trial',
          value: data.trialAccounts,
        },
        business: {
          title: 'Business',
          value: data.businessAccounts,
        },
        canceled: {
          title: 'Canceled',
          value: data.canceledAccounts,
        },
      };
      setAccounts(accountsDataToSet);
    });
  };

  const fetchProposalsData = (period: number) => {
    axios.get(ALL_PROPOSALS_DATA_URL, { params: { period } }).then(r => {
      const data = r.data;
      const current = extractProposalData(data, 'current');
      const past = extractProposalData(data, 'past');
      setProposals({ current, past });
    });
  };

  const extractProposalData = (data: any, key: string): Record<string, IData> => ({
    total: {
      title: 'Total Sent',
      value: data[key].total,
    },
    pending: {
      title: 'Pending',
      value: data[key].pending,
    },
    approved: {
      title: 'Approved',
      value: data[key].approved,
    },
    ordered: {
      title: 'Ordered',
      value: data[key].ordered,
    },
    totalOrderAmount: {
      title: 'Ordered Amt.',
      value: data[key].totalOrderAmount,
    },
  });

  return (
    <div className="stats-dashboard-container">
      <Row>
        <Col md={4}>
          <DashboardSection title="Accounts" currentData={accounts} border />
        </Col>

        <Col>
          <DashboardSection
            title="Proposals"
            currentData={proposals.current}
            pastData={proposals.past}
            periods={{
              30: '30 days',
              90: '90 days',
              365: 'YTD',
            }}
            border
            onPeriodChange={fetchProposalsData}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboard;
