import { parse, isValid } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { convertDateToUSAFormat } from 'app/shared/util/date-utils';
import { EXPIRES_LATEST_DAYS_BEFORE_EVENT } from 'app/config/constants';

/**
 * Represents the options for formatting a DateTime object.
 * @interface
 */
interface DateTimeFormatOptions {
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
  day?: 'numeric' | '2-digit';
  year?: 'numeric' | '2-digit';
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
  hour12?: boolean;
}

/**
 * Converts a given date to a formatted US date and time string.
 *
 * @param {any} date - The date to be converted.
 * @returns {string} - The formatted US date and time string.
 */
export const toUsaDateTime = (date: any): string => {
  const options: DateTimeFormatOptions = {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };

  const dateInMilliseconds = date * 1000;
  return new Date(dateInMilliseconds).toLocaleString('en-US', options);
};

/**
 * Converts the given date to USA date format.
 *
 * @param {any} date - The date to be converted.
 * @returns {string} The date in USA format (MM/DD/YY).
 */
export const toUsaDate = (date: any): string => {
  const options: DateTimeFormatOptions = {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  };

  const dateInMilliseconds = date * 1000;

  return new Date(dateInMilliseconds).toLocaleString('en-US', options);
};

/**
 * Parses a string into a JavaScript Date object based on a specific time format.
 * If the parsed date is valid, it returns the Date object; otherwise, it returns null.
 *
 * @param {string} timeString - The time string to be parsed.
 * @returns {Date | null} The parsed Date object or null if parsing failed.
 */
export const dateFromTimeString = (timeString: string): Date | null => {
  const timeFormat = 'HH:mm';
  const parsedDate = parse(timeString, timeFormat, new Date());

  return isValid(parsedDate) ? parsedDate : null;
};

/**
 * Combines a given date and time into a single Dayjs object.
 *
 * @param {Date} date - The date to combine.
 * @param {string} time - The time to combine in the format 'HH:mm'.
 * @returns {Dayjs} - The Dayjs object with the combined date and time.
 */
export const dateTimeToDayjs = (date: Date, time: string): Dayjs => {
  let result: Dayjs = dayjs(date);

  if (time) {
    const [hours, minutes] = time.split(':').map(Number);
    result = result.hour(hours).minute(minutes);
  }

  return result;
};

/**
 * Calculate the last expiration date in ISO format based on the given event date.
 *
 * @param {string} eventDate - The event date in the format 'YYYY-MM-DD'.
 * @returns {string} The last expiration date in ISO format 'YYYY-MM-DD'.
 */
export const getLastExpirationDateISO = (eventDate: string): string => {
  if (!eventDate) return;

  const date = new Date(eventDate);
  const future = new Date(date);
  future.setDate(date.getDate() - EXPIRES_LATEST_DAYS_BEFORE_EVENT);

  return dateToISO(future);
};

/**
 * Converts an ISO string representation of a date to a UTC Date object.
 *
 * @param {string} dateString - The ISO string representation of the date (YYYY-MM-DD).
 * @returns {Date} - The converted UTC Date object.
 */
export const dateFromISO = (dateString: string): Date => {
  if (!dateString) return;

  const parts = dateString.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[2], 10);

  return new Date(year, month, day);
};

/**
 * Converts a given date to ISO format.
 *
 * @param {Date} date - The date to be converted.
 * @returns {string} The date in ISO format (YYYY-MM-DD).
 */
export const dateToISO = (date: Date): string => {
  const ensure2Digits = (value: number | string): number | string => (value.toString().length === 2 ? value : `0${value}`);

  const year = date.getFullYear();
  const month = ensure2Digits(date.getMonth() + 1);
  const day = ensure2Digits(date.getDate());

  return `${year}-${month}-${day}`;
};

/**
 * Converts a date in ISO format to USA format.
 *
 * @param {string} iso - The date in ISO format (YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss.sssZ).
 * @returns {string} The converted date in USA format (MM/DD/YYYY).
 */
export const ISOtoUSA = (iso: string): string => iso && convertDateToUSAFormat(dateFromISO(iso));

/**
 * Converts a timestamp to ISO format (yyyy-MM-dd).
 *
 * @param {number} timestamp - The timestamp to convert.
 * @returns {string} The timestamp in ISO format (yyyy-MM-dd).
 */
export const timeStampToISO = (timestamp: number): string => {
  if (!timestamp) return;

  const date = timestampToDate(timestamp);
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

/**
 * Extracts the time from a given timestamp and returns it as a formatted string.
 *
 * @param {number} timestamp - The timestamp to extract the time from.
 * @returns {string} The time extracted from the timestamp as a formatted string.
 */
export const extractTimeFromTimestamp = (timestamp: number): string =>
  timestamp && timestampToDate(timestamp).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

/**
 * Converts a UNIX timestamp to a JavaScript Date object.
 *
 * @param {number} timestamp - The UNIX timestamp to convert.
 * @returns {Date} - The corresponding Date object.
 */
const timestampToDate = (timestamp: number): Date => timestamp && new Date(timestamp * 1000);

/**
 * Represents time in 12-hour format.
 */
export interface ITime {
  hours: number;
  minutes: number;
  am: boolean;
}

/**
 * Converts a 24-hour time string to a formatted time object.
 *
 * @param {string} time24 - The 24-hour time string (e.g. "23:45")
 * @return {ITime} - The formatted time object with hours, minutes, and am properties
 */
export const hours24ToTime = (time24: string): ITime => {
  const hours24 = Number(time24.split(':')[0]);
  const minutes = Number(time24.split(':')[1]);
  const am = hours24 < 12;
  const hours = hours24 % 12 === 0 ? 12 : hours24 % 12;

  return { hours, minutes, am };
};

/**
 * Converts a given time object to 12-hour format string.
 *
 * @param {ITime} time - The time object to be converted.
 * @returns {string} The time in 12-hour format.
 */
export const timeTo12hours = (time: ITime): string => {
  let { hours, minutes, am } = time;
  hours = hours % 12;
  hours = hours ? hours : 12;
  const ampm = am ? 'AM' : 'PM';
  const hoursStr = hours.toString();
  const minutesStr = minutes.toString().padStart(2, '0');

  return `${hoursStr}:${minutesStr} ${ampm}`;
};

/**
 * Convert a 24-hour time format string to a 12-hour time format string.
 *
 * @param {string} time - The time string in 24-hour format (e.g. "23:45").
 * @returns {string} - The time string in 12-hour format (e.g. "11:45 PM").
 */
export const time24To12 = (time: string): string => timeTo12hours(hours24ToTime(time));
