import React from 'react';

import { Alert } from 'reactstrap';

const RedAlert = ({ text }) => {
  return (
    <div>
      <Alert color="danger">{text}</Alert>
    </div>
  );
};

export default RedAlert;
