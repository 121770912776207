import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './contact.reducer';
import { CONTACTS_PATH, EDIT_PATH } from 'app/paths';

export const ContactDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const contactEntity = useAppSelector(state => state.contact.entity);
  return (
    <Row>
      <Col>
        <h4 data-cy="contactDetailsHeading">Contact</h4>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">Id</span>
          </dt>
          <dd>{contactEntity.id}</dd>
          <dt>
            <span id="firstname">First Name</span>
          </dt>
          <dd>{contactEntity.name}</dd>
          <dt>
            <span id="lastName">Last Name</span>
          </dt>
          <dd>{contactEntity.lastName}</dd>
          <dt>
            <span id="email">Email</span>
          </dt>
          <dd>{contactEntity.email}</dd>
          <dt>
            <span id="phoneNumber">Phone Number</span>
          </dt>
          <dd>{contactEntity.phone}</dd>
          <dt>
            <span id="note">Note</span>
          </dt>
          <dd>{contactEntity.note}</dd>
          <dt>Florist</dt>
          <dd>{contactEntity.florist ? contactEntity.florist.id : ''}</dd>
        </dl>
        <Button tag={Link} to={CONTACTS_PATH} replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`${CONTACTS_PATH}/${contactEntity.id}/${EDIT_PATH}`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default ContactDetail;
