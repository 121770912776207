import { MergedEventStatus } from 'app/shared/model/enumerations/merged-event-statuses';

export enum ProposalStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  DRAFT = 'DRAFT',
}

export const getProposalStatus = (status: ProposalStatus): string => MergedEventStatus[status];
