import { AUTHORITIES, mdi } from 'app/config/constants';
import {
  CONTACTS_PATH,
  EVENTS_PATH,
  GALLERY_PATH,
  LOGOUT_PATH,
  SETTINGS_PATH,
  SUBSCRIPTION_PATH,
  SUBSCRIPTIONS_MANAGEMENT_PATH,
} from 'app/paths';
import { NavMenuHandler, SEPARATOR } from 'app/shared/layout/menus/nav-menu/NavMenu';

const events = {
  name: 'Events',
  path: EVENTS_PATH,
  icon: mdi.calendar,
};

const reports = {
  name: 'Reports',
  path: '/reports',
  icon: mdi.chart,
};

const contacts = {
  name: 'Contacts',
  path: CONTACTS_PATH,
  icon: mdi.contactCard,
};

const catalog = {
  name: 'Catalog',
  path: '/catalog',
  icon: mdi.catalog,
};

const settings = {
  name: 'Settings',
  path: SETTINGS_PATH,
  icon: mdi.settings,
};

const gallery = {
  name: 'Gallery',
  path: GALLERY_PATH,
  icon: mdi.image,
};

const subscriptionsManagement = {
  name: 'Subscriptions',
  path: SUBSCRIPTIONS_MANAGEMENT_PATH,
  icon: mdi.statusList,
};

const contactUs = {
  name: 'Contact Us',
  icon: mdi.message,
  clickHandler: NavMenuHandler.CONTACT_US,
};

const subscription = {
  name: 'Subscription',
  path: SUBSCRIPTION_PATH,
  icon: mdi.card,
};

const signOut = {
  name: 'Sign Out',
  path: LOGOUT_PATH,
  icon: mdi.logout,
};

const userRoutes = [events, contacts, gallery, settings, contactUs];

const adminRoutes = [events, contacts, gallery, settings, subscriptionsManagement, contactUs];

const sideMenuUserRoutes = [events, contacts, gallery, settings, subscription, contactUs, SEPARATOR, signOut];

const sideMenuAdminRoutes = [events, contacts, gallery, settings, subscriptionsManagement, subscription, contactUs, SEPARATOR, signOut];

export const getMenuItems = (role: string, sideMenu?: boolean) => {
  switch (role) {
    case AUTHORITIES.ADMIN:
      return sideMenu ? sideMenuAdminRoutes : adminRoutes;
    case AUTHORITIES.USER:
      return sideMenu ? sideMenuUserRoutes : userRoutes;
    default:
      return [];
  }
};
