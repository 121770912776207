import Icon from '@mdi/react';
import React from 'react';
import './action-button-styles.scss';
import { Color } from 'app/shared/model/enumerations/color.model';

interface IActionButtonProps {
  icon: string;
  name: string;
  color?: Color;
  large?: boolean;
  disabled?: boolean;
  clickHandler?: () => void;
}

const ActionButton = ({ icon, name, color = Color.PRIMARY, disabled, large, clickHandler }: IActionButtonProps) => (
  <div
    className={`action-button ${large ? 'large' : ''} ${disabled ? 'disabled-button' : ''} ${color}-button`}
    onClick={e => {
      e.stopPropagation();

      if (!disabled && !!clickHandler) clickHandler();
    }}
  >
    <Icon path={icon} size={large ? 1 : 0.75} title={name} />
  </div>
);

export default ActionButton;
