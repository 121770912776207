import dayjs from 'dayjs';
import { IEvent } from 'app/shared/model/event.model';
import { IContact } from 'app/shared/model/contact.model';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';

export interface IEventPayment {
  id?: number;
  timestamp?: dayjs.Dayjs | null;
  link?: string | null;
  status?: keyof typeof PaymentStatus | null;
  event?: IEvent | null;
  contact?: IContact | null;
}

export const defaultValue: Readonly<IEventPayment> = {};
