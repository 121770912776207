import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Input, Row, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getEvents, deleteEntity } from './event.reducer';
import { IEvent } from 'app/shared/model/event.model';
import '../../shared/layout/Layout.scss';
import './events-styles.scss';
import { toUsaDate, toUsaDateTime } from 'app/util/date-time-utils';
import axios from 'axios';
import { ORDER_HISTORY_URL, FLORIST_PROPOSALS_DATA_URL } from 'app/config/url';
import { len } from 'app/util/structure-utils';
import { MergedEventStatus } from 'app/shared/model/enumerations/merged-event-statuses';
import ReviseBeforeOrder from 'app/entities/event/ReviseBeforeOrder';
import { IOrderHistory } from 'app/shared/model/order-history.model';
import OrderHistory from 'app/entities/event/order-history/OrderHistory';
import StatusIndicator from 'app/entities/event/StatusIndicator';
import { getLastProposalNumber, getStatus, isOrdered } from 'app/util/event-utils';
import Loading from 'app/shared/alert/loading';
import ActionButton from 'app/shared/action-buttons/ActionButton';
import NoItems from 'app/shared/alert/NoItems';
import { EDIT_PATH, EVENTS_PATH, NEW_EVENT_PATH } from 'app/paths';
import { mdi } from 'app/config/constants';
import Icon from '@mdi/react';
import Switch from 'app/shared/switch/Switch';
import ModalDialog from 'app/shared/layout/dialog/ModalDialog';
import { extractNumbers } from 'app/util/format-utils';
import { Color } from 'app/shared/model/enumerations/color.model';
import DashboardSection, { IData, IStats } from '../../shared/dashboard/dashboard-components/DashboardSection';
import { useWindowWidth } from 'app/shared/hooks/useWindowWidth';
import { screenWidth } from 'app/shared/model/enumerations/screen-modes';

export const statuses = {
  [MergedEventStatus.ORDERED]: {
    icon: mdi.ordered,
    className: 'ordered',
  },
  [MergedEventStatus.APPROVED]: {
    icon: mdi.approved,
    className: 'approved',
  },
  [MergedEventStatus.PENDING_APPROVAL]: {
    icon: mdi.pending,
    className: 'pending',
  },
  [MergedEventStatus.DRAFT]: {
    icon: mdi.draft,
    className: 'draft',
  },
  [MergedEventStatus.CANCELED]: {
    icon: mdi.close,
    className: 'canceled',
  },
  [MergedEventStatus.DECLINED]: {
    icon: mdi.declined,
    className: 'declined',
  },
};

/**
 * Sorts events based on the given key difference and returns the result.<br>
 * If the key difference equals to 0 (means that events have same value regardless of what field they were compared by),
 * returns difference between events' ids.
 *
 * @param {number} keyDifference - The difference value used for sorting events.
 * @param {IEvent} a - The first event to compare.
 * @param {IEvent} b - The second event to compare.
 * @returns {number} - The comparison result based on the key difference.
 */
const sortEvents = (keyDifference: number, a: IEvent, b: IEvent): number => (keyDifference === 0 ? b.id - a.id : keyDifference);

/**
 * Comparator functions for sorting event objects based on different properties.
 */
const comparators = {
  id: (a: IEvent, b: IEvent) => sortEvents(a.id - b.id, a, b),
  name: (a: IEvent, b: IEvent) => sortEvents(a.name.localeCompare(b.name), a, b),
  status: (a: IEvent, b: IEvent) => sortEvents(getStatus(a).localeCompare(getStatus(b)), a, b),
  eventDate: (a: IEvent, b: IEvent) => sortEvents(Number(a.eventDate) - Number(b.eventDate), a, b),
  deliveryDate: (a: IEvent, b: IEvent) => sortEvents(Number(a.deliveryDate) - Number(b.deliveryDate), a, b),
  proposalNumber: (a: IEvent, b: IEvent) =>
    sortEvents(Number(extractNumbers(getLastProposalNumber(a))) - Number(extractNumbers(getLastProposalNumber(b))), a, b),
};

/**
 * Filters an event based on a specific key and filter.
 *
 * @param {IEvent} event - The event object to be filtered.
 * @param {string} key - The key to access the value in the event object.
 * @param {string} filter - The filter string to be applied.
 * @returns {boolean} - Returns true if the value matches the filter, false otherwise.
 */
const filterEvent = (event: IEvent, key: string, filter: string) => {
  const value = !!dataAccessors[key] ? dataAccessors[key](event) : event[key];

  return value.toLowerCase().includes(filter.toLowerCase());
};

/**
 * A collection of data accessors for retrieving specific properties from an event object.
 */
const dataAccessors = {
  proposalNumber: (event: IEvent) => getLastProposalNumber(event),
  eventDate: (event: IEvent) => (event.eventDate ? toUsaDateTime(event.eventDate) : ''),
  deliveryDate: (event: IEvent) => (event.deliveryDate ? toUsaDate(event.deliveryDate) : ''),
  status: (event: IEvent) => getStatus(event),
};

/**
 * Extracts order numbers from an event.
 *
 * @param {IEvent} event - The event object containing orders.
 * @returns {number[]} - An array of order numbers extracted from the event.
 */
const extractOrderNumbersFromEvent = (event: IEvent): number[] =>
  (event.orders && event.orders[0].orderNumber.split('; ').map(n => Number(n))) || [];

/**
 * The ISort interface represents a sorting mechanism.
 */
interface ISort {
  sort: string;
  order: string;
  comparator: (a: IEvent, b: IEvent) => number;
}

const defaultSort = {
  sort: 'eventDate',
  order: ASC,
  comparator: comparators.eventDate,
};

const defaultFilters = {
  status: '',
};

const defaultProposalsData: Record<string, IData> = {
  total: {
    title: 'Total Sent',
    value: 0,
  },
  pending: {
    title: 'Pending',
    value: 0,
  },
  approved: {
    title: 'Approved',
    value: 0,
  },
  ordered: {
    title: 'Ordered',
    value: 0,
  },
  totalProfitAmount: {
    title: 'Profit',
    value: 0,
  },
};

const Event = () => {
  const [sortState, setSortState] = useState<ISort>(defaultSort);
  const [eventToConvert, setEventToConvert] = useState(null);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [eventsWithOrders, setEventsWithOrders] = useState<IEvent[]>([]);
  const [hoveredOrderHistory, setHoveredOrderHistory] = useState<IOrderHistory>(null);
  const [eventToDelete, setEventToDelete] = useState<IEvent>(null);
  const [filters, setFilters] = useState<{ [key: string]: string }>(defaultFilters);
  const [showPastEvents, setShowPastEvents] = useState(false);
  const [proposals, setProposals] = useState<IStats>({
    current: defaultProposalsData,
    past: defaultProposalsData,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const eventsSourceList = useAppSelector(state => state.event.entities);
  const loading = useAppSelector(state => state.event.loading);
  const width = useWindowWidth();

  useEffect(() => {
    const events_: IEvent[] = sortEvents(JSON.parse(JSON.stringify(eventsSourceList)));
    const convertedOrderNumbers = events_.filter(isOrdered).reduce((arr: number[], event: IEvent) => {
      const addedNumbers = [...arr];
      const numbers = extractOrderNumbersFromEvent(event);
      numbers.forEach(n => {
        if (!arr.includes(n)) addedNumbers.push(n);
      });

      return addedNumbers;
    }, []);
    const hasOrders = len(convertedOrderNumbers) > 0;

    if (hasOrders) {
      axios.get<IOrderHistory[]>(ORDER_HISTORY_URL).then(r => {
        const orderHistories = r.data.filter(o => convertedOrderNumbers.includes(o.orderNumber));
        const convertedEvents = events_.filter(isOrdered);
        convertedEvents.forEach(event => {
          const orderNumbers = extractOrderNumbersFromEvent(event);
          event.orderHistories = orderHistories.filter(o => orderNumbers.includes(o.orderNumber));
        });

        setEventsWithOrders(events_);
      });
    } else {
      setEventsWithOrders(events_);
    }
  }, [eventsSourceList]);

  useEffect(() => {
    let filteredEvents: IEvent[] = sortEvents(filterPastEvents(eventsWithOrders));

    if (hasAnyFilter()) {
      const filterKeys = Object.keys(filters).filter(key => len(filters[key]) > 0);

      filterKeys.forEach(key => {
        filteredEvents = filteredEvents.filter(event => filterEvent(event, key, filters[key]));
      });
    }

    setEvents(filteredEvents);
  }, [filters, showPastEvents, eventsWithOrders]);

  useEffect(() => {
    setEvents(sortEvents(events));
  }, [sortState.order, sortState.sort]);

  const fetchProposalsData = (period: number) => {
    axios.get(FLORIST_PROPOSALS_DATA_URL, { params: { period } }).then(r => {
      const data = r.data;
      const current = extractProposalData(data, 'current');
      const past = extractProposalData(data, 'past');
      setProposals({ current, past });
    });
  };

  const extractProposalData = (data: any, key: string): Record<string, IData> => ({
    total: {
      title: 'Total Sent',
      value: data[key].total,
    },
    pending: {
      title: 'Pending',
      value: data[key].pending,
    },
    approved: {
      title: 'Approved',
      value: data[key].approved,
    },
    ordered: {
      title: 'Ordered',
      value: data[key].ordered,
    },
    totalProfitAmount: {
      title: 'Profit',
      value: data[key].totalProfitAmount,
    },
  });

  const getAllEvents = () => {
    dispatch(getEvents({}));
  };

  const filterPastEvents = (eventList: IEvent[]): IEvent[] => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const today = now.getTime() / 1000;

    const filterEvent = (event: IEvent) =>
      showPastEvents ? event.eventDate && Number(event.eventDate) < today : !event.eventDate || Number(event.eventDate) >= today;

    return eventList.filter(filterEvent);
  };

  const sortEvents = (eventList: IEvent[]): IEvent[] =>
    [...eventList].sort((a, b) => {
      const comparison = sortState.comparator(a, b);

      return sortState.order === ASC ? comparison : -comparison;
    });

  const sort = (field: string) => () => {
    setSortState(prevState => {
      const shouldSetToDefault = prevState.order === DESC && prevState.sort === field;
      return {
        ...prevState,
        sort: shouldSetToDefault ? defaultSort.sort : field,
        order: shouldSetToDefault ? defaultSort.order : prevState.order === ASC && prevState.sort === field ? DESC : ASC,
        comparator: comparators[shouldSetToDefault ? defaultSort.sort : field],
      };
    });
  };

  const getSortIconByFieldName = (fieldName: string): IconDefinition => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;

    return sortFieldName !== fieldName ? faSort : order === ASC ? faSortUp : faSortDown;
  };

  const resetModal = () => {
    setEventToDelete(null);
  };

  const editHandler = (event: IEvent) => {
    navigate(`${EVENTS_PATH}/${getLastProposalNumber(event)}/${EDIT_PATH}`);
  };

  const deleteHandler = () => {
    dispatch(deleteEntity(eventToDelete.id));
    resetModal();
  };

  const hasAnyFilter = () => Object.values(filters).some(filter => len(filter) > 0);

  const renderDeleteButton = (event: IEvent) => (
    <ActionButton
      icon={mdi.delete}
      name="Delete"
      disabled={getStatus(event) !== MergedEventStatus.DRAFT}
      clickHandler={() => setEventToDelete(event)}
    />
  );

  const renderEditButton = (event: IEvent) => (
    <ActionButton icon={mdi.edit} name="Edit" disabled={isOrdered(event)} clickHandler={() => editHandler(event)} />
  );

  const renderConvertToOrderButton = (event: IEvent, disabled: boolean = false) => (
    <div onClick={e => e.stopPropagation()} style={disabled ? { cursor: 'default' } : {}}>
      <Button className="btn" color="primary" onClick={() => setEventToConvert(event)}>
        <>
          <Icon path={mdi.convertToOrder} size={0.75} style={{ marginBottom: '2px' }} />
          &nbsp; Convert to Order
        </>
      </Button>
    </div>
  );

  const renderClearFiltersButton = () => (
    <div className="justify-start" style={{ width: '100%' }}>
      <ActionButton icon={mdi.clear} name="Reset filters" color={Color.DANGER} clickHandler={() => setFilters(defaultFilters)} />
    </div>
  );

  const renderOrderNumbers = (event: IEvent) => {
    const orderHistories = event.orderHistories || [];

    return (
      <div className="order-numbers">
        {orderHistories.map((o, i) => (
          <div
            key={i}
            className="order-number-link"
            onMouseMove={() => {
              setHoveredOrderHistory(o);
            }}
            onMouseLeave={() => {
              setHoveredOrderHistory(null);
            }}
          >
            {o.orderNumber}

            {o === hoveredOrderHistory && <OrderHistory order={hoveredOrderHistory} />}
          </div>
        ))}
      </div>
    );
  };

  const renderStatus = (event: IEvent) => <StatusIndicator status={getStatus(event)} />;

  const renderOrderCell = (event: IEvent) =>
    isOrdered(event) ? renderOrderNumbers(event) : getStatus(event) === MergedEventStatus.APPROVED && renderConvertToOrderButton(event);

  const updateFilters = (key: string, { target: { value } }: any) => {
    setFilters((prev: any) => ({ ...prev, [key]: value }));
  };

  const renderFilterInput = (key: string) => (
    <Input value={filters[key] || ''} style={{ padding: '2px 5px' }} onChange={e => updateFilters(key, e)} />
  );

  const renderStatusFilterDropdown = () => {
    const options: string[] = [
      MergedEventStatus.DRAFT,
      MergedEventStatus.PENDING_APPROVAL,
      MergedEventStatus.APPROVED,
      MergedEventStatus.ORDERED,
    ];

    return (
      <Input type="select" value={filters.status} style={{ padding: '2px 5px' }} onChange={e => updateFilters('status', e)}>
        <option value=""></option>

        {options.map((status: string, i: number) => (
          <option key={i} value={status}>
            {status}
          </option>
        ))}
      </Input>
    );
  };

  const renderHighLightedValue = (event: IEvent, key: string) => {
    let value = dataAccessors[key] ? dataAccessors[key](event) : event[key];
    const filter = filters[key];

    if (filter) {
      const regex = new RegExp(`(${filter})`, 'gi');
      const parts = value.split(regex);
      const spaceMargin = '4px';
      value = (
        <div style={{ display: 'flex' }}>
          {parts.map((part, index) => (
            <div
              key={index}
              style={{
                background: part.toLowerCase() === filter.toLowerCase() ? 'rgb(0, 219, 180, 0.4)' : '',
                marginLeft: part.startsWith(' ') ? spaceMargin : '0',
                marginRight: part.endsWith(' ') ? spaceMargin : '0',
                borderRadius: '5px',
              }}
            >
              {part}
            </div>
          ))}
        </div>
      );
    }

    return value;
  };

  const renderFilters = () => (
    <tr key="table-filters-row">
      <td className="proposal-number">{renderFilterInput('proposalNumber')}</td>
      <td className="event-name filter">{renderFilterInput('name')}</td>
      <td className="event-date">{renderFilterInput('eventDate')}</td>
      <td className="delivery-date">{renderFilterInput('deliveryDate')}</td>
      <td className="status">{renderStatusFilterDropdown()}</td>
      <td className="order">{hasAnyFilter() && renderClearFiltersButton()}</td>
      <td className="short-actions"></td>
      <td className="short-actions"></td>
    </tr>
  );

  const renderTable = () => (
    <Table responsive>
      <thead>
        <tr>
          <th className="hand proposal-number" onClick={sort('proposalNumber')}>
            <FontAwesomeIcon icon={getSortIconByFieldName('proposalNumber')} /> Proposal #
          </th>
          <th className="hand event-name" onClick={sort('name')}>
            <FontAwesomeIcon icon={getSortIconByFieldName('name')} /> Name
          </th>
          <th className="hand event-date" onClick={sort('eventDate')}>
            <FontAwesomeIcon icon={getSortIconByFieldName('eventDate')} /> Event Date
          </th>
          <th className="hand delivery-date" onClick={sort('deliveryDate')}>
            <FontAwesomeIcon icon={getSortIconByFieldName('deliveryDate')} /> Delivery Date
          </th>
          <th className="hand status" onClick={sort('status')}>
            <FontAwesomeIcon icon={getSortIconByFieldName('status')} /> Status
          </th>
          <th className="order">Order</th>
          <th className="short-actions" />
          <th className="short-actions" />
        </tr>
      </thead>

      <tbody>
        {renderFilters()}

        {len(events) > 0 ? (
          events.map((event: IEvent, i: number) => {
            const lastProposalNumber = getLastProposalNumber(event);

            return (
              <tr
                key={`entity-${i}`}
                data-cy="entityTable"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`${EVENTS_PATH}/${lastProposalNumber}`)}
              >
                <td className="proposal-number">
                  <div className="value">{renderHighLightedValue(event, 'proposalNumber')}</div>
                </td>
                <td className="event-name">{renderHighLightedValue(event, 'name')}</td>
                <td className="event-date">{renderHighLightedValue(event, 'eventDate')}</td>
                <td className="delivery-date">{renderHighLightedValue(event, 'deliveryDate')}</td>
                <td className="status">{renderStatus(event)}</td>
                <td className={`order ${isOrdered(event) ? 'order-numbers' : 'convert-to-order-button'}`}>{renderOrderCell(event)}</td>
                <td className="short-actions">{renderEditButton(event)}</td>
                <td className="short-actions">{renderDeleteButton(event)}</td>
              </tr>
            );
          })
        ) : (
          <tr key="table-filters-row stretch">
            <NoItems text="No Events found" />
          </tr>
        )}
      </tbody>
    </Table>
  );

  const createNewEvent = () => {
    navigate(NEW_EVENT_PATH);
  };

  const togglePastEvents = () => {
    setShowPastEvents(prev => !prev);
  };

  const renderButton = (icon?: string, text?: string, clickHandler: any = null, isOutlined: boolean = false) => (
    <Button className="btn" color="primary" onClick={clickHandler} outline={isOutlined}>
      <>
        <Icon path={icon} size={0.75} style={{ marginBottom: '2px' }} />
        &nbsp; {text}
      </>
    </Button>
  );

  const renderPageHeader = () => (
    <Row>
      <Col style={{ margin: '0 10px 10px 0', flex: 0 }}>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <h4 id="event-heading" data-cy="EventHeading" style={{ textWrap: 'nowrap', margin: '0 30px 0 0' }}>
            Events
          </h4>
          <Switch
            leftLabel="Past"
            rightLabel="Upcoming"
            leftColor={Color.PRIMARY}
            rightColor={Color.PRIMARY}
            isAtRight={!showPastEvents}
            onToggle={togglePastEvents}
          />
        </div>
        <div className="d-flex justify-content-start gap-3" style={{ paddingTop: '10px' }}>
          {renderButton(mdi.plus, 'New Event', createNewEvent)}
          {renderButton(mdi.refresh, 'Refresh', getAllEvents, true)}
        </div>
      </Col>

      <Col className="stats-dashboard-container">
        <DashboardSection
          title="Proposals Statistics"
          currentData={proposals.current}
          pastData={proposals.past}
          periods={{
            30: '30 days',
            90: '90 days',
            365: 'YTD',
          }}
          onPeriodChange={fetchProposalsData}
        />
      </Col>
    </Row>
  );

  const renderCardRow = (title: string, content: any) => (
    <Row>
      <Col className="card-row-title">{title}:</Col>
      <Col>{content}</Col>
    </Row>
  );

  const renderCards = () =>
    len(events) > 0 ? (
      <Row className="event-cards-container">
        {events.map((event: IEvent, i: number) => {
          const lastProposalNumber = getLastProposalNumber(event);

          return (
            <Col key={`entity-${i}`} md={6} sm={6}>
              <div className="event-card" onClick={() => navigate(`${EVENTS_PATH}/${lastProposalNumber}`)}>
                {renderCardRow('Proposal #', renderHighLightedValue(event, 'proposalNumber'))}
                {renderCardRow('Event Name', renderHighLightedValue(event, 'name'))}
                {renderCardRow('Event Date', renderHighLightedValue(event, 'eventDate'))}
                {renderCardRow('Delivery Date', renderHighLightedValue(event, 'deliveryDate'))}
                {renderCardRow('Status', renderStatus(event))}
                {renderCardRow('Order', renderOrderCell(event))}

                <div className="card-actions">
                  {renderEditButton(event)}
                  {renderDeleteButton(event)}
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    ) : (
      <NoItems text="No Events found" />
    );

  const renderEvents = () => (width >= screenWidth.MIN_TABLET ? renderTable() : renderCards());

  const renderPageContent = () => <div className="events-content">{loading ? <Loading /> : renderEvents()}</div>;

  const renderPage = () => (
    <div className="stretch">
      {renderPageHeader()}
      {renderPageContent()}
    </div>
  );

  const renderOrderRevision = () => (
    <ReviseBeforeOrder initialEvent={eventToConvert} onOrderPlaced={() => getAllEvents()} onClose={() => setEventToConvert(null)} />
  );

  const renderConfirmationDialog = () => (
    <ModalDialog
      isOpen={!!eventToDelete}
      modalText={
        eventToDelete?.name
          ? `Are you sure you want to delete event draft '${eventToDelete.name}'?`
          : 'Are you sure you want to delete this event draft?'
      }
      toggleHandler={resetModal}
      buttons={[
        {
          label: 'Delete',
          color: Color.DANGER,
          clickHandler: deleteHandler,
        },
        {
          label: 'Cancel',
          color: Color.SECONDARY,
          outline: true,
          clickHandler: resetModal,
        },
      ]}
    />
  );

  return (
    <>
      {renderPage()}
      {renderConfirmationDialog()}
      {eventToConvert && renderOrderRevision()}
    </>
  );
};

export default Event;
