import axios from 'axios';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { SHIPMENT_URL } from 'app/config/url';
import { createAsyncThunk } from '@reduxjs/toolkit';

const initialState: EntityState<string> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  updateSuccess: false,
};

const apiUrl = SHIPMENT_URL;

export const getShipdays = createAsyncThunk(
  'shipdays/getShipdays',
  async (eventDateStr: string) => {
    const response = await axios.get(apiUrl);
    const shipmentData = response.data;

    return calculateAvailableDates(shipmentData, eventDateStr);
  },
  { serializeError: serializeAxiosError },
);

const getWeekDayAndWeekNumber = (date: Date) => {
  const firstDayOfMonth = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
  const adjustedDate = date.getUTCDate() + firstDayOfMonth.getUTCDay();
  const weekNumber = Math.ceil(adjustedDate / 7);
  const dayOfWeek = date.getUTCDay();

  return { weekDay: dayOfWeek, weekNumber: weekNumber };
};

const calculateAvailableDates = (shipmentModel: any, eventDateStr: string) => {
  const dates = [];
  const eventDate = new Date(eventDateStr);
  eventDate.setDate(eventDate.getDate() - 7);

  if (shipmentModel) {
    const shipDay = new Date(shipmentModel.shipDay);
    const endDate = new Date(eventDateStr);

    const excludedDaysInfo = new Map();

    shipmentModel.excludeDaysTruck.forEach((ts: string) => {
      const date = new Date(ts);
      const info = getWeekDayAndWeekNumber(date);
      excludedDaysInfo.set(date.toISOString().split('T')[0], info);
    });

    let startDate = new Date(eventDate);
    if (startDate < new Date(shipDay)) {
      startDate = new Date(shipDay);
    }

    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const { weekDay } = getWeekDayAndWeekNumber(d);
      const dateString = d.toISOString().split('T')[0];
      const isDisabled = Array.from(excludedDaysInfo.values()).some(excludedInfo => excludedInfo.weekDay === weekDay);

      if (!isDisabled) {
        dates.push(dateString);
      }
    }
  }

  return dates;
};

// Slice

export const ShipdaysSlice = createEntitySlice({
  name: 'shipdays',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getShipdays.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addMatcher(
        action => action.type.endsWith('/pending'),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
          state.updating = true;
        },
      );
  },
});

export const { reset } = ShipdaysSlice.actions;

// Reducer
export default ShipdaysSlice.reducer;
