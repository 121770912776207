import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import SubscriptionInfo from './subscription-info';

const SubscriptionInfoRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SubscriptionInfo />} />
  </ErrorBoundaryRoutes>
);

export default SubscriptionInfoRoutes;
