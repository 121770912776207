import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Terms from './terms';

const TermsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Terms />} />
  </ErrorBoundaryRoutes>
);

export default TermsRoutes;
