import React from 'react';
import './qty-styles.scss';
import { Color } from 'app/shared/model/enumerations/color.model';

type ILabel = {
  text: string;
  type: Color;
};

interface QtyProps {
  min?: number;
  max?: number;
  step?: number;
  value: number;
  width?: string;
  label?: ILabel | null;
  onChange: (newValue: number) => void;
}

const Qty: React.FC<QtyProps> = ({ min = -Infinity, max = Infinity, step = 1, value, width = '100px', label = null, onChange }) => {
  const renderButton = (text: string, isDisabled: boolean, onClick: any) => (
    <div className={`qty-button ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? null : onClick}>
      {text}
    </div>
  );

  const renderDec = () => renderButton('-', value - step < min, () => onChange(value - step));

  const renderInc = () => renderButton('+', value + step > max, () => onChange(value + step));

  const renderVal = () => <div className="val">{value}</div>;

  const renderLabel = () => (
    <div className="qty-label">
      Qty
      {label && <div className={`text-label ${label.type}`}>{label.text}</div>}
    </div>
  );

  return (
    <div className="qty-container">
      <div className="qty" style={{ width }}>
        {renderLabel()}
        {renderDec()}
        {renderVal()}
        {renderInc()}
      </div>
    </div>
  );
};

export default Qty;
