import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row, Table } from 'reactstrap';
import { getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity, getEntities } from './contact.reducer';
import '../../shared/layout/Layout.scss';
import './contacts-styles.scss';
import { IContact } from 'app/shared/model/contact.model';
import ActionButton from 'app/shared/action-buttons/ActionButton';
import { mdi } from 'app/config/constants';
import ModalDialog from 'app/shared/layout/dialog/ModalDialog';
import { formatPhoneNumber } from 'app/util/format-utils';
import { Color } from 'app/shared/model/enumerations/color.model';

const Contact = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));
  const [contactToDelete, setContactToDelete] = useState<IContact>(null);
  const contactList = useAppSelector(state => state.contact.entities);
  const loading = useAppSelector(state => state.contact.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const resetModal = () => {
    setContactToDelete(null);
  };

  const deleteHandler = () => {
    dispatch(deleteEntity(contactToDelete.id));
    resetModal();
  };

  const renderDeleteButton = (contact: IContact) => (
    <ActionButton icon={mdi.delete} name="Delete" clickHandler={() => setContactToDelete(contact)} />
  );

  const renderPageContent = () => (
    <div className="stretch col-md-8">
      <Row>
        <Col>
          <h4 id="contact-heading" data-cy="ContactHeading">
            Contacts
          </h4>
        </Col>
      </Row>

      <div className="contacts-table">
        {contactList && contactList.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th className="hand first-name" onClick={sort('firstName')}>
                  First Name <FontAwesomeIcon icon={getSortIconByFieldName('firstName')} />
                </th>
                <th className="hand last-name" onClick={sort('lastName')}>
                  Last Name <FontAwesomeIcon icon={getSortIconByFieldName('lastName')} />
                </th>
                <th className="hand email">Email</th>
                <th className="hand phone-number">Phone</th>
                <th className="hand note">Note</th>
                <th className="short-actions" />
              </tr>
            </thead>
            <tbody>
              {contactList.map((contact: IContact, i: number) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td className="first-name">{contact.firstName}</td>
                  <td className="last-name">{contact.lastName}</td>
                  <td className="email">{contact.email}</td>
                  <td className="phone-number">{contact.phoneNumber && formatPhoneNumber(contact.phoneNumber)}</td>
                  <td className="note" title={contact.note}>
                    {contact.note}
                  </td>
                  <td className="short-actions">{renderDeleteButton(contact)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Contacts found</div>
        )}
      </div>
    </div>
  );

  const renderConfirmationDialog = () => {
    if (!contactToDelete) return;

    const contact = contactToDelete;
    const firstName = contact.firstName || '';
    const lastName = contact.lastName || '';
    const email = contact.email || '';
    const phone = contact.phoneNumber ? formatPhoneNumber(contact.phoneNumber) : '';
    let contactPromptPart: string;

    if (!firstName && !lastName) {
      contactPromptPart = !!email ? `${email} from your contacts?` : !!phone ? `${phone} from your contacts?` : 'this contact?';
    } else {
      contactPromptPart = `${firstName} ${lastName}`.trim();

      if (!!email || !!phone) {
        contactPromptPart += ` (${!!email ? email : phone})`;
      }

      contactPromptPart += ' from your contacts?';
    }

    return (
      <ModalDialog
        isOpen={true}
        modalText={`Are you sure you want to delete ${contactPromptPart}`}
        toggleHandler={resetModal}
        buttons={[
          {
            label: 'Delete',
            color: Color.DANGER,
            clickHandler: deleteHandler,
          },
          {
            label: 'Cancel',
            color: Color.SECONDARY,
            outline: true,
            clickHandler: resetModal,
          },
        ]}
      />
    );
  };

  return (
    <>
      {renderPageContent()}
      {renderConfirmationDialog()}
    </>
  );
};

export default Contact;
