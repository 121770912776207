import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { NavDropdown } from './menu-components';
import { LOGIN_PATH, LOGOUT_PATH, SUBSCRIPTION_PATH } from 'app/paths';

const accountMenuItemsAuthenticated = () => (
  <>
    <MenuItem icon="credit-card-alt" to={SUBSCRIPTION_PATH} data-cy="subscription">
      Subscription
    </MenuItem>

    <MenuItem icon="sign-out-alt" to={LOGOUT_PATH} data-cy="logout">
      Sign out
    </MenuItem>
  </>
);

const accountMenuItems = () => (
  <>
    <MenuItem id="login-item" icon="sign-in-alt" to={LOGIN_PATH} data-cy="login">
      Sign in
    </MenuItem>
  </>
);

const AccountMenu = ({ isAuthenticated = false }) => (
  <NavDropdown icon="user" name="Account" id="account-menu" data-cy="accountMenu">
    {isAuthenticated && accountMenuItemsAuthenticated()}
    {!isAuthenticated && accountMenuItems()}
  </NavDropdown>
);

export default AccountMenu;
